//Coming soon products

section.coming-prod {
	margin-bottom: 60px;
	.coming-prod-slider {
		position: relative;
		.coming-prod-img {
			padding: 15px;
			padding-bottom: 0;
			position: relative;
			img {
				max-width: 100%;
				margin: 0 auto;
			}
		}
	}
	.coming-prod-wrap {
		margin-bottom: 50px;
		position: relative;
		padding-top: 30px;
		h2 {
			font-weight: 800;
		    font-size: 24px;
		    color: #000;
		    padding-bottom: 10px;
		}
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			top: 32px;
   			left: 65%;
			right: 0; 
			background-color: transparent;
			background-image: url(../images/kidlet-news-ok-girl-bg-new.jpg);
			background-size: contain;
			background-position-x: right;
    		background-position-y: top;
			background-repeat: no-repeat;
			z-index: 1;
			display: none;
		}
		.bg {
			    position: absolute;
			    display: block;
			    top: 42%;
			    bottom: 12%;
			    right: 0;
			    transition: all .9s ease;
			    z-index: 5;
			    width: 100%;
		    &.bg-multi {
		    	background-color: #ff7b75;
    			mix-blend-mode: multiply;
		    }
		    &.bg-soft {
		    	background: -webkit-linear-gradient(45deg, #ff7b75 0%, #e086a7 100%);
			    background: -o-linear-gradient(45deg, #ff7b75 0%, #e086a7 100%);
			    background: linear-gradient(45deg, #ff7b75 0%, #e086a7 100%);
			    mix-blend-mode: soft-light;
			    box-shadow: 0px 8px 18px 2px rgba(0, 0, 0, 0.15);
		    }
		} 

	}
	.coming-prod-slider-wrap {
		padding: 15px;
		position: relative;
		box-shadow: 0px 8px 18px 2px rgba(0, 0, 0, 0.15);
		z-index: 20;
		background-color: #fff;
		.navigation-slick {
			position: absolute;
			left: 0;
    		right: 0;
    		top: 50%;
    		margin-top: -14px;
			.left,
			.right {
				display: block !important;
				position: absolute;
				color: darken(#e4e4e4,30%);
				cursor: pointer;
				font-size: 18px;
				z-index: 100;
				&:hover {
					color: darken(#e4e4e4,60%);
				}
			}
			.left {
				left: 15px; 
			}
			.right {
				right: 15px;
			}
		}
	}
	.facebook-wrap {
		position: relative;
		height: 100%;
		.facebook-cnt {
			position: relative;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 10;
			padding: 30px 30px;
			background-color: #fff; 
			.event-btn {
				position: relative;
				padding-left: 75px;
				text-align: left;
				border: 2px solid #4792da;
				font-weight: 700;
				transition: all .3s ease;
				&:hover {
					border-color: darken(#4792da,15%);
					transition: all .3s ease;
					transform: translateY(-3px);
				}
				i {
					font-size: 28px;
				    position: absolute;
				    left: 20px;
				    top: 13px;
				}
			}
		}
		.counter {
			position: relative;
			background-color: #e6727a;
			z-index: 10;
			height: 100px;	
			text-align: center;
			.count-wrap {
				display: inline-block;
				float: left;
				text-align: center; 
				padding: 0 20px;
				position: relative;
				&.hr,
				&.min {
					&:after {
						content: ':';
						font-family: 'Nunito';
						font-weight: 700;
						font-size: 24px;
						position: absolute;
						right: 0;
						top: -2px;
						color: #fff;
					}
				}
				
			}
			.count-val {
				color: #fff;
				font-family: 'Nunito';
				font-weight: 700;
				font-size: 24px;
				position: relative;
			}
			.count-text {
				color: #fff;
				font-weight: 700;
				font-size: 14px;
			}
			#facebook-count {
				position: relative;
			    height: auto;
			    display: inline-block;
			    margin-top: 24px;
			}
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.coming-prod {
		margin-bottom: 120px;
		.coming-prod-slider {
			.coming-prod-img {
				img {
					max-width: 85%;
					margin: 0 auto;
				}
			}
		}
		.coming-prod-wrap {
			h2 {
			    font-size: 28px;
			    padding-bottom: 10px;
			}
			p {
				font-weight: 600;
				padding-right: 30px;
			}
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				top: 32px;
	   			left: 65%;
				right: 0; 
				background-color: transparent;
				background-image: url(../images/kidlet-news-ok-girl-bg-new.jpg);
				background-size: contain;
				background-position-x: right;
	    		background-position-y: top;
				background-repeat: no-repeat;
				z-index: 1;
				display: block;
			}

		}
		.facebook-wrap {
			.facebook-cnt {
				padding: 30px 60px; 
			}
			.counter {
				.count-wrap {
					&.hr,
					&.min {
						&:after {
							font-size: 24px;
							right: 0;
							top: -2px;
						}
					}
					
				}
				.count-val {
					font-size: 24px;
				}
				.count-text {
					font-size: 14px;
				}
				#facebook-count {
				}
			}
		}
	}



}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}



