.kidlet-newsletter {
	margin-top: 50px;
	.container {
		position: relative;

	}
	.form-inner {
		padding: 15px;
		background-color: rgba(255, 255, 255, 0.98);
		position: relative;
		box-shadow: 0px 15px 80px 0px rgba(0,0,0,.15);
		z-index: 10;
		transition: all 0.3s ease-out;
		transform: scale(1);	
		//overflow: hidden;
		h2 {
			color: #000;
			font-weight: 800; 
			font-size: 20px;
			line-height: 30px;
			span {
				color: #fff;
				background-color: #000;
				display: inline-block;
				padding: 2px 8px;
				line-height: 28px;
				margin-left: 5px;
			}
		}
		hr {
			border-color: #000;
			margin-top: 1rem;
    		margin-bottom: 1rem;
		}
		form {
			input:not([type='checkbox']) {    
				display: block;
			    width: 100%;
			    box-shadow: none;
				border-color: rgba(0,0,0,1);
			    margin-bottom: 20px;
			    padding: .9rem .75rem;
			    color: #000;
			    font-size: 16px;
			    font-weight: 600;
			    font-family: Nunito;
			    &:focus,
			    &:focus-within
			    &:visited {
					box-shadow: 0 10px 30px 0 rgba(0,36,71,.08);
			    }
			}
			button.btn {
			    background-color: #000;
			    color: #fff;
			    font-size: 22px;
			    font-weight: 800;
			    text-align: center;
			    padding: .7rem .75rem;
			    cursor: pointer;
			    position: relative;
			    font-family: Nunito;
			    margin-top: 10px;
			    margin-top: 30px;
			}
			.form-check {
				margin-left: 20px;
			}
			a.term-2 {
				padding-left: 20px;
				display: block;
			}
		}
		.after-cnt {
			display: none;			
			h3 {
				margin-top: 20px;
				font-weight: 700;
				font-size: 18px;
				margin-bottom: 30px;
			}
			span {
				border: 2px solid #b2d2a6;
			    color: #90be7e;
			    font-size: 34px;
			    font-weight: 800;
			    display: block;
			    text-align: center;
			    margin-bottom: 20px;
			    padding: 10px 0;
			}
			button.btn-clip {
				background-color: #000;
			    color: #fff;
			    font-size: 22px;
			    font-weight: 800;
			    text-align: center;
			    padding: 5px 15px;
			    display: block;
			    cursor: pointer;
			    position: relative;
			    margin-top: 30px;
			    width: 100%;
			}
		}
		.initial-cnt {
		}
		&.submission-ok {
			.initial-cnt {
			    display: none;
			}
			.after-cnt {
				display: block;
				-webkit-animation-duration: 0.5s;
			    animation-duration: 0.5s;
			    -webkit-animation-fill-mode: both;
			    animation-fill-mode: both;

			}
		}
	}
	.img-bg {
		transition: all 0.3s ease-out;
		background-image: url(../images/kidlet-newsletter-bg.png);
		background-position: 50%;
	    background-size: contain;
	    background-repeat: no-repeat;
	    min-height: 640px;
	    position: absolute;
	    left: -60%;
	    right: -60%;
	    top: 0px;
	    bottom: auto;
	    transform:rotate(90deg);
	    &.yellow-bg {
	    	// //background-image: url(../images/kidlet-newsletter-bg-yellow.png);
	    	// transition: all 0.3s ease-out;
	    	// left: 0%;
	    	// //right: 6.33%;
	    }
	}
}


// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	.kidlet-newsletter {
		margin-top: 130px;
		.form-inner {
			padding: 30px;
			background-color: rgba(255, 255, 255, 0.98);
			box-shadow: 0px -15px 19px 3px rgba(0,0,0,.2);
			&:before {
				content: '';
				position: absolute;
				top: -30px;
				left: -30px;
				right: -30px;
				bottom: -30px;
				background-color: transparent;
				border: 5px solid #000;
				z-index: -1;
				transition: all 0.3s ease-out;
				height: auto;
			}	
			h2 {
				color: #000;
				font-size: 24px;
				width: 80%;

			}
			hr {
				margin-top: 2rem;
	    		margin-bottom: 2rem;
			}
		}
		.img-bg {
		    left: 8.33%;
		    right: 8.33%;
		    top: -28%;
		    bottom: 0;
		    transform:rotate(0deg);
		    transition: all 0.3s ease-out;
		    &.yellow-bg {
		    	//background-image: url(../images/kidlet-newsletter-bg-yellow.png);
		    	left: 6.33%;
		    	right: 6.33%;
		    	transition: all 0.3s ease-out;
		    }
		}
	}



}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}