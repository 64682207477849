section.blog-listing {
  .title-section {
    margin-bottom: 30px;
  }
  .section-title {
    margin-top: 10px;
  }
  .section-link {
    text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 1px;
      a {
      &:hover {
        .lnr {
          transition: all .3s ease;
          margin-left: 15px;
        }
      }
    }
      .lnr {
        color: red;
        margin-left: 10px;
        -webkit-text-stroke: 1px red;
        position: relative;
        top: 1px;
        transition: all .3s ease;
      }
  }
  .blog-listing-content {
    article {
      height: calc( 100% - 30px );
      margin-bottom: 30px;
      a {
        display: block;
        background-color: #fff;
        box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.03);
        border-radius: 5px;
        height: 100%;
        text-decoration: none;
        &:hover {
          span {
            &.article-featured-image {
              span {
                transform: scale(1.1);
              }
              &:after {
                opacity: 0.75;
              }
            }
          }
        }
        span {
          display: block;
          &.article-featured-image {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 60%;
            border-radius: 5px 5px 0 0;
            overflow: hidden;
            span {
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              filter: grayscale(1);
              z-index: 1;
              transition: all 0.3s ease;
              transform: scale(1);
            }
            &:after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;        
              background-color: #fea782;
              background-image: -webkit-linear-gradient(45deg, #fea782 0%, #f87473 100%);
              background-image: -o-linear-gradient(45deg, #fea782 0%, #f87473 100%);
              background-image: linear-gradient(45deg, #fea782 0%, #f87473 100%);
              z-index: 5;
              transition: all 0.3s ease;
              opacity: 0;  
            }
          }
          &.content-side {
            padding: 20px 15px;
          }
          &.entry-title {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 25px;
          }
          &.entry-summary {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            margin-bottom: 25px;
          }
          &.entry-readmore {
            color: #2c6df3;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 20px;
            position: relative;
            display: inline-block;
            padding-right: 15px;
            line-height: 14px;
            &:after {
              content: "";
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 5.5px 0 5.5px 6px;
              border-color: transparent transparent transparent#2c6df3;
              position: absolute;
              top: calc( 50% - 5.5px );
              right: 0;
            }
          }
        }
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  
  

}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  
    

}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

  section.kdl-promo-slider {
    .section-title {
      margin-top: 0;
    }
  }

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}