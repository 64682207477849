section.benefits-slider-newsl {
	.newsl-cta {
		padding: 40px 0 40px 0;
		position: relative;
		margin-bottom: 100px;
		.btn-hold {
			padding-left: 16.6666%;
			margin-top: 30px;
			a.btn {
				border-radius: 24px;
				background-color: #000;
				color: #fff;
				font-weight: 800;
				font-size: 24px;
				padding-left: 40px;
				padding-right: 40px; 
			}
		}
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: -30px;
			right: 50%;
			bottom: 0;
			background-color: #caefcc;
			z-index: -1;
			border-radius: 6px;
		}
		&:after {
			content: '';
			background-image: url('../images/nesletter-pattern.svg');
			width: 160px;
			height: 117px;
			position: absolute;
			bottom: -60px;
    		left: -25%;
    		background-repeat: no-repeat;
		}
	}
	.benefits-slider-inner {
		padding: 40px 20% 30px 30px;
		position: relative; 
		margin-left: 0;
		h2 {
			color: #99acbf;
			position: relative;
		}		
		.benefits-slider-item { 
			padding: 15px 0;
			transition: all 0.3s;
			p {
				color: #717f8c;
				font-size: 16px;
				line-height: 20px;
				margin: 0;
			}
			h3 {
				color: #62788c;
				font-size: 24px;
				font-weight: 300;
				margin-bottom: 15px;
			}
		}
		&:before {
			content: '';
			top: 0;
			left: 0;
			right: 0;
			bottom: 40%;
			background-color: #edf6ff;
			position: absolute;
			border-radius: 6px;
		}
	}
	.navigation-slick-benefits {
		position: absolute;
		right: 20px;
		top: 46%;
		span.up,
		span.down {
			position: relative;
			-webkit-text-stroke: 1px #99acbf;
			color: #99acbf;
			font-size: 24px;
			cursor: pointer;
		}
		span.up {
			top: -20px;
		}
		span.down {
			bottom: -20px;
		}
	}
	.pagingInfo {
		position: absolute;
		right: 10px;
		top: 50%;
		color: #99acbf;
		font-size: 16px;
		span {
			font-size: 22px;
		}
	}
}


// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.benefits-slider-newsl {
		.newsl-cta {
			margin-bottom: 60px;
		}
		.benefits-slider-inner {
			margin-left: 3%;
			padding: 50px 20% 30px 30px;
		}
		.navigation-slick-benefits {
			right: 45px;
		}
		.pagingInfo {
			right: 34px;
		}
		.newsl-cta {
			padding: 40px 40px 40px 10px;
		}
	}

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}
