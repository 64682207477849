//Kidlet Newsletter Page

section.kidlet-newsletter-recomanda {
	font-family: 'Raleway';
	margin: 60px 0;
	.inactive-message {
		display: none;
	}
	&.inactive {
		.kidlet-newsletter-bg {
			.promo-desc {
				visibility: hidden;
				opacity: 0;
			}
			.form-inline,
			.success-message {
				display: none;
			}
			.inactive-message {
				display: block;
				color: #000;
				padding: 30px 30px 60px;
				h3 {
					color: #000;
					margin-bottom: 20px;
				}
				.icon-holder {
					display: block;
					text-align: center;
					margin-bottom: 30px;
				}
				i {
					color: #ff7970;
					border-radius: 50%;
					font-size: 80px;
				}
				h4 {
					font-size: 16px;
				}
				a.kdl-submit {
					padding-left: 30px;
					padding-right: 30px;
					margin-left: 0;
					margin-top: 30px;
					display: block;
					font-size: 16px;
				}
			}
		}
	}
	h2, h3 {
		color: #000;
		font-weight: 900;
		font-style: normal;
		font-family: 'Raleway';
		margin-left: 15px;
		letter-spacing: 2px;
	}
	h2 {
		font-size: 40px;
		margin-top: 0;
	}
	h3 {
		font-size: 34px;
		margin-top: 0;
		margin-bottom: 30px;
	}
	.kidlet-newsletter-inner {
		position: relative;
		.img-holder {
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			min-height: 510px;
		}
	}
	.kidlet-newsletter-bg{
		padding: 60px 15px;
		color: #fff;
		font-size: 16px;
		-webkit-transition: all 0.9s ease;
		-moz-transition: all 0.9s ease;
		-ms-transition: all 0.9s ease;
		-o-transition: all 0.9s ease;
		transition: all 0.9s ease;
		h3 {
			color: #fff;
			margin-bottom: 0;
			margin-left: 0;
		}
		span.bg {
			position: absolute;
			display: block;
			top: 0;
			left: 15px;
			bottom: 0;
			right: 15px;
			-webkit-transition: all 0.9s ease;
			-moz-transition: all 0.9s ease;
			-ms-transition: all 0.9s ease;
			-o-transition: all 0.9s ease;
			transition: all 0.9s ease;
		}
		span.bg-soft {
			//background-color: #ff7b75;
			background: linear-gradient(45deg,#ff7b75 0%,#e086a7 100%);
			mix-blend-mode: soft-light;
			box-shadow: 0px 8px 18px 2px rgba(0, 0, 0, 0.15);
		}
		span.bg-multi {
			background-color: #ff7b75;
			mix-blend-mode: multiply;
		}
	}
	ul.principles {
		margin-top: 15px;
		padding: 15px 15px 30px;
		list-style: none;
		background-color: rgba(0,0,0,.95);
		li {
			margin-top: 20px;
			word-wrap: break-word;
			span {
				font-size: 14px;
				font-style: italic;
			}
		}
	}

	.news-form {
		background-color: #fff;
		margin-top: 30px;
		padding: 25px 15px 25px 25px;
		box-shadow: 0px 8px 18px 2px rgba(0, 0, 0, 0.15);
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		-ms-transition: all 0.3s ease;
		-o-transition: all 0.3s ease;
		transition: all 0.3s ease;
		min-height: 300px;

		span.obs {
			display: block;
			font-size: 14px;
			font-weight: 500;
    		font-style: italic;
    		color: #888;
		}
		h4 {
			font-size: 22px;
			margin-top: 0;
			margin-bottom: 20px;
			font-weight: 400;
			font-family: 'Raleway';
			&.you {
				color: #ff7280;
			}
			&.friend {
				color: #a82858;
			}
		}
		.form-group {
			padding-left: 0;
			padding-right: 30px;
			margin-bottom: 20px;
			position: relative;
			background-color: #fff;
			-webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-ms-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;
			transition: all 0.3s ease;
			&:after {
				content: '';
				-webkit-transition: all 0.3s ease;
				-moz-transition: all 0.3s ease;
				-ms-transition: all 0.3s ease;
				-o-transition: all 0.3s ease;
				transition: all 0.3s ease;
			}
			input.form-control {
				width: 100%;
				border-radius: 0;
				box-shadow: none;
				border: 2px solid #ff7b75;
				height: 48px;
				&.lilla {
					border: 2px solid #c24874;
				}
			}
			&.mail-ok {
				&:after {
					content: '\f00c';
					width: 48px;
					height: 48px;
					right: -96px;
					background-color: #b8e6be;
					position: absolute;
					font-family: 'FontAwesome';
					color: #5ca070;
					text-align: center;
					line-height: 48px;
					font-size: 20px;
					top: 0;
					box-shadow: 0px 8px 18px 2px rgba(0, 0, 0, 0.15);
					-moz-transform: translateX(-48px);
					-webkit-transform: translateX(-48px);
					-o-transform: translateX(-48px);
					-ms-transform: translateX(-48px);
					transform: translateX(-48px);
					-webkit-transition: all 0.3s ease;
					-moz-transition: all 0.3s ease;
					-ms-transition: all 0.3s ease;
					-o-transition: all 0.3s ease;
					transition: all 0.3s ease;
				} 
			}
			&.mail-invalid {
				&:after {
					content: '\f00d';
					background-color: #f7c5c6;
					color: #f85563;
					//display: block;
					width: 48px;
					height: 48px;
					right: -96px;
					position: absolute;
					font-family: 'FontAwesome';
					text-align: center;
					line-height: 48px;
					font-size: 20px;
					top: 0;
					box-shadow: 0px 8px 18px 2px rgba(0, 0, 0, 0.15);
					-moz-transform: translateX(-48px);
					-webkit-transform: translateX(-48px);
					-o-transform: translateX(-48px);
					-ms-transform: translateX(-48px);
					transform: translateX(-48px);
					-webkit-transition: all 0.3s ease;
					-moz-transition: all 0.3s ease;
					-ms-transition: all 0.3s ease;
					-o-transition: all 0.3s ease;
					transition: all 0.3s ease;
				}
				& + .invalid-message {
					display: inline-block;
				}
			}
			::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: #888;
			  font-size: 14px;
			}
			::-moz-placeholder { /* Firefox 19+ */
			  color: #888;
			  font-size: 14px;
			}
			:-ms-input-placeholder { /* IE 10+ */
			  color: #888;
			  font-size: 14px;
			}
			:-moz-placeholder { /* Firefox 18- */
			  color: #888;
			  font-size: 14px;
			}
		}
		.invalid-message {
			color: #f85563;
			font-style: italic;
			font-size: 14px;
			display: none;
			margin-top: -10px;
			margin-bottom: 20px;
		}
	}
	.success-message {
		color: #4d0925;
		padding: 60px 30px 100px;
		display: none;
		background-color: #fff;
		.icon-holder {
			display: block;
			text-align: center;
			margin-bottom: 30px;
		}
		i {
			color: #9de7a6;
			border-radius: 50%;
			border: 4px solid #b8e6be;
			padding: 20px 22px;
			font-size: 50px;
		}
		h3 {
			color: #000;
			text-align: center;
			margin-bottom: 20px;
		}
		h4 {
			text-align: center;
			color: #000;
			margin-bottom: 30px;
			font-size: 16px;
		}
		span.code-field {
			border: 3px solid #ff7b75;
			color: #ff7b75;
			font-size: 34px;
			font-weight: 900;
			display: block;
			text-align: center;
			margin-bottom: 20px;
			padding: 10px 0;

		}
		.btn {
			background: #fff;
		    font-weight: 800;
		    color: #1f030e;
		    padding: 5px 20px;
		    border-radius: 0;
		    border: 3px solid #4d0925;
		    -webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-ms-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;
			transition: all 0.3s ease;
		    &:hover,
		    &:active,
		    &:focus {
		    	border-color: #ff7b75;
		    	outline: none;
		    	color: #ff7b75;
		    	-webkit-transition: all 0.3s ease;
				-moz-transition: all 0.3s ease;
				-ms-transition: all 0.3s ease;
				-o-transition: all 0.3s ease;
				transition: all 0.3s ease;
		    }
		}
	}
	.button-holder {
		margin: 15px 0;
	}
	a.kdl-submit {
		margin-left: -35px;
		padding: 1px 80px 9px 80px;
		background-color: #fff;
		border-radius: 0;
		font-size: 24px;
		font-weight: 900;
		font-family: 'Raleway';
		text-transform: uppercase;
		color: #4d0925;
		border: 3px solid #4d0925;
		position: relative;
		z-index: 50;
		margin-top: 5px;
		&:before {
			content: '';
		    border: 3px solid #4d0925;
		    bottom: 2px;
		    right: 2px;
		    top: -8px;
		    left: -8px;
		    position: absolute;
		    z-index: -1;
		    display: block;
		    background-color: #fff;

		}
	}
}
.popover {
    border-radius: 0;
    font-family: 'Raleway';
    font-weight: 500;
}



// MEDIA QUERIES

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {



}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

	section.kidlet-newsletter-recomanda {
		h2, h3 {
			margin-left: 40px;
		}
		.kidlet-newsletter-bg {
		    padding: 60px 15px 60px 40px;
		}
		ul.principles {
			margin: 0;
    		padding: 0;
    		background-color: transparent;
		}
		.kidlet-newsletter-bg {
			span.bg {
			    bottom: 120px;
			    right: 15px;
			}
		}
		&.inactive {
			.kidlet-newsletter-bg {
				.inactive-message {
					a.kdl-submit {
						font-size: 24px;
					}
				}
			}
		}
	}


}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}






