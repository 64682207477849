.kdl-log-reg-forms {
	background-color: #fff;
	max-width: 600px;
	border-radius: 3px;
	padding: 30px;
	.login-head {
		text-align: center;
		h2 {
			font-weight: 800;
			font-size: 28px;
			color: #000;
			i {
				position: relative;
				top:2px;
				margin-left: 5px;
			}
		}
	}
	.login-body {
		a.lost-pwd {
			font-weight: 600;
			font-size: 15px;
		}
		button.btn {
			border-radius: 3px;
			text-transform: uppercase;
			padding: .65rem 1rem;
			font-weight: 800;
			font-family: 'Nunito';
		}
		button.modify {

		}
		button.facebook {
			background-color: #295396;
			border: 1px #295396 solid;
			margin-top: 20px;
			i {
				font-size: 24px;
			    position: relative;
			    top: 2px;
			}
		}
		.register-link {
			padding-top: 20px;
			a {
				font-size: 14px;
				font-weight: 700;
				color: #000;
				text-transform: uppercase;
				&:hover {
					color: #0275d8;
					text-decoration: underline;
				}
			}
		}
	}
}

body.blackfriday {
	.product-list .product-item-wrap span.img-holder {
		&:after {
			content: '';
			background-image: url('../images/kdl-bg-badge.png');
			background-repeat: no-repeat;
			background-position: center;
			width: 42px;
			height: 42px;
			position: absolute;
			left: 0;
			bottom: 10px;
			right: auto;
		}
	}
	.catalog-page-wrap .catalog-header-inner .promo-links {
		display: none;
	}
}

.main-content {
	overflow: hidden;
	padding-bottom:150px;
}

#kidlet-mobile-cnt:not(.mm-menu) {
	display: none;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

.popover {
	box-shadow: 0px -5px 30px 0px rgba(0,0,0,.1);
	border: none;
	&.bs-tether-element-attached-top {
		margin-top: -10px;
		&:after {
			border-bottom-color: #ffffff;
			
		}
		&:before {
			border-bottom-color: #ffffff;
		}
	}
	
}

.animate-out {
	-webkit-animation-delay: 0ms;
	animation-delay: 0ms;
}

.owl-item-kdl .animated  {
	-webkit-animation-duration : 900ms;  
	animation-duration: 900ms;

	-webkit-animation-delay: 300ms;
	animation-delay: 300ms;
	-webkit-animation-fill-mode : both;
	animation-fill-mode : both;
}

.owl-item.animated  {
	-webkit-animation-duration: 900ms;
	animation-duration: 900ms;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}  


/* .owl-animated-out - only for current item */ 
/* This is very important class. Use z-index if you want move Out item above In item */ 
.owl-animated-out {
	z-index : 1; 
}
/* .owl-animated-in - only for upcoming item
/* This is very important class. Use z-index if you want move In item above Out item */ 
.owl-animated-in {
	z-index : 0; 
}

// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {


}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {


}