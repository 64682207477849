//Kidlet OVS

section.kidlet-ovs {
	.ovs-header {
		margin-bottom: 0;
	}
	.ovs-hero-text {
		margin: -50px 15px 15px;
		background-color: #fff;
		.ovs-hero-text-inner {
			position: relative;
			background-color: #fff;
			z-index: 10;
			padding: 50px;
		}
		h1.ovs-title {
			font-size: 35px;
			font-weight: 800;
			color: #000;
			margin-top: 0;
			margin-bottom: 30px;
			margin-left: 0;
			margin-right: 0;
		}
		p {
			color: #474747;
			font-size: 18px;
		}
	}
	.gender-img-holder {
		position: relative;

		.img-link {
			position: absolute;
			left: 0;
			right: 0;
			top: 45%;
			text-align: center;
		}
		.btn-ovs {
			background-color: rgba(245,245,245,.8);
			font-size: 26px;
			font-weight: 700;
			color: #000;
			border-radius: 0;
			padding-left: 15px;
			padding-right: 15px;
			&:hover {
				background-color: rgba(240,237,130,.8);
			}
		}
	}
	.ovs-girl-title {
		h2 {
			margin-top: 0;
			background-color: #fff;
			padding: 28px;
			margin-left: -120px;
			font-size: 32px;
			font-weight: 800;
			margin-bottom: 0;
		}
		p {
			color: #474747;
			font-size: 18px;
			margin-top: -27px;
		}
		&.mob {
			h2 {
				margin-left: 0;
				padding: 15px;
				padding-left: 0;
			}
			p {
				margin-top: 0;
			}
		}
	}
	.ovs-boy-title {
		margin-top: 4%;
		h2 {
			margin-top: 0;
			background-color: #fff;
			padding: 45px;
			margin-right: -120px;
			font-size: 28px;
			font-weight: 800;
			margin-bottom: 0;
			z-index: 10;
			position: relative;
			text-align: right;
		}
		p {
			color: #474747;
			font-size: 18px;
			margin-top: -27px;
			position: relative;
			z-index: 15;
		}
		&.mob {
			h2 {
				margin-right: 0;
				text-align: left;
				padding: 15px;
				padding-left: 0;
			}
			p {
				margin-top: 0;
			}
		}
	}
	.ovs-girl-link {
		padding: 30px 0;
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				width: 40%;
				float: left;
				span {
					width: 50px;
					height: 50px;
					display: inline-block;
					text-align: center;
				}
				a {
					font-size: 18px;
					color: #204959;
				}
				img {
					margin-right: 15px;
				}
			}
		}
		&.boy {
			padding-top: 30px;
		}
	}
	.form-wrap {
		margin-top: 100px;
		h2 {
			margin-top: 0;
			background-color: #fff;
			font-size: 28px;
			font-weight: 800;
			margin-bottom: 30px;
		}
		p {
			color: #474747;
			font-size: 18px;
		}
	}
	#disney-form {
		margin-top: 0; 
	}
	.form-text {
		h2 {
			margin-top: 0;
			background-color: #fff;
			font-size: 28px;
			font-weight: 800;
			margin-bottom: 30px;
		}
		p {
			color: #474747;
			font-size: 18px;
		}
	}
}

// MEDIA QUERIES

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
	
	section.kidlet-ovs { 
		.ovs-hero-text {
			margin: -185px 120px 0 120px;
		}
		.ovs-header {
			margin-bottom: 30px;
		}
		.ovs-girl-link {
			padding: 80px 0;
			&.boy {
				padding-top: 150px;
			}
		}
	}

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}