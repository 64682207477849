.single-newsletter {
	background-image: url('../images/single-news-bg-1.png');
	background-size: auto;
	background-repeat: no-repeat;
	border-radius: 3px;
	padding: 0 15px;
	.single-newsletter-inner {
		background-color: #fff;
    	margin-top: 50px;
    	padding: 30px 15px 10px 15px;
    	border-radius: 3px;
    	h6 {
    		color: #999;
    		font-size: 12px;
    		text-transform: uppercase;
    		margin-bottom: 10px;
    	}
    	h2 {
    		color: #000;
    		font-weight: 800;
    		font-size: 20px;
    	}
	}
	.newsl-form-section {
		margin-top: 30px;
	}
	.kdl-notification {
		padding: 20px 15px 20px 45px;
		background-color: #e5fafa;
		border-radius: 3px;
		position: relative;
		margin-top: 40px;
		.not-badge {
			position: absolute;
			font-size: 12px;
			color: #fff;
			font-weight: 800;
			background-color: #0b484f;
			top: 0;
			left: -17px;
			right: auto;
			bottom: 0;
			border-radius: 50%;
			text-align: center;
			width: 34px;
    		height: 34px;
    		margin: auto 0;
    		z-index: 5;
    		i {
    			position: relative;
    			top: 7px;
    			font-style: normal;
    		}
		}
		.not-bg {
			background-image: url('../images/single-newsl-bg-notif.png');
			background-repeat: no-repeat;
			background-position: right;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 30px;
		}
		span.title {
			display: block;
			color: #0b484f;
			padding-bottom: 15px;
			font-size: 18px;
			font-weight: 800;
			border-bottom: 1px solid #0b484f;
		}
		p {
			color: #0b484f;
			margin-top: 15px;
			font-size: 14px;
			margin-bottom: 0;
		}
	}
	form.single-newsletter-form {
		button {
			border-radius: 3px;
			border: 3px solid #000;
			color: #000;
			font-size: 22px;
			text-transform: uppercase;
			margin-top: 15px;
			font-family: 'Nunito';
			font-weight: 700;
			cursor: pointer;
			background-color: #fff;
			&:hover,
			&:focus {
				background-color: #000;
				color: #fff;
			}
		}
		.form-check {
			margin-top: 20px;
			input {
				margin-left: 0;
				margin-top: 9px;
			}
			label.form-check-label {
				font-size: 12px;
				font-weight: 600;
				color: #bebebe;
				padding-left: 25px;

			}
		}
		.form-group {
			position: relative;
			label {
				position: absolute;
				background-color: transparent;
				padding: 1px 6px;
				left: 15px;
				top: 11px;
				font-size: 12px;
				color: #999;
				text-transform: uppercase;
				margin: 0;
				opacity: 0;
				display: block;
				transition: all 0.3s ease;
			}
			input {
				padding: 12px 15px;
				border-radius: 3px;
				border-color: #d4d4d4;
				margin-bottom: 20px;
				color: #000;
				// &:focus ~ label {
				//     background-color:purple;
				//     color: red;
				//     top: -11px;
				//     opacity: 1;
				// }
			}
			input:not(:placeholder-shown) {
				~ label,
				+ label {
				    background-color: #fff;
				    color: #999;
				    transform: translateY(-19px);
				    opacity: 1;
				    transition: all 0.3s ease;
				    font-size: 10px;
				}
			}
			input:not(:placeholder-shown),
			input:focus {
				border-color: #000;
			}

			input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  font-size: 12px;
			  text-transform: uppercase;
			  color: #999;
			}
			input::-moz-placeholder { /* Firefox 19+ */
			  color: pink;
			}
			input:-ms-input-placeholder { /* IE 10+ */
			  color: pink;
			}
			input:-moz-placeholder { /* Firefox 18- */
			  color: pink;
			}
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  
	
	.single-newsletter {
		.single-newsletter-inner {
	    	margin-top: 80px;
	    	padding: 50px 40px 10px 40px;
	    	h6 {
	    		margin-bottom: 15px;
	    	}
	    	h2 {
	    		font-size: 22px;
	    	}
		}
		.newsl-form-section {
			margin-top: 50px;
		}
		.kdl-notification {
			padding: 40px 25px 30px 90px;
			margin-top: 0;
			.not-badge {
				top: -17px;
				left: 0;
				right: 0;
				bottom: auto;
				margin: 0 auto;
	    		i {
	    			top: 7px;
	    		}
			}
			.not-bg {
				width: 60px;
			}
			span.title {
				padding-bottom: 15px;
				font-size: 18px;
				font-weight: 800;
			}
			p {
				margin-top: 15px;
				font-size: 14px;
			}
		}
		form.single-newsletter-form {
			button {

			}
			.form-check {
				margin-top: 30px;
			}
			.form-group {
				input {
					margin-bottom: 40px;
				}
			}
		}
	}


}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}