/**
*About Us General
*/

.kidlet-about-wrap {
	font-size: 17px;
	font-weight: 600;
	color: #000;
	margin-top: 30px;
	h1 {
		color: #000;
		font-weight: 800;
		font-size: 32px;
	}
	h2 {
		color: #000;
		font-weight: 800;
		font-size: 22px;
	}
	a {
		color: #000;
	}
	p {
		line-height: 22px;
	}
}

.kdl-divider {
	margin: 15px 0;
	height: 1px;
	width: 100%;
	display: block;
}

.kdl-divider-large {
	margin: 30px 0;
	height: 1px;
	width: 100%;
	display: block;
}

.kdl-divider-40 {
	margin: 20px 0;
	height: 1px;
	width: 100%;
	display: block;
}

section.hero {
	font-size: 16px;
	.hero-wrap {
		background-repeat: no-repeat;
		background-position: right top;
		background-size: auto;
		background-image: none;
	}
	h1 {
		margin-top: 0;
		margin-bottom: 10px;
	}
	h2.light {
		color: #e04553;
		font-weight: 300;
		margin-bottom: 30px;	
	}
	p {
		margin-bottom: 30px;
		max-width: 100%;
	}
	.video-link-wrap {
		margin-top: 25px;	
		padding-top: 100px;
		a {
			display: block;
			width: 100%;
			height: 100%;
			position: relative;
		}
		img.video-bg {
			box-shadow: 0px 5px 45px 0px rgba(0, 0, 0, 0.15);
		}
		span.video-title {
			position: absolute;
			background-color: #fff;
			padding: 15px 25px;
			display: block;
			bottom: auto;
			left: auto;
			right: -15px;
			top: -90px;
			.lnr {
				font-size: 60px;
				color: #e74b58;
				position: absolute;
				left: -15%;
				top: -40%;
			}
		}
		span.play {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			width: 60px;
			height: 60px;
			border-radius: 100%; 
			background-color: #e22e35;
			&:before {
				content: '';
				position: relative;
				display: block;
				width: 300%;
				height: 300%;
				box-sizing: border-box;
				margin-left: -100%;
				margin-top: -100%;
				background-color: #e02f36;
				animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
				border-radius: 100%; 
			}

			&:after {
				content: '';
				position: absolute;
				left: 0; 
				top: 0;
				display: block;
				width: 100%;
				height: 100%;
				background-color: #e02f36;
				box-shadow: 0 0 8px rgba(0,0,0,.3);
				animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
				border-radius: 100%; 
			}
			.icon {
				top: 15px;
    			left: 20px;
				position: absolute;
				z-index: 10;
			}
		}

		@keyframes pulse-ring {
			0% {
				transform: scale(.15);
			}
			80%, 100% {
				opacity: 0;
			}
		}

		@keyframes pulse-dot {
			0% {
				transform: scale(.75);
			}
			50% {
				transform: scale(1);
			}
			100% {
				transform: scale(.75);
			}
		}
	}

}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	.kdl-divider {
		margin: 30px 0;
	}
	section.hero {
		font-size: 17px;
		.hero-wrap {
			background-image: url(../images/kidlet-about-hero.jpg);
		}
		h1 {
			margin-top: 60px;
			margin-bottom: 10px;
		}
		h2.light {
			font-weight: 300;
			margin-bottom: 55px;	
		}
		p {
			margin-bottom: 34px;
			max-width: 90%;
		}
		.video-link-wrap {
			margin-top: 25px;
			padding-top: 0;	

			span.video-title {
				padding: 22px 15px;
				bottom: -15px;
				left: -45%;
				right: auto;
				top: auto;
			}
		}
	}
	
	.kidlet-about-wrap {
		font-size: 17px;
		margin-top: 60px;
		h1 {
			font-size: 36px;
		}
		h2 {
			font-size: 26px;
		}
		p {
			line-height: 26px;
		}
	}
	.kdl-divider-large {
		margin: 60px 0;
	}

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}



