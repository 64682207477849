//Mobile Filter

.mm-wrapper_opened {
	#kidlet-wrap {
		overflow: hidden;
	}
}

#kdl-filter {
	.mm-panels {
    	overflow: unset;
    	overflow-y: scroll;
    	.mm-panel {
    		overflow-y: scroll;
    	}
	} 
	.filter-head {
		margin-bottom: 20px;
		h4 {
			font-size: 18px;
			font-weight: 600;
			margin-bottom: 0;
			margin-top: 11px;
			color: #000;
		}
		a.aplica {
		    color: #fff;
		    background-color: #90cc90;
		    border-color: #90cc90;
		    font-weight: 700;
		    border-radius: 0;
		}
	}
	#filter-accordion {
		.card {
			border: none;
			border-radius: 0;
			.card-header {
				border-radius: 0;
				background-color: transparent;
    			border-bottom: 1px solid #c8def0;
    			padding: 0;
    			h5{
    				a {
    					display: block;
    					color: #3a7eab;
    					font-size: 16px;
    				    padding: 15px 30px;
    				    background-color: #c8def0;
    				    transition: all 0.3s ease;
    				    position: relative;
    				    .circle-plus {
    				    	.vertical {
							    transition: all 0.5s ease-in-out;
							    transform: rotate(-90deg);
							  }
							  .horizontal {
							    transition: all 0.5s ease-in-out;
							    transform: rotate(-90deg);
							    opacity: 1;
							  }
    				    }
    				    &.collapsed {
							background-color: #dce9f5;
							color: #4595c9;
							padding: 15px 20px;
							transition: all 0.3s ease;
							.circle-plus {
								opacity: 1;
								.vertical {
									transition: all 0.5s ease-in-out;
									transform: rotate(90deg);
								}
								.horizontal {
									transition: all 0.5s ease-in-out;
									transform: rotate(90deg);
									opacity: 0;
								}
							}
    				    }
    				}
    			}
    			&#headingActiveFilter {
    				border-bottom: 1px solid #efa2a4;
					h5 {
						a {
							background-color: #f7c5c6;
	    					color: #f05764;
							.circle-plus {
    				    		.vertical {
									background-color: #f05764;
								}
								.horizontal {
									background-color: #f05764;
								}
	    				    }
							&.collapsed {
								background-color: darken(#f7c5c6,5%);
	    						color: #f05764;
							}
						}
					}
				}
			}
			.card-block {
				background-color: #f3f9ff;
				ul.kidlet-filter-list {
					list-style: none;
					margin: 0;
					padding: 0;
					padding-left: 20px;
					li {
						margin: 1px 0;
						a  {
							color: #252525;
							font-size: 12px;
							padding-left: 20px;
							padding-top: 2px;
							padding-bottom: 2px;
							position: relative;
							&:hover {
								color: #000;
								&:before {
									border: 1px solid #000;
								}

							}
							&:before {
								content: '';
								width: 13px;
								height: 13px;
								background-color: transparent;
								border: 1px solid #6b6b6b;
								position: absolute;
								left: 0;
								top: 3px;
							}
							&.active,
							&:active {
								color: #000;
								&:after {
									content: '';
									position: absolute;
									background-image: url('../images/checkmark-blue.svg');
									background-size: contain;
									fill: red;
									left: 3px;
									top: 7px;
									width: 7px;
									height: 5px;
								}
								&:before {
									background-color: #c8def0;
									border-color: #8bc5eb;
								}
								&:hover {
									&:before {

									}
								}
							}
							span.count {
								margin-left: 3px;
								color: lighten(#000,60%);
							}
						}
					}
					&.active-filters {
						li {
							a {
								display: inline-block;
								&:before {
									display: none;
								} 
								&:after {
									content: "";
								    width: 10px;
								    height: 10px;
								    background-image: url(../images/kidlet-remove-icon.svg);
								    background-size: contain;
								    top: 6px;
								    position: absolute;
								    left: 2px;
								}
								&.active,
								&:hover,
								&:focus,
								&:active {
									color: #f05764;
									&:after {
										content: "";
									    width: 10px;
									    height: 10px;
									    background-image: url(../images/kidlet-remove-icon.svg);
									    background-size: contain;
									    top: 6px;
									    position: absolute;
									    left: 2px;
									}
								}
								&.delete-filter {
									color: #f05764;
									font-weight: 700;
									margin-top: 5px;
									&:hover {
										text-decoration: underline;
									}
								}
							}
							&:last-child {
								a {
									color: #f05764;
									font-weight: 700;
									margin-top: 5px;
									&:hover {
										text-decoration: underline;
									}
								}
							}
						}
					}
					&.categori-links {
						li {
							a {
								color: #59a5d7;
							    padding: 5px;
								display: block;
							    font-weight: 600;
							    &:before {
							    	display:none;
							    }
							    &:after {
							    	display: none;
							    }
							    &.active {
							    	color: #4595c9;
    								font-weight: 700;
							    }
							}
						}
					}
				}
				ul.kidlet-filter-list-colors {
					list-style: none;
					margin: 0;
					padding: 0;
					@include clearfix;
					li {
					display: inline-block;
					float: left;
					margin: 3px 6px 3px 0;
						a {
							width: 27px;
							height: 27px;
							display: block;
							border: 1px solid transparent;
							background-color: transparent;
							position: relative;
							&:hover,
							&:focus,
							&:active,
							&.active {
								&:after {
									content: '';
									position: absolute;
									background-image: url('../images/checkmark-white.svg');
									background-size: contain;
									left: 6px;
									top: 8px;
									width: 14px;
									height: 10px;
								}
							}
							
							&.kdl-red {
								background-color: #f05764;
							}
							&.kdl-blue {
								background-color: #c8def0;
							}
							&.kdl-orange {
								background-color: #FFA04A;
							}
							&.kdl-yellow {
								background-color: #f6dd86;
							}
							&.kdl-green {
								background-color: #badfbc;
							}
							&.kdl-black {
								background-color: #000;
							}
							&.kdl-white {
								background-color: #fff; 
								border-color: #e8e8e8;
								&:hover,
								&:focus,
								&:active,
								&.active {
									&:after {
										background-image: url('../images/checkmark-black.svg');
									}
								}
							}
							&.kdl-turq {
								background-color: #8DC7BB;
							}
							&.kdl-violet {
								background-color: #A55FEB;
							}	
						}
					}
				}
			}
			.collapse {
				&.show {

				}
			}
		}
		.circle-plus {
		    height: auto;
		    width: auto;
		    position: absolute;
		    right: 30px;

		}

		.circle-plus .circle {
		    position: relative;
		    width: 17px;
		    height: 17px;
		    display: block;
		}
		.circle-plus .circle .horizontal {
	        position: absolute;
		    background-color: #3a7eab;
		    width: 17px;
		    height: 1px;
		    left: 50%;
		    margin-left: -9px;
		    top: 50%;
		    margin-top: -1px;
		}
		.circle-plus .circle .vertical {
		    position: absolute;
		    background-color: #3a7eab;
		    width: 1px;
		    height: 17px;
		    left: 50%;
		    margin-left: -1px;
		    top: 50%;
		    margin-top: -9px;
		}
	}
}

#mobile-order-select {
	background-color: #fff;
	height: 70vh;
	padding: 30px;
	width: 80vw;
	.custom-control-input:focus~.custom-control-indicator {
	    box-shadow: 0 0 0 1px #fff, 0 0 0 3px #4595c9;
	}
	.custom-control-input:checked~.custom-control-indicator {
	    color: #fff;
	    background-color: #4595c9;
	}
}



