/* nunito-200 - latin_latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/nunito-v9-latin_latin-ext-200.eot'); /* IE9 Compat Modes */
  src: local('Nunito ExtraLight'), local('Nunito-ExtraLight'),
       url('../fonts/nunito-v9-latin_latin-ext-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v9-latin_latin-ext-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-200.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v9-latin_latin-ext-200.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-200italic - latin_latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200;
  src: url('../fonts/nunito-v9-latin_latin-ext-200italic.eot'); /* IE9 Compat Modes */
  src: local('Nunito ExtraLight Italic'), local('Nunito-ExtraLightItalic'),
       url('../fonts/nunito-v9-latin_latin-ext-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v9-latin_latin-ext-200italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-200italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v9-latin_latin-ext-200italic.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-300 - latin_latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/nunito-v9-latin_latin-ext-300.eot'); /* IE9 Compat Modes */
  src: local('Nunito Light'), local('Nunito-Light'),
       url('../fonts/nunito-v9-latin_latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v9-latin_latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v9-latin_latin-ext-300.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-300italic - latin_latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/nunito-v9-latin_latin-ext-300italic.eot'); /* IE9 Compat Modes */
  src: local('Nunito Light Italic'), local('Nunito-LightItalic'),
       url('../fonts/nunito-v9-latin_latin-ext-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v9-latin_latin-ext-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v9-latin_latin-ext-300italic.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-regular - latin_latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/nunito-v9-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local('Nunito Regular'), local('Nunito-Regular'),
       url('../fonts/nunito-v9-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v9-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v9-latin_latin-ext-regular.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-italic - latin_latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/nunito-v9-latin_latin-ext-italic.eot'); /* IE9 Compat Modes */
  src: local('Nunito Italic'), local('Nunito-Italic'),
       url('../fonts/nunito-v9-latin_latin-ext-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v9-latin_latin-ext-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v9-latin_latin-ext-italic.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-600 - latin_latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/nunito-v9-latin_latin-ext-600.eot'); /* IE9 Compat Modes */
  src: local('Nunito SemiBold'), local('Nunito-SemiBold'),
       url('../fonts/nunito-v9-latin_latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v9-latin_latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v9-latin_latin-ext-600.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-600italic - latin_latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/nunito-v9-latin_latin-ext-600italic.eot'); /* IE9 Compat Modes */
  src: local('Nunito SemiBold Italic'), local('Nunito-SemiBoldItalic'),
       url('../fonts/nunito-v9-latin_latin-ext-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v9-latin_latin-ext-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-600italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v9-latin_latin-ext-600italic.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-700 - latin_latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/nunito-v9-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
  src: local('Nunito Bold'), local('Nunito-Bold'),
       url('../fonts/nunito-v9-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v9-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v9-latin_latin-ext-700.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-700italic - latin_latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/nunito-v9-latin_latin-ext-700italic.eot'); /* IE9 Compat Modes */
  src: local('Nunito Bold Italic'), local('Nunito-BoldItalic'),
       url('../fonts/nunito-v9-latin_latin-ext-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v9-latin_latin-ext-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v9-latin_latin-ext-700italic.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-800 - latin_latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/nunito-v9-latin_latin-ext-800.eot'); /* IE9 Compat Modes */
  src: local('Nunito ExtraBold'), local('Nunito-ExtraBold'),
       url('../fonts/nunito-v9-latin_latin-ext-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v9-latin_latin-ext-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v9-latin_latin-ext-800.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-800italic - latin_latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 800;
  src: url('../fonts/nunito-v9-latin_latin-ext-800italic.eot'); /* IE9 Compat Modes */
  src: local('Nunito ExtraBold Italic'), local('Nunito-ExtraBoldItalic'),
       url('../fonts/nunito-v9-latin_latin-ext-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v9-latin_latin-ext-800italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-800italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v9-latin_latin-ext-800italic.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-900 - latin_latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/nunito-v9-latin_latin-ext-900.eot'); /* IE9 Compat Modes */
  src: local('Nunito Black'), local('Nunito-Black'),
       url('../fonts/nunito-v9-latin_latin-ext-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v9-latin_latin-ext-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v9-latin_latin-ext-900.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-900italic - latin_latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/nunito-v9-latin_latin-ext-900italic.eot'); /* IE9 Compat Modes */
  src: local('Nunito Black Italic'), local('Nunito-BlackItalic'),
       url('../fonts/nunito-v9-latin_latin-ext-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v9-latin_latin-ext-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-900italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v9-latin_latin-ext-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v9-latin_latin-ext-900italic.svg#Nunito') format('svg'); /* Legacy iOS */
}