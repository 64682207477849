//Review Styles

.custom-control.custom-checkbox {
	position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    .custom-control-input {
		position: absolute;
	    z-index: -1;
	    opacity: 0;

    }
    .custom-control-input:checked~.custom-control-label::after {
		background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
		border-radius: .25rem;
    }
    .custom-control-input:checked~.custom-control-label::before {
    	background-color: #03cc97;
    	color: #fff;
    }
    .custom-control-label {
		margin-bottom: 0;
		&:before {
			position: absolute;
		    top: .25rem;
		    left: 0;
		    display: block;
		    width: 1rem;
		    height: 1rem;
		    pointer-events: none;
		    content: "";
		    -webkit-user-select: none;
		    -moz-user-select: none;
		    -ms-user-select: none;
		    user-select: none;
		    background-color: #7aadb1;
		    border-radius: .25rem;
		}
		&:after {
			position: absolute;
		    top: .25rem;
		    left: 0;
		    display: block;
		    width: 1rem;
		    height: 1rem;
		    content: "";
		    background-repeat: no-repeat;
		    background-position: center center;
		    background-size: 50% 50%;
		}
    }
}

section.kdl-reviews {
	margin-top: 30px;
	.rev-top-section {
		padding-top: 135px;
		padding-bottom: 165px;
		position: relative;
		&:before {
			content: '';
			width: 2px;
			height: 80px;
			background-color: #000;
			top: 30px;
			left: 0;
			right: 0;
			display: block; 
			text-align: center;
			position: absolute;
			margin: 0 auto;
		}
	}
	.rev-title-section {
		margin-bottom: 100px;
		span.subtitle {
			font-size: 14px;
			font-weight: 800;
			color: #999;
			display: block;
			margin-bottom: 10px;
			text-align: center;
		}
		h1 {
			font-weight: 800;
			color: #000;
			font-size: 26px;
			text-align: center;
		}
	}

	.rev-stat-section {
		.user-count {
			padding-right: 20px;
			span {
				font-size: 80px;
				font-weight: 200;
				display: inline-block;
				line-height: 1;
			}
			span.plus {
				font-size: 50px;
				position: relative;
				top: -14px;
			}
			.kdl-blue-txt {
				font-size: 14px;
				font-weight: 800;
				color: #2da4e1;
				display: block;
				text-align: right;
				position: relative;
				right: 3px;
				top: 4px;
			}
		}
		.rev-count {
			padding-left: 20px;
			position: relative;
			.rev-low,
			.rev-high {
				font-size: 80px;
				font-weight: 200;
				display: inline-block;
				line-height: 1;
			}
			.rev-low {
				position: relative;
				padding-right: 30px;
				&:after {
					content: '/';
					font-size: 30px;
					position: absolute;
					bottom: 10px;
					right: 5px;
					font-weight: inherit;
				}
			}
			.rating-wrap,
			.stars-wrap {
				display: inline-block;
			}
			.stars-wrap {
				margin-left: 15px;
				.stars,
				.all-rev {
					display: block;
				}
				.all-rev {
					font-size: 12px;
					font-weight: 700;
					color: #999;
				}
				.star-rating {
				    text-align: left;
				    font-size: 16px;
				    display: block;
				    overflow: hidden;
				    position: relative;
				    height: 1em;
				    line-height: 1em;
				    letter-spacing: 0;
				    margin: .5em 0;
				    width: 5.4em;
				    font-family: "kdl-review" !important;
				    -webkit-font-smoothing: antialiased;
				    -moz-osx-font-smoothing: grayscale;
				    &:before {
				    	content: '\65\65\65\65\65';
					    color: #f9cf42;
					    float: left;
					    top: 0;
					    left: 0;
					    position: absolute;
				    }
				    .star-rating-fill {
				    	overflow: hidden;
					    float: left;
					    width: 10px;
					    height: 10px;
					    top: 0;
					    left: 0;
					    font-family: "kdl-review" !important;
					    position: absolute;
					    padding-top: 1.5em;
					    font-weight: 400 !important;
					    &:before {
					    	content: '\62\62\62\62\62';
						    color: #f9cf42;
						    top: 0;
						    position: absolute;
						    left: 0;
					    }
				    }
    			}
			}
			.kdl-green-txt {
				font-size: 14px;
				font-weight: 700;
				color: #0bbf8f;
				display: block;
			}
			&:before {
				content: '';
				height: 100%;
				width: 1px;
				background-color: #dfdfdf;
				top: 0;
				left: -15px;
				display: block;
				bottom: 0;
				position: absolute;
			}
		}
	}

	.kdl-blue-txt {
		font-size: 14px;
		font-weight: 800;
		color: #2da4e1;
		display: block;
		text-transform: uppercase;
	}
	.star-rating {
	    text-align: left;
	    font-size: 16px;
	    display: block;
	    overflow: hidden;
	    position: relative;
	    height: 1em;
	    line-height: 1em;
	    letter-spacing: 0;
	    margin: .5em 0;
	    width: 5.4em;
	    font-family: "kdl-review" !important;
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
	    &:before {
	    	content: '\65\65\65\65\65';
		    color: #f9cf42;
		    float: left;
		    top: 0;
		    left: 0;
		    position: absolute;
	    }
	    .star-rating-fill {
	    	overflow: hidden;
		    float: left;
		    width: 10px;
		    height: 10px;
		    top: 0;
		    left: 0;
		    font-family: "kdl-review" !important;
		    position: absolute;
		    padding-top: 1.5em;
		    font-weight: 400 !important;
		    &:before {
		    	content: '\62\62\62\62\62';
			    color: #f9cf42;
			    top: 0;
			    position: absolute;
			    left: 0;
		    }
	    }
	}

	.rev-bottom-section {
		h2 {
			font-size: 26px;
			font-weight: 300;
			padding-bottom: 30px;
		}
	}

	.review-item {
		color: #000;
		padding: 40px 0;
		border-bottom: 1px solid #f3f3f3;
		.rev-meta {
			padding-right: 20px;
		}
		.rev-name {
			font-weight: 800;
			font-size: 18px;
			margin-bottom: 10px;
		}
		.rev-date {
			color: #999;
			font-size: 12px;
			padding-bottom: 5px
		}
		.verified-buyer {
			margin: 10px 0;
			padding-left: 28px;
			color: #10b68a;
			position: relative;
			font-size: 12px;
			font-weight: 700;
			&:before,
			&:after {
				content: '';
				font-family: "kdl-review" !important;
				font-style: normal !important;
				font-weight: normal !important;
				font-variant: normal !important;
				text-transform: none !important;
				speak: none;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
			&:before {
				content: '\61';
				position: absolute;
				left: 0;
				font-size: 22px;
				top: 0;
			}
			&:after {
				content: "\66";
			    position: absolute;
			    left: 6px;
			    font-size: 10px;
			    top: 6px;
			    color: #fff;
			}
		}
		.rev-title {
			font-size: 22px;
			margin-bottom: 16px;
		}
		.rev-cnt {
			font-size: 16px;
		}
		&:last-child {
			border-color: transparent;
		}

	}

	.review-form-wrap {
		padding-left: 15px;
		padding-bottom: 40px;
	}
	.review-form-cnt {
		padding: 40px 30px 50px 30px;
		background-color: #f4fffc;
		border-radius: 5px;
		abbr[title] {
		    border-bottom: none;
		    text-decoration: none;
		}
		.form-group {
			margin-bottom: 22px;
			label {
				color: #001621;
				font-size: 14px;
				font-weight: 700;
			}
			input.form-control,
			textarea.form-control {
				border-radius: 5px;
				border: 1px solid #7aadb1;
				background-color: transparent;
				padding: 12px 18px;
			}
		}
		.form-check {
			margin: 10px 0;
		}
		.submit-container {
			padding: 20px 0 10px 0;
			button.btn {
				background-color: #03cc97;
				font-weight: 700;
				font-size: 16px;
				color: #fff;
				padding: 15px 20px;
				border-radius: 5px;
				border-color: transparent;
				cursor: pointer;
				&:hover,
				&:focus,
				&:active {
					background-color: darken(#03cc97, 5%);
				}
			}
		}
		
		fieldset {
			margin: 0;
		    padding: 0;
		    border: 0;
		    font-size: 100%;
		    font: inherit;
		    vertical-align: baseline;
		}
		.star-rating-selector {
			display: flex;
		    flex-direction: column;
		    align-items: flex-start;
		    margin-bottom: 18px;
		    label.star-label-top {
		    	color: #001621;
				font-size: 14px;
				font-weight: 700;

		    }
			&:not(:checked) input {
				position: absolute;
			    left: -9999px;
			    clip: rect(0,0,0,0);
			}
			&:not(:checked) label.checkbox {
				float: right;
				cursor: pointer;
			    width: 1em;
			    margin-left: 1px;
			    margin-bottom: 0;
			    overflow: hidden;
			    white-space: nowrap;
			    cursor: pointer;
			    font-size: 32px;
			    line-height: 1em;
			    vertical-align: middle;
			    //color: #7aadb1;
			    color: #ffe036;
			    position: relative;
			    z-index: 1;
			    text-indent: -9999px;
			    display: inline-block;
			    &:before {
			    	display: inline-block;
				    position: absolute;
				    left: 0;
				    text-indent: 0;
				    font-family: "kdl-review";
				    content: "\65";
				    -webkit-font-smoothing: antialiased;
				    -moz-osx-font-smoothing: grayscale;
				    speak: none;
			    }
			}
		}
		.star-rating-selector:not(:checked) input:checked~label.checkbox:before {
			content: "\62";
			color: #f9cf42;
		}
		.star-rating-selector:not(:checked) label.checkbox:hover:before, .star-rating-selector:not(:checked) label.checkbox:hover~label.checkbox:before {
			content: "\62";
			color: #f9cf42;
		}
	}
	
	.custom-file {
		width: 100%;
		display: block;
		height: 46px;
		input {
			width: 100%;
			height: 100%

		}
	}
	span.custom-label {
		color: #001621;
		font-size: 14px;
		font-weight: 700;
	}
	.custom-file-input {
		position: relative;

	}
	span.fileuploadtext {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #75c0c6;
		border-radius: 5px;
		color: #f4fffc;
		padding: 12px 20px;
		display: block;
		cursor: pointer;
		i {
			position: relative;
			top: 1px;
			margin-right: 5px;
		}

	}

	.custom-control.custom-checkbox {
		a {
			color: #55999e;
		}
	}
	.rev-load-more-section {
		padding-top: 60px;
		padding-bottom: 100px;
		.btn-load-more {
			color: #10b68a;
			font-size: 16px;
			font-weight: 700;
			padding: 14px 60px;
			background-color: transparent;
			border: 2px solid #10b68a;
			border-radius: 5px;
			&:hover,
			&:focus,
			&:active {
				background-color: #10b68a;
				color: #fff;
			}
		}
	}
	.rev-footer-section {
		padding-bottom: 150px;
		h4 {
			font-size: 26px;
			font-weight: 300;
			margin-bottom: 30px;
		}
		.btn-rev-cta {
			text-transform: uppercase;
			color: #fff;
			background-color: #10b68a;
			font-weight: 800;
			font-size: 16px;
			border-radius: 5px;
		}
	}
}





