section.kdl-accseorii {
	margin-top: 100px;
	margin-bottom: 100px;
	.section-title {
		margin-bottom: 35px;
		margin-top: 30px;
		h2 {
			font-size: 24px;
		    font-weight: 800;
		    color: #000;
		    padding: 25px 30px;
		    background-color: #fff;
		    margin-left: 0;
		    letter-spacing: 1px;
			margin-bottom: 0;
		} 
	}
	.section-list {
  		padding-left: 15px; 
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			margin-bottom: 30px;
			span.list-title {
				font-style: italic;
				font-weight: 700;
				font-size: 15px;
				display: block;
				margin-bottom: 15px;
				font-family: 'Nunito';
			}
			li {
				a {
					color: #000;
					font-weight: 500;
					padding: 8px 0;
					display: block;
					font-size: 14px;
					transition: all .2s ease;
					transform: translateX(0px);
					&:hover {
						color: #54a5dd;
						transition: all .2s ease;
						transform: translateX(2px);
					}
				}
			}
		}
		.all-prod-link {
			display: block;
			font-style: italic;
			text-decoration: underline;
			margin-top: 30px;
		}
	} 
	.accesorii-fete-wrap {
		.description-wrap {
			margin-top: -130px;
			.section-title { 
				margin-bottom: 0;
				h2 {
					margin: 30px;
					padding: 40px 30px;
				}
			}
		}
	} 
	.accesorii-baieti-wrap {
		margin-top: 15px;
		margin-bottom: 0;
		.section-title {
		margin: 0; 
			h2 {
				margin-right: 0;
				margin-left: 0;
				position: relative;
				z-index: 10;
				padding-left: 15px;
				text-align: left;
				padding-top: 0;
			}
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  

	section.kdl-accseorii {
		margin-top: 100px;
		margin-bottom: 100px;
		.section-title {
			margin-bottom: 35px;
			margin-top: 30px;
		}
		.section-list {
	  		padding-left: 20px; 
			ul {
				margin: 0;
				li {
					a {
						font-size: 14px;
					}
				}
			}
			.all-prod-link {
				margin-top: 15px;
			}
		} 
		.accesorii-fete-wrap {
			.description-wrap {
				margin-top: 0;
				.section-title { 
					h2 {
						margin: 0;
						margin-left: -120px;
						padding: 25px 30px;
					}
				}
			}
		} 
		.accesorii-baieti-wrap {
			margin-top: 15px;
			.section-title { 
				h2 {
					margin-right: -120px;
					margin-left: 0;
					text-align: right;
					padding-top: 25px;
				}
			}
		}
	}


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.kdl-accseorii {
		margin-top: 100px;
		margin-bottom: 100px;
		.section-title {
			margin-bottom: 35px;
			margin-top: 30px;
			h2 {
				font-size: 28px;
			} 
		}
		.section-list {
	  		padding-left: 30px; 
			ul {
				margin: 0;
				li {
					a {
						font-size: 16px;
					}
				}
			}
			.all-prod-link {
				margin-top: 30px;
			}
		} 
		.accesorii-fete-wrap {
			.description-wrap {
				margin-top: 0;
				.section-title { 
					h2 {
						margin: 0;
						margin-left: -120px;
						
					}
				}
			}
		} 
		.accesorii-baieti-wrap {
			margin-top: 15px;
			.section-title { 
				h2 {
					margin-right: -120px;
					margin-left: 0;
					text-align: right;
					padding-top: 25px;
				}
			}
		}
	}

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}





