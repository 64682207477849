//Kidlet benefits

section.kidlet-benefits {
	.kidlet-benefits-desc {
		h2 {
			font-size: 28px;
		    font-weight: 800;
		    margin-bottom: 20px;
		}
		p {
			font-weight: 600;
		}
	}
	.icon-item {
		margin-bottom: 25px; 
		margin-top: 15px;  
		.desc {
			padding-left: 15px;
			
		}
		.icon-desc-simple {
			h4 {
				margin-top: 20px;
			}
			p {
				display: none;
			}

		}
		h4 {
			font-size: 18px;
		    font-weight: 700;
		    
		}
		p {
			font-weight: 600;
			font-size: 14px;
			margin-top: 20px;
			display: none;
		}
		.icon-holder {
			&.text-center {
				img {
					max-width: 50%;
					height: auto;
				}
			}
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.kidlet-benefits {
		margin-bottom: 120px;
		.kidlet-benefits-desc {
			h2 {
				margin-bottom: 40px;
			}
		}
	}	

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}
