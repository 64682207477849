.product-list {
	.product-item-wrap {
		margin: 10px 0 30px 0;
		position: relative;
		.new-badge,
		.promo-badge {
			display: none;
		}
		span.image-overlay-multi,
		span.image-overlay-soft {
			position: absolute;
		    top: -8px;
		    left: -8px;
		    bottom: 0;
		    right: -8px;
		    width: auto;
		    height: auto;
		    background: transparent;
		    opacity: 0;
		    transition: all .1s ease-in;
		    border-top-left-radius: 3px;
		    border-top-right-radius: 3px; 
		}
		span.gender {
			position: absolute;
			top: 10px;
	  		left: 5px;
			font-size: 11px;
			color: #fff;
			padding: 1px 4px;
			border: 1px solid #fff;
			font-weight: 800;
			text-transform: uppercase;
			letter-spacing: 1px;
			display: none;
			&.fete {
				text-shadow: 0px 1px 0px rgba(133, 46, 48, 0.15);
			}
		}
		span.seeicon {
			position: absolute;
			width: 48px;
			height: 48px;
			background-color: #fff;
			top: 50%;
			margin-top: -24px;
			box-shadow: 0px 40px 63px 7px rgba(0, 0, 0, 0.15); 
			opacity: 0;
			visibility: hidden;
			border-radius: 50%;
			left: 50%;
			margin-left: -24px;
			i {
				font-size: 26px;
			    position: absolute;
			    top: 11px;
			    left: 11px;
			}
		}
		&.new {
	        .new-badge {
	            display: block;
	            position: absolute;
	            top: 0;
	            left: 0;
	            background-image: url('../images/new-badge@2x.svg');
	            background-position: center;
	            background-size: cover;
	            width: 42px;
	            height: 41px;
	            font-weight: 800;
	            color: #fff;
	            text-transform: uppercase;
	            font-size: 12px;
	            line-height: 45px;
	            transition: all .2s ease-in;
	            span {  
	                display: block;
	                position: relative;
	                text-align: center;
	                line-height: 1; 
	                &:first-child {
	                    color: #5ca070;
	                    font-size: 12px;
	                    font-weight: 600; 
	                    text-transform: initial;
	                    margin-top: 8px;
	                    margin-right: 5px;
	                }
	            }
	        }
	    }
	    &.promo {
	        .promo-badge {
	            display: block;
	            position: absolute;
	            top: 0;
	            right: 0;
	            background-image: url('../images/promo-badge@2x.svg');
	            background-position: center;
	            background-size: cover;
	            width: 42px;
	            height: 41px;
	            font-weight: 800;
	            color: #fff;
	            text-transform: uppercase;
	            font-size: 12px;
	            line-height: 45px;
	            text-align: center;
	            transition: all .2s ease-in;
	        }
	    }
	    &.disabled {
	    	a.kdl-add-cart {
	    		border-color: #c5c5c5;
	    		pointer-events: none;
	    		cursor: not-allowed;
	    		span.progress-bar {
	    			background-color: #f1f1f1;
	    		}
	    		span.btn-text {
	    			color: #989898;
	    		}
	    	}
	    	span.img-holder img {
				filter: grayscale(1);
	    	}
	    	span.image-overlay-multi { 
				background: #e8e8e8;
				opacity: 0.4;
	    	}
	    }
	    &:before {
	    	content: '';
    	    position: absolute;
		    top: -8px;
		    left: -8px;
		    bottom: -10px;
		    right: -8px;
		    background: transparent;
		    opacity: 1;
		    transition: all .1s ease-in;
		    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
		    border-radius: 3px;
		    z-index: 5;
	    }
	    &:hover {
	    	&:before {
	    		opacity: 1;
	    	}
	    	span.gender { 
				display: block;
	    	}
	    	&.new {
	    		span.gender { 
					top: 55px;
	    		}
	    		.new-badge {
		    		background-image: url('../images/new-badge-black@2x.svg');
		    		color: #fff;
		    		transition: all .2s ease-in;	    		
		    		span {
		    			color: #fff;
		    		}
	    		}
	    	}
	    	&.promo .promo-badge {
				background-image: url('../images/promo-badge-black@2x.svg');
	    		color: #fff;
	    		transition: all .2s ease-in;
	    	}
	    	&.fete {
				span.image-overlay-multi {
				    background: #f7c5c6;
	    			mix-blend-mode: multiply;
	    			opacity: .9;
	    			transition: all .1s ease-in; 
				}
				span.image-overlay-soft {
				    background: #f7c5c6;
	    			mix-blend-mode: soft-light;
	    			opacity: 0;
	    			transition: all .1s ease-in; 
				}
			}
			&.baieti {
				span.image-overlay-multi {
				    background: #c8def0;
	    			mix-blend-mode: multiply;
	    			opacity: .9;
	    			transition: all .1s ease-in; 
				}
				span.image-overlay-soft {
				    background: #c8def0;
	    			mix-blend-mode: soft-light;
	    			opacity: 0;
	    			transition: all .1s ease-in; 
				}
			}
			&.disabled {
	    		span.image-overlay-multi {
					background: #e8e8e8;
					opacity: 0.4;
	    		}
	    		span.image-overlay-soft {
	    			display: none;
	    		}
	    	}
			span.seeicon {
				transition: transform .3s cubic-bezier(0, 0, 0.2, 1);
				transform: translateY(10px); 
				opacity: 1;
				visibility: visible;
			}
	    }
	    span.product-item,
		span.img-holder,
		span.bottom-content,
		span.product-name,
		span.product-price,
		span.marime-wrap,
		span.stare-wrap,
		span.image-overlay,
		span.background-shadow {
			display: block;
		}
		span.img-holder {
			text-align: center;
			position: relative;
			
		}
		span.bottom-content {
			padding-top: 15px;
			padding-bottom: 10px;
			background-color: #fff;
			transition: all 5ms ease;
			position: relative;
		}
		span.product-name {
			.brand {
				font-weight: 700;
				text-transform: uppercase;
			}
		}
		span.product-price {
			margin-top: 10px;
			.price {
				font-weight: 700;
				margin-right: 8px;
				display: inline-block;
			}
			.promo-price {
				font-weight: 800;
				color: #cbcbcb;
				text-decoration: line-through;
				display: inline-block;
			}
		}
		span.marime-wrap {
			margin-top: 10px;
			.marime {
				font-weight: 700;
				margin-right: 8px;
				display: inline-block;
			}
			.marime-list {
				font-weight: 800;
				color: #cbcbcb;
				display: inline-block;
			}
		}
		span.stare-wrap {
			margin-top: 10px;
			.stare {
				font-weight: 700;
				margin-right: 8px;
				display: inline-block;
			}
			.stare-list {
				font-weight: 800;
				color: #cbcbcb;
				display: inline-block;
			}
		}
		
		
		a.prod-link-new {
			display: block;
			font-size: 12px;
			color: #000;
			font-weight: 600;
			position: relative;
			transition: all .1s ease-in;
			z-index: 10;
			span.kdl-fav {
				font-size: 18px;
				position: absolute;
				bottom: 5px;
				right: 0;
				background-color: #fff;
				border-radius: 50%;
				width: 28px;
				height: 28px;
				background-color: #fff;
				z-index: 200;
				text-align: center;
				display: block;
				cursor: pointer;
				i {
					position: relative;
					top: 4px;
					color: #9e9e9e;
				}
				&.fav-active {
					background-color: #e43d4c;
					i {
						color: #fff;
					}
					&:hover {
						i {
							color: #fff;
						}
					}
				}
				.popover-fav {
					position: absolute;
				    background-color: #000;
				    border-radius: 3px;
				    color: #fff;
				    font-size: 11px;
				    bottom: 1px;
				    width: 160px;
				    right: 130%;
				    padding: 5px 3px;
				    opacity: 0;
				    visibility: hidden;
				    &:after {
				    	content: '';
				    	position: absolute;
				    	right: -6px;
				    	top: 9px;
				    	width: 0;
						height: 0;
						border-style: solid;
						border-width: 4px 0 4px 6px;
						border-color: transparent transparent transparent #000;
				    }
				}
				&:hover {
					//background-color: #e43d4c;
					i {
						color: #e43d4c;
					}
					.popover-fav {
						visibility: visible;
						opacity: 1;
					}
				}

			}
		}
		a.kdl-add-cart {
			position: relative;
			z-index: 15;
			display: block;
			padding: 0;
			border-radius: 3px;
			border: 1px solid #000;
			background-color: #fff;
			span.progress {
				position: relative;
				border-radius: 3px;
				background-color: #fff;
			}
			span.progress-bar {
				height: 100%;
				background-color: #fff;
				border-radius: 3px;
			}
			span.btn-text {
				position: absolute;
				left: 0;
				right: 0;
				margin: auto;
				top: 7px;
				bottom: 0;
				font-size: 12px;
				font-weight: 700;
				text-transform: uppercase;
				color: #000;
			}
			
			&.reserved-you {
				border-color: #5ca070;
				color: #49905e;
				span.progress-bar {
					background-color: #a0d4a3;
				}
				span.btn-text {
					color: #49905e;
				}
			}
			&.reserved-other {
				border-color: #e5453a;
				color: #e02f23;
				span.progress-bar {
					background-color: #f99a94;
				}
				span.btn-text {
					color: #e02f23;
				}
			}
			&.disabled {
				opacity: 1;
			}
		}
		a.kdl-add-cart {
			.kdl-tip {
				position: absolute;
				opacity: 0; 
				font-size: 12px;
				background-color: #000;
				color: #fff;
				border-radius: 3px;
				padding: 8px;
				left: -5px;
				right: -5px;
				margin: 0 auto;
				text-align: center;
				bottom: 103%;
				display: block;
				white-space: normal;
				text-transform: none;
				font-weight: 600;
				transition: all .3s ease;
				visibility: hidden;
				z-index: 99;
				&:before {
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 6px 5px 0 5px;
					border-color: #000 transparent transparent transparent;
					position: absolute;
					top: auto;
					bottom: -6px;
					left: 0;
					right: 0;
					margin: 0 auto;
				}
			}
			&:focus,
			&:hover {
				.kdl-tip {
					opacity: 1;
					transition: all .3s ease;
					bottom: 130%;
					visibility: visible;
				}
			}
			&.disabled {
				pointer-events: auto;
			}
		}
	}
}

.popover.bs-tether-element-attached-bottom {
	&:before {
		border-top-color: transparent;
	}
}

.popover {
	.popover-content {
		font-size: 11px;
		padding: 8px;
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	.product-list {
		.product-item-wrap { 
			&:before {
				opacity: 0;
			}
		}
	}


}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}
