section.cat-slider {
	.cat-slider-wrap {
		padding-top: 30px;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			width: 83%;
			top: 0;
			bottom: 60px;
			left: -15px;
			background-color: #f1f7fd;
			border-radius: 6px;
		}
		span.link-all {
			position: relative;
		    left: 0;
		    display: block;
		    top: 0;
		    margin-bottom: 15px;
			text-transform: uppercase;
			font-size: 12px;
			font-weight: bold;
			letter-spacing: 1px;
			a {
				&:hover {
					span {
						transition: all .3s ease;
						margin-left: 15px;
					}
				}
			}
			span {
				color: red;
				margin-left: 10px;
				-webkit-text-stroke: 1px red;
				position: relative;
				top: 1px;
				transition: all .3s ease;
			}
		}
	}
	.cat-slider-nav,
	.cat-slider-nav-boy {
		position: absolute;
	    top: -14%;
	    /* margin-top: -25px; */
	    right: 15px;
	    left: auto;
	    z-index: 10;
    	.left,
    	.right {
    		i {
	    		position: absolute;
	    		right: 40px;
	    		line-height: 30px;
	    		width: 30px;
	    		height: 30px;
	    		font-size: 16px;
	    		background-color: #000;
	    		text-align: center;
	    		color: #fff;
	    		border-radius: 50%;
	    		cursor: pointer;
    		}
    	}
    	.right { 
    		i {
    			right: 0;
    			left: auto;
    		}
    	}
	}
	.cat-slider-main-girl,
	.cat-slider-main-boy {
		padding: 0;
	}
	h2 {
		width: 75%;
		padding-left: 0;
	}
	.owl-stage { 
		left: -30px;
	}
	.slider-item {
		border-radius: 6px; 
		padding: 30px 0;
		a {
			display: block;
			width: 100%;
			box-shadow: 0px 6px 15px 0px rgba(89, 89, 89, 0.1);
			height: 275px;
			position: relative;
			padding: 20px;
			overflow: hidden;
			border-radius: 6px;
			background-color: #fff;
			transition: all 0.3s ease;
			span {
				display: block;
			}

			img {
				position: absolute;
				width: auto;
				transition: all 0.3s ease;
				display: block;
			}
			span.detail {
				position: absolute;
				bottom: 30px;
				left: 30px;
			}
			span.cat-name {
				font-weight: 700;
				font-size: 20px;
				margin-bottom: 5px;
			}
			span.price {
				color: #999;
				font-weight: 600;
				font-size: 16px;
				margin-bottom: 5px;
				span {
					display: inline;
					color: #000;
				}
				sup {
					margin-left: 5px;
				}
			}
			span.link {
				font-size: 12px;
				font-weight: 900;
				color: #da6e09;
				margin-bottom: 5px;
				text-transform: uppercase;
				transition: all 0.3s ease;
			}
			&:before {
				content: '';
				background-color: #ffc290;
				width: 265px;
				height: 265px;
				position: absolute;
				display: block;
				border-radius: 50%;
				transition: all 0.3s ease;
				transform: scale(1);
			}
			&:hover {
				box-shadow: 0px 6px 20px 0px rgba(89, 89, 89, 0.15); 
				transition: all 0.3s ease;
				&:before {
					transition: all 0.3s ease;
					transform: scale(1.1);
				}
				span.link {
					color: #4f9ae2;
					transition: all 0.3s ease;
				}
				img {
					transition: all 0.3s ease;
					transform: scale(.9);
				}

			}
		}
		&.top-right {
			a {
				&:before {
					right: -35%;
					top: -43%;
				}
				img {
					top: 30px;
					right: 30px;
				}
				&:hover {
					border-top-right-radius: 30px;
				}
				span.detail {
					bottom: 20px;
				}
			}
		}
		&.top-left {
			a {
				&:before {
					left: -50%;
    				top: -60%;
				    width: 300px;
				    height: 300px;
				}
				img {
					top: 30px;
					left: 30px;
				}
				&:hover {
					border-top-left-radius: 30px;
				}
				span.detail {
					bottom: 20px;
					top: auto;
				}
			}
		}
		&.bottom-right {
			a {
				&:before {
					bottom: -45%;
				    right: -70%;
				    width: 306px;
				    height: 260px;
				}
				img {
					bottom: 30px;
					right: 30px;
				}
				&:hover {
					border-bottom-right-radius: 30px;
				}
				span.detail {
					bottom: auto;
					left: 30px;
					top: 20px;
				}
			}
		}
		&.bottom-left {
			a {
				&:before {
					bottom: -46%;
				    left: -55%;
				    width: 265px;
				    height: 265px;
				}
				img {
					bottom: 30px;
					left: 30px;
				}
				span.detail {
					left: auto;
					right: 30px;
					top: 20px;

				}
				&:hover {
					border-bottom-left-radius: 30px;
				}
			}
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  
	
	section.cat-slider {
		.slider-item {
			a {
				padding: 30px;	
				span.cat-name {
					font-size: 22px;
					margin-bottom: 5px;
				}
				span.price {
					font-size: 18px;
					margin-bottom: 5px;
				}
			}
			&.top-left {
				a {
					&:before {
						left: -150%;
					    top: -45%;
					    width: 550px;
					    height: 300px;
					}
					span.detail {
						bottom: 30px;
						top: auto;
					}
				}
			}
			&.top-right {
				a {
					&:before {
						right: -35%;
						top: -35%;
					}
					span.detail {
						bottom: 30px;
					}
				}
			}
			&.bottom-right {
				a {
					&:before {
						bottom: -45%;
					    right: -70%;
					    width: 306px;
					    height: 306px;
					}
					span.detail {
						bottom: auto;
    					left: 30px;
    					top: 30px;
					}
				}
			}
			&.bottom-left {
				a {
					&:before {
						bottom: -31%;
					    left: -55%;
					    width: 325px;
					    height: 265px;
					}
					span.detail {
						left: auto;
						right: 30px;
						top: 30px;
						bottom: auto;
					}
				}
			}
		}
		.owl-stage { 
			left: auto; 
		}
		.cat-slider-wrap {
			span.link-all {
			    position: absolute;
			    top: 20px;
			    right: 14px;
			    left: auto;
			}
		}
		h2 {
			width: 75%;
			padding-left: 15px;
		}
		.cat-slider-nav,
		.cat-slider-nav-boy {
			top: 50%;
			margin-top: -25px; 
			right: -30px;
	    	left: -30px;
	    	z-index: 10;
	    	.left {
	    		i {
	    			left: 0;
	    			right: auto;
	    		}
	    	}
	    	.right { 
	    		i {
	    			right: 0;
	    			left: auto;
	    		}
	    	}
		}
	}


}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}















