//Disney Landing
section.kidlet-disney {
	margin-top: 30px;
	margin-bottom: 60px;
	p {
		font-family: 'Nunito', sans-serif;
		color: #000;
	}
	.logo-wrap {
		display: inline-block;
		margin-bottom: 0;
	}
	.kdl-logo {
		padding-top: 0;
	}

	.main-img {
		padding-right: 0;
		position: relative;
		span {
			position: absolute;
			display: none;
			-webkit-transition: .8s ease-in-out;
			transition: .8s ease-in-out;
			&.mem-icon-1 {
				left: -77px;
				top: 40%;
			}
			&.mem-icon-2 {
				right: 15px;
				top: 85%;
			}
		}
	}
	&.kidlet-fb {
		.main-img {
			img {
				-webkit-transform: scale(1);
				transform: scale(1);
				-webkit-transition: .8s ease-in-out;
				transition: .8s ease-in-out;
			}
		}
		.main-img:hover {
			img {
				-webkit-transform: scale(1.02);
				transform: scale(1.02);
				-webkit-transition: .8s ease-in-out;
				transition: .8s ease-in-out;
			}
			span {
				-webkit-transition: .8s ease-in-out;
				transition: .8s ease-in-out;
				&.mem-icon-1 {
					left: -77px;
					top: 45%;

				}
				&.mem-icon-2 {
					right: 15px;
					top: 80%;
				}
			}
		}
	} 
	.title-wrap {
		margin-left: 0;
		margin-bottom: 40px;
		h1 {
			margin-left: 0;
			margin-right: 0;
			background-color: #fff;
			//padding: 30px 0 30px 30px;
			margin-top: 30px;
			font-size: 30px;
			span {
				white-space: pre;
				display: inline-block;
				position: relative;
				z-index: 10;
				margin-left: -15px;
				&:before {
					content: '';
					background-image: url(../images/title-bg.png);
					position: absolute;
					background-size: cover;
					height: 40px;
					width: 100%;
					left: 0;
					z-index: -1;
				}
			}
		}
	}

	.desc-wrap {
		p {
			margin-bottom: 20px;
			font-weight: 300;
			font-size: 14px;
		}
		p:nth-child(2) {
			margin-left: 0;
		}
		p:last-child {
			padding-left: 30px;
			position: relative;
			margin-bottom: 5px;
			&:before {
				content: '';
				position: absolute;
				width: 5px;
				top: 0;
				bottom:0;
				left: 0;
				background-color: #f6dd86;
			}
		}
		#popover-trans {
			cursor: pointer;
			i {
				margin: 0 5px;
				color: #8bd592;
			}
		}
	}
	.form-section {
		margin-top: 0;
		box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
		padding-top: 70px;
		padding-bottom: 30px;
		padding-left: 30px;
		padding-right: 30px;
		margin-bottom: 50px;
		input {
			margin-bottom: 15px;
	    	box-shadow: none;
	    	border-radius: 0;
	    	font-size: 16px;
		    background-color: transparent;
		    border: 2px solid #000;
		    color: #000;
		    height: 50px;
		    width: 100%;
		    -webkit-transition: all 0.2s ease;
			-moz-transition: all 0.2s ease;
			-ms-transition: all 0.2s ease;
			-o-transition: all 0.2s ease;
			transition: all 0.2s ease;
		    &:focus {
		    	border-color: #f6dd86;
		    	transform: translateY(-3px);
		    	-webkit-transition: all 0.2s ease;
				-moz-transition: all 0.2s ease;
				-ms-transition: all 0.2s ease;
				-o-transition: all 0.2s ease;
				transition: all 0.2s ease;
		    }
	    }
	    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: #888888;
		  font-size: 14px;
		}
		::-moz-placeholder { /* Firefox 19+ */
		  color: #888888;
		  font-size: 14px;
		}
		:-ms-input-placeholder { /* IE 10+ */
		  color: #888888;
		  font-size: 14px;
		}
		:-moz-placeholder { /* Firefox 18- */
		  color: #888888;
		  font-size: 14px;
		}
		.kdl-button {
			width: 100%;
		    text-transform: uppercase;
		    font-size: 21px;
		    text-align: center;
		    line-height: 34px;
		}
	}
	.category-section {
		margin-bottom: 30px;
		h2 {
			margin-bottom: 30px;
			margin-top: 0;
			font-size: 25px;
		}
		.block-button {
			font-weight: 800;
		    font-size: 22px;
		    color: #000;
		    padding: 15px 10px;
		    text-align: center;
		    background-color: #b8e6be;
		    text-transform: uppercase;
		    color: #000000;
			&:hover {
				background-color: #a1d4a8;
			}
		}
		a.cat-link {
			position: relative;
			display: block;
			overflow: hidden;
			margin-bottom: 30px;
			img.img-fluid {
				-webkit-transform: scale(1);
				transform: scale(1);
				-webkit-transition: .3s ease-in-out;
				transition: .3s ease-in-out;
				-webkit-filter: grayscale(100%); 
    			filter: grayscale(100%);
			}
			span.logo {
				img {
					max-width: 100px;
				    height: auto;
				    position: absolute;
				    top: 60px;
				    right: 30px;
				    -webkit-transition: all 0.2s ease;
					-moz-transition: all 0.2s ease;
					-ms-transition: all 0.2s ease;
					-o-transition: all 0.2s ease;
					transition: all 0.2s ease;
				}
			}
			&:hover {
				img.img-fluid {
					-webkit-transform: scale(1.1);
					transform: scale(1.1);
					-webkit-filter: grayscale(0%); 
    				filter: grayscale(0%);
				}
				span.logo {
					img {
					    position: absolute;
					    top: 40px;
					    -webkit-transition: all 0.2s ease;
						-moz-transition: all 0.2s ease;
						-ms-transition: all 0.2s ease;
						-o-transition: all 0.2s ease;
						transition: all 0.2s ease;
					}
				}
			}
		}
	}
	.shop-benefits {
		h2 {
			margin-bottom:30px;
			font-size: 25px;
		}
		h4 {
			font-family: 'Nunito', sans-serif;
			font-size: 20px;
			font-weight: 600;
			text-align: center;
			color: #000;

		}
		.text-center {
			margin-bottom: 30px;
		}
		.icon-holder {
			margin-bottom: 15px;
		}
	}
	.button {
		float: none;
		margin-bottom: 30px;
		a {
			font-size: 20px;
			color: #000;
			text-decoration: none;
			margin-top: 25px;
			display: inline-block;
			i {
				padding: 8px;
			    border-radius: 100%;
			    background-color: #b4e6e3;
			    display: inline-block;
			    width: 41px;
			    text-align: center;
			    font-size: 23px;
			    margin-left: 15px;
			    -webkit-transition: all 0.2s ease;
				-moz-transition: all 0.2s ease;
				-ms-transition: all 0.2s ease;
				-o-transition: all 0.2s ease;
				transition: all 0.2s ease;

			}
			&:hover {
				i {
					transform: translateX(5px);
					-webkit-transition: all 0.2s ease;
					-moz-transition: all 0.2s ease;
					-ms-transition: all 0.2s ease;
					-o-transition: all 0.2s ease;
					transition: all 0.2s ease;
				}
			}
		}
	}
}

section.disney-success {
		.success-message {
		color: #4d0925;
		padding: 60px 30px 100px;
		background-color: #fff;
		.icon-holder {
			display: block;
			text-align: center;
			margin-bottom: 30px;
		}
		i {
			color: #9de7a6;
			border-radius: 50%;
			border: 4px solid #b8e6be;
			padding: 20px 22px;
			font-size: 50px;
		}
		h3 {
			color: #000;
			text-align: center;
			margin-bottom: 20px;
			font-weight: 900;
			font-size: 40px;
		}
		h4 {
			text-align: center;
			color: #000;
			margin-bottom: 30px;
			font-size: 16px;
			font-family: 'Nunito', sans-serif;
		}
		span.code-field {
			border: 3px solid #ff7b75;
			color: #ff7b75;
			font-size: 34px;
			font-weight: 900;
			display: block;
			text-align: center;
			margin-bottom: 20px;
			padding: 10px 0;

		}
		.btn {
			background: #fff;
		    font-weight: 800;
		    color: #1f030e;
		    padding: 5px 20px;
		    border-radius: 0;
		    border: 3px solid #4d0925;
		    -webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-ms-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;
			transition: all 0.3s ease;
		    &:hover,
		    &:active,
		    &:focus {
		    	border-color: #ff7b75;
		    	outline: none;
		    	color: #ff7b75;
		    	-webkit-transition: all 0.3s ease;
				-moz-transition: all 0.3s ease;
				-ms-transition: all 0.3s ease;
				-o-transition: all 0.3s ease;
				transition: all 0.3s ease;
		    }
		}
	}
}

.category-section-fb {
	margin-bottom: 0;
	.container {
		background-size: initial;
		min-height: 200px;
		background-position: center;
		background-repeat: no-repeat;
		padding-top: 60px;
		padding-bottom: 60px;
	}
	h3 {
		font-family: 'Nunito', sans-serif;
		font-size: 32px;
		font-weight: 900;
		color: #000;
		margin-bottom: 40px;
		margin-top: 25px;
		padding: 15px;
		box-shadow: 0px 8px 18px 2px rgba(0, 0, 0, 0.15);
		background-color: #fff;
		display: inline-block;
	}
	.circle {
		display: block;
		width: 100px;
		height: 110px;
		background-color: #fff;
		position: relative;
		border-radius: 50%;
	}
	.left-cnt,
	.right-cnt {
		position: relative;
		padding-bottom: 30px;
	}
	.right-cnt {
		margin-top: 0;
	}
	.inner-cnt {
		position: relative;
		z-index: 10;
		padding: 15px;
		a {
			font-size: 20px;
			font-weight: normal;
			font-style: italic;
			box-shadow: 0px 8px 18px 2px rgba(0, 0, 0, 0.15);
			background-color: #fff;
			color: #000;
			padding: 10px 15px;
			margin-bottom: 40px;
			display: inline-block;
			i {
				position: relative;
				margin-left: 10px;
				top: 3px;
    			font-size: 28px;
			}
		}
	}
	.bg {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
	.left-cnt {
		.bg-multi {
			background-color: #f7ea9a;
			mix-blend-mode: multiply;
		}
		.bg-light {
			background-color: #94917c;
			mix-blend-mode: hard-light;
		}
		&:before {
			content: '';
		    position: absolute;
		    top: 30px;
		    bottom: -30px;
		    right: 0;
		    width: 30px;
		    background-color: #000;
		    opacity: .9;
		    display: none;
		}
		a {
			background-color: #fff;
			color: #000;
			margin-left: 30px;
		}
		.circle {
			&:after {
				content: '';
				position: absolute;
				background-image: url(../images/girl-sign.png);
				width: 65px;
				height: 65px;
				top: 0;
    			right: 0;
    			transform: translate(0,0);
    			-webkit-transition: all 0.3s ease;
				-moz-transition: all 0.3s ease;
				-ms-transition: all 0.3s ease;
				-o-transition: all 0.3s ease;
				transition: all 0.3s ease;
			}
		}
		&:hover {
			.circle {
				&:after {
					transform: translate(10px,-10px);
					-webkit-transition: all 0.3s ease;
					-moz-transition: all 0.3s ease;
					-ms-transition: all 0.3s ease;
					-o-transition: all 0.3s ease;
					transition: all 0.3s ease;
				}
			}
		}

	}
	.right-cnt {
		.inner-cnt {
			float: none;
		}
		.bg {
			right: 0;
		}
		.bg-multi {
			background-color: #b8e6be;
			mix-blend-mode: multiply;
		}
		.bg-light {
			background-color: #808681;
			mix-blend-mode: hard-light;
		}
		&:before {
			content: '';
		    position: absolute;
		    top: -30px;
		    bottom: 30px;
		    left: 0;
		    width: 30px;
		    background-color: #000;
		    opacity: .8;
		    display: none;
		}
		a {
			background-color: #000;
			color: #fff;
			font-weight: 300;
		}
		.circle {
			margin-left: 30px;
			&:after {
    			content: '';
				position: absolute;
				background-image: url(../images/boy-sign.png);
				width: 68px;
				height: 68px;
				bottom: 0;
    			right: 0;
    			transform: translate(0,0);
    			-webkit-transition: all 0.3s ease;
				-moz-transition: all 0.3s ease;
				-ms-transition: all 0.3s ease;
				-o-transition: all 0.3s ease;
				transition: all 0.3s ease;
			}
		}
		&:hover {
			.circle {
				&:after {
					transform: translate(10px,-10px);
					-webkit-transition: all 0.3s ease;
					-moz-transition: all 0.3s ease;
					-ms-transition: all 0.3s ease;
					-o-transition: all 0.3s ease;
					transition: all 0.3s ease;
				}
			}
		}
	}
}

// MEDIA QUERIES

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

	section.kidlet-disney { 
		margin-top: 60px;
		.logo-wrap {
			margin-bottom: 50px;
		}
		.main-img {
			span {
				display: block;
			}
		}
		.category-section-fb {
			margin-bottom: 120px;
			h3 {
				display: inline-block;
			}
			.right-cnt {
				margin-top: 60px;
				.inner-cnt {
					float: right;
				}

				&:before {
					display: block;
				}
			}
			.left-cnt {
				&:before {
					display: block;
				}
			}
		}
	}

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

	section.kidlet-disney {
		margin-bottom: 120px;
		.main-img {
			padding-right: 55px;
		}
		.title-wrap {
			margin-left: -40%;
			margin-bottom: 40px;
			h1 {
				padding: 30px 0 30px 30px;
				font-size: 45px;
				span {
					margin-left: -25px;
					&:before {				
						height: 70px;
					}
				}
			}
		}
		&.kidlet-sh {
			.title-wrap {
				margin-left: -50%;
			}
		}
		.desc-wrap {
			p {
				margin-bottom: 40px;
				font-size: 18px;
			}
			p:nth-child(2) {
				margin-left: -30px;
			}
		}
		.form-section {
			margin-top: 20px;
			box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.15);
			margin-bottom: 125px;
			.form-inline {
				width: 100%;
				align-items: start;
			}
		}
		.category-section { 
			margin-bottom: 70px;
			h2 {
				font-size: 40px;
			}
		}
		.shop-benefits {
			h2 {
				font-size: 40px; 
			}
			h4 { 
				font-size: 20px;
			}
		}
		.button {
			float: right;
			margin-bottom: 0;
		}
		.category-section-fb {
			.container {
				padding-top: 180px;
				padding-bottom: 60px;
			}
			h3 {
				padding: 0;
				box-shadow: none;
				background-color: transparent;
				display: block;
			}
		}
	}
	
	
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

	.form-section {
		margin-top: 70px;
		box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.15);
		margin-bottom: 125px;
		input {
			margin-bottom: 0; 
		} 
	}

}

