//Kidlet Single Page


.kidlet-single {
	margin-top: 20px;
	//Related prod slider
	section.new-products {
		margin-top: 0;
		padding-top: 0;
		margin-top: 30px;
		margin-bottom: 0;
		.new-prod-wrap {
			padding-top: 15px;
			h2 { 
				font-size: 20px;
				color: #000;
				font-weight: 800;
				z-index: 50;
				position: relative;
				margin-bottom: 0px;
			}
			&:after {
				display: none;
			}
			&:before {
				display: none;
			}
			.new-products-nav {
				margin-bottom: 0;
				margin-top: 0;
				.owl-prev,
				.owl-next {
					font-size: 12px;
					span {
						width: 26px;
						height: 26px;
						text-align: center;
						background-color: #000;
						border-radius: 50%;
						display: inline-block;
					}
					i {
						color: #fff;
						position: relative;
    					top: 5px;
					}
				}
				.owl-next { 
					margin-left: 12px;
				}
			}
			.new-prod-slider-wrap {
				box-shadow: none;
				padding: 15px 0;
				margin-left: -5px;
				&.owl-carousel {
					width: 101%;
					.owl-stage {
						left: -50px;
					}
				}
				.owl-item {
				    .new-prod-item {
						border-radius: 6px;
					    font-size: 12px;
					    color: #000;
					    font-weight: 600;
					    margin-bottom: 20px;
					    a {
					    	transition: box-shadow .3s ease-out,transform .2s ease-out;
						    transition-delay: .1s;
						    transform: translateY(0);
						    padding-left: 0;
						    padding-right: 0;
						    border-radius: 6px;
						    display: block;
						    position: relative;
						    
						}
				    }
				    &.active {
						.new-prod-item {
							border-radius: 6px;
						    font-size: 12px;
						    color: #000;
						    font-weight: 600;
						    margin-bottom: 20px;
						    a {
						    	transition: box-shadow .3s ease-out,transform .2s ease-out;
							    transition-delay: .1s;
							    transform: translateY(-5px);
							    box-shadow: 0 2px 2px rgba(45,45,45,.02), 
							    0 4px 4px rgba(49,49,49,.02), 
							    0 8px 8px rgba(42,42,42,.02), 
							    0 16px 14px rgba(32,32,32,.02), 
							    0 16px 14px rgba(49,49,49,.02), 
							    0 16px 14px rgba(35,35,35,.02);
						    }
					    }
				    }
				    .new-prod-item  {
				    	.item-img {
					    	padding: 5px 10px;
					    	margin-bottom: 5px;
					    }
					    .name {
					    	font-size: 12px;
					    	margin-bottom: 10px;
					    	.brand {
					    		font-size: 12px;
					    		font-weight: 700;
					    	}
					    }
					    .price {
					    	font-size: 12px;
					    	padding: 0 10px;
					    }
				    }
				}
			}

	
		}
	}
	.kdl-breadcrumb {
		.breadcrumb {
			padding: 0;
			margin-bottom: 0;
			background-color: transparent;
			color: #4792da;
			a.breadcrumb-item {
				font-size: 12px;
				color: #585858;
				margin-right: 15px;
				position: relative;
				color: #4792da;
    			text-decoration: underline;
				&:before {
					position: absolute;
					font-size: 24px;
					font-weight: 100;
					left: -19px;
    				top: -7px;
    				color: #4792da;
				}
				&:last-child {
					font-weight: 600;
				}
			}
		}
	}
	.kdl-title-meta {
		position: relative;
	}
	.kdl-meta {
		position: relative;
		padding-left: 50px;
		z-index: 10;
		background-color: #fff;
		margin-top: 5px;
		span {
			font-size: 10px;
			text-transform: uppercase;
			color: #000;
			font-weight: 800;
			padding: 0 15px;
			background-color: #fff;
			//float: left;
			position: relative;
    		z-index: 10;
    		//line-height: 1;
    		display: inline-block;
    		@include clearfix;

			&:last-child {
				padding-right: 25px;
			}
			&:before {
				content: '';
				width: 8px;
				height: 8px;
				position: absolute;
				left: 0;
				top: 3px;
				background-color: #000;
				border-radius: 50%;
			}
			&.gender.girl {
				&:before {
					background-color: #f5868f;
				}
			}
			&.quality.new {
				&:before {
					background-color: #badfbc;
				}
			}
		}
		&:before {
			content: '';
			width: 60px;
			height: 2px;
			background-color: #000;
			left: -30px;
			position: absolute;
			display: inline-block;
			top: 12px;  
			margin-top: -1px;
		}
		&:after {
			content: '';
			height: 1px;
			background-color: #e8e8e8;
			left: 25%;;
			right: 0;
			position: absolute;
			display: inline-block;
			top: 12px;
			z-index: 1
		}
	}
	.kdl-title {
		margin-top: 20px;
		h1 {
			font-weight: 800;
			font-size: 20px;
			color: #000;
		}
	}
	.kdl-prod-code {
		.prod-code {
			font-size: 14px;
			color: #989898;
		}
	}
	.price-shipping {
		margin-top: 20px;
		.discount-price {
			.disc-price {
				color: #bdbdbd;
				font-size: 18px;
				text-decoration: line-through;
				margin-right: 20px;
			}
			.eco {
				font-size: 14px;
				color: #989898;
				.amount {
					font-weight: bold;
				}
			}
		}
		.price {
			color: #000;
			span {
				font-size: 30px;
				font-weight: 700;
				position: relative;
				&:before {
					content: '';
					width: 100%;
					left: 0;
					right: 0;
					top: auto;
					bottom: 8px;
					height: 12px;
					background-color: #fff8c8;
					position: absolute;
					z-index: -1;
					
				}
			}
			sup {
				position: relative;
				font-size: 20px;
				top: -8px;
			}
		}
	}
	.prod-handle-meta {
		&.mobile {
			margin-top: 15px;
			.estim {
				bottom: 0;
				margin-top: 4px;
			}
		}
		&.desktop {
			margin-bottom: 6px;
			.estim {
				bottom: 0;
				margin-top: 3px;
			}
		}
	}
	.estim {
		font-size: 12px;
		font-weight: 700;
		color: #000;
		display: block;
		margin-top: 15px;
		.estimwrap {
			color: #5ca070;
			background-color: #fff;
			padding: 1px 5px;
			margin-left: 0;
			display: inline-block;
		}
	}
	.add-cart {
		margin-top: 10px;
		.custom-select {
			border: 3px solid #e3e3e3;
			border-radius: 0;
			font-size: 14px;
			font-weight: 800;
			height: 46px;
			border-radius: 3px;
		}
		a.ghid {
			font-size: 12px;
			color: #4792da;
		}
	}
	.kdl-button { 
		padding: 1px 15px 9px 15px;
		background-color: #fadbdc;
		border-radius: 0;
		font-size: 24px;
		font-weight: 900;
		font-family: 'Nunito';
		color: #000; 
		border: 3px solid #000;
		position: relative;
		z-index: 50;
		margin-top: 35px;
		margin-left: 6px;

		&:before {
			content: '';
		    border: 3px solid #000;
		    bottom: 2px;
		    right: 2px;
		    top: -8px;
		    left: -8px;
		    position: absolute;
		    z-index: -1;
		    display: block;
		    background-color: #fff;
		    transform: translate3d(0,0,0);
		    -webkit-transition: all 0.2s ease-out;
			-moz-transition: all 0.2s ease-out;
			-ms-transition: all 0.2s ease-out;
			-o-transition: all 0.2s ease-out;
			transition: all 0.2s ease-out;

		}
		&.kblue {
			border-color: #88c6f2;
			&:before {
				border-color: #88c6f2;
			}
		}
		&.kgreen {
			border-color: #8bd592;
			&:before {
				border-color: #8bd592;
			}
		}
		&.kwhite {
			background-color: #fff;
			&:before {
				background-color: #fff;
			} 
		}
		&:hover,&:focus,&:active {
			outline: none;
			&:before {
				transform: translate3d(-3px,-3px,0);
				-webkit-transition: all 0.2s ease-out;
				-moz-transition: all 0.2s ease-out;
				-ms-transition: all 0.2s ease-out;
				-o-transition: all 0.2s ease-out;
				transition: all 0.2s ease-out;
			}
		} 
	}
	a.kdl-cart-button {
		padding: 0;
	    border-radius: 3px;
	    border: 3px solid #000;
	    background-color: #ffff;
	    text-transform: uppercase;
	    font-weight: 800;
	    font-size: 18px;
	    color: #000;
		position: relative;
		outline: none;
		margin-top: 15px;
		&:focus {
			outline: none;
			box-shadow: none;
		}
		&.disabled {
			pointer-events: auto;
		}
		.kdl-tip {
			position: absolute;
			top: auto;
			opacity: 0; 
			font-size: 12px;
			background-color: #000;
			color: #fff;
			border-radius: 3px;
			padding: 8px;
			left: 0;
			right: 0;
			margin: 0 auto;
			text-align: center;
			top: 103%;
			display: block;
			white-space: normal;
			text-transform: none;
			font-weight: 700;
			transition: all .3s ease;
			visibility: hidden;
			z-index: 99;
			&:before {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 5px 6px 5px;
				border-color: transparent transparent #000000 transparent;
				position: absolute;
				top: -6px;
				left: 0;
				right: 0;
				margin: 0 auto;
			}
		}
		&:focus,
		&:hover {
			.kdl-tip {
				opacity: 1;
				transition: all .3s ease;
				top: 130%;
				visibility: visible;
			}
		}
	    .progress {
	    	height: 40px;
	    	background-color: #fff;
	    	.progress-bar {
	    		height: 100%;
	    		background-color: #fff;
	    	}
	    	span.btn-text {
				position: absolute;
				left: 0;
				right: 0;
				text-transform: uppercase;
			    font-weight: 800;
			    font-size: 18px;
			    color: #000;
			    top: 12px;
	    	}
	    }
	    &.reserved-you {
			border-color: #5ca070;
			color: #49905e;
			span.progress-bar {
				background-color: #a0d4a3;
			}
			span.btn-text {
				color: #49905e;
			}
		}
		&.reserved-other {
			border-color: #e5453a;
			color: #e02f23;
			span.progress-bar {
				background-color: #f99a94;
			}
			span.btn-text {
				color: #e02f23;
			}
		}
		&.disabled {
			opacity: 1;
		}
	}
	.measures {
		margin-top: 70px;
		a.ghid {
			font-size: 12px;
    		color: #4792da;
    		margin-top: 5px;
    		display: inline-block;
		}
		.mes-title {
			.mes {
				margin-left: 10px;
				position: relative;
				top: 2px;
				display: inline-block;
			}
		}
		ul.measurelist {
			padding: 0;
			margin: 0;
			margin-top: 15px;
			list-style: none;
			width: 75%;
			li {
				font-size: 14px;
				color: #989898;
				margin-bottom: 2px;
				position: relative;
				.listtitle {
					display: inline-block;
					background-color: #fff;
					position: relative;
					z-index: 10;
					padding-right: 5px;
				}
				.listvalue {
					color: #050808;
					float: right;
					display: inline-block;
					background-color: #fff;
					position: relative;
					z-index: 10;
					padding-left: 5px;
				}
				&:before {
					content: '';
					width: 100%;
					border-bottom: 1px dotted #989898;
					position: absolute;
					bottom: 5px;
					z-index: 5;
				}
			}
		}
	}
	.img-holder {
		margin-top: 60px;
	}

	.benefits-wrap {
		margin-top: 40px;
		margin-bottom: 20px;
		box-shadow: 0 8px 27px 0 rgba(89,89,89,0.05);
		border-radius: 3px;
		.benefit-item {
			padding-bottom: 12px;
			padding-top: 12px;
			border-bottom: 1px solid #f2f2f2;
			&:last-child {
				border-bottom: 0;
			}
			&:first-child {
				padding-top: 0;
			}
		}
		img {
			filter: grayscale(100%);
			width: 55%;
		}
		span.icon-image {
			width: 20%;
			text-align: center;
		}
		span.desc {
			width: 80%;
			margin-left: 8px;
			color: #000;
			font-size: 14px;
			font-weight: 600;
			span.small-text {
				text-transform: uppercase;
			    font-size: 11px;
			    font-weight: 600;
			    color: #c1c1c1;
			    margin-top: 5px;
			}
		}
	}
	.measures {
		&.new-m {
			margin-top: 50px;
			a.ghid {
				font-size: 12px;
			    color: #36754a;
			    margin-top: 7px;
			    padding: 3px 15px;
			    display: block;
			    background-color: #d5f3d6;
			    border-radius: 3px;
			    i {
			    	display: inline-block;
			    	margin-right: 7px;
			    	position: relative;
			    	top: 1px;
			    }
			}
			.mes-title {
				display: block;
				background-color: #fbfbfb;
				padding: 10px 5px;
				border-top-left-radius: 3px;
				border-top-right-radius: 3px;
				text-align: center;	

			}
			.mes {
				margin-left: 5px;
				font-size: 14px;
				font-weight: 600;
				top: 6px;
			}
			.measures-bottom {
				padding: 18px;
				border: 1px solid #f7f7f7;
				margin-top: 0;
				border-top: 0;
				border-bottom-left-radius: 3px;
				border-bottom-right-radius: 3px;
				margin-bottom: 8px;
				.mes-sub-title {
					margin-bottom: 4px;
					font-weight: 600;
					font-size: 14px;
    				text-decoration: underline;
				}
			}
			ul.measurelist {
				width: 100%;
				margin-top: 0;
				margin-bottom: 10px;
				li {
					&:before {
						background-image: linear-gradient(to right, #989898 33%, rgba(255,255,255,0) 0%);
						background-position: bottom;
						background-size: 3px 1px;
						background-repeat: repeat-x;
						height: 1px;
						border: none;
					}
				}
			}
		}
	}
}

.user-section-wrap {
	&.tab-section-single {
		box-shadow: none;
		border-left: 1px solid #f3f8fd;
		border-right: 1px solid #f3f8fd;
		border-bottom: 1px solid #f3f8fd;
		.nav.nav-tabs {
			background-color: #f1f7fd;
			li.nav-item {
				a.nav-link {
					padding-top: 20px;
					padding-bottom: 20px;
					color: #0f3b65;
					transition: all .3s ease;
					&.active,
					&:hover,
					&:focus {
						padding-top: 18px;
						padding-bottom: 20px;
						color: #0f3b65;
						transition: all .3s ease;
						&:after {
							background-color: #0f3b65;
							transition: all .3s ease;
						}
					}
					&:after {
						transition: all .3s ease;
					}
				}
			}
		}
		.count-label {
			font-size: 14px;
		    font-weight: 700;
		    background-color: #fff5b6;
		    border-radius: 50%;
		    padding: 5px;
		    width: 30px;
		    height: 30px;
		    color: #000;
		    position: relative;
		    top: -10px;
		    padding: 5px 7px;
		}
		.tab-content {
			margin-bottom: 0;
			color: #0f3b65;
			font-size: 14px;
			h2 {
				color: #0f3b65;

			}
			h3 {
				font-weight: 800;
    			font-size: 18px;
			}
			ul {
				li {
					margin-bottom: 10px;
				}
			}
			.card {
				&:last-child {
					.card-header {
						border: none;
					}
				}
			}
		}
		.collapse { 
			box-shadow: 0px -3px 20px 0px rgba(213, 227, 241, 0.43);
			margin: 0;
			padding: 0;
		}
 
		.card {
			.card-header {
				background-color: #f1f7fd;
				border-color: #dae5f1;
				color: #0f3b65;
				a {
					color: #0f3b65;
					font-size: 18px;
					&:after {
						font-size: 14px;
					}
				}
			}
			.card-body {
				padding: 20px;
			}
		}
	}
} 

#kidlet-search-inline {
	padding: 40px 12px;
	.alert.alert-success {
		padding: 5px 10px;
		margin-bottom: 10px;
		font-size: 14px;
	}
	h4 {
		font-size: 20px;
   		font-weight: 800;
   		margin-top: 10px;
	}
	.product-container {
		img {
			display: block;
			margin: 0 auto;
		}
	}
	.cart-details {

		p.cart-product-title {
			font-weight: 800;
			font-size: 18px;
			margin-bottom: 10px;
		}
		p.cart-product-data {
			margin-bottom: 5px;
			font-size: 14px;
		}
	}
	hr {
		margin-top: 10px;
	    margin-bottom: 10px;
	}
	.add-cart-buttons {
		a.btn-success {
			background-color: #8bd592;
		    border-color: #8bd592;
		    font-weight: 800;
		    margin-bottom: 15px;
		    width: 100%;
		    i {
		    	position: relative;
		    	top: 2px;
		    }
		}
		a.btn-primary {
			background-color: #8bc5eb;
		    border-color: #8bc5eb;
		    font-weight: 800;
		    margin-bottom: 15px;
		    width: 100%;
			i {
		    	position: relative;
		    	top: 2px;
		    }

		}
	}
}

#kidlet-masuri-modal {
	padding: 15px;
	background-color: #fff;
	h2 {
		font-size: 18px;
		font-weight: 800;
	}
	p {
		font-size: 12px; 
	}
	.image-holder {
		padding: 30px 0;
		border-top: 1px solid rgba(0,0,0,.2);
		border-bottom: 1px solid rgba(0,0,0,.2);
	}
	.button-holder {
		padding: 15px 0;
		button.btn {
			font-size: 18px;
			font-weight: 800;
			color: #bb4d56;
			background-color: #fff;
			border: 2px solid #bb4d56;
			border-radius: 3px;
			min-width: 210px;
			cursor: pointer;
			&:hover {
				background-color: #bb4d56;
				color: #fff;
			}
		}
	}
}


.lity-active body {
    overflow: hidden;
}
.lity-inline .lity-content > * {
    max-height: none !important;
}
.lity-inline .lity-wrap {
    overflow: auto;
}
.lity-inline .lity-content {
    margin: 20px 0;
}

button.lity-close {
	color: #e61b00;
	right: 15px;
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  

	.kidlet-single {
		section.new-products .new-prod-wrap .new-prod-slider-wrap.owl-carousel .owl-stage {
		    left: 0;
		}
		.estim {
			left: 15px;
			.estimwrap {
				margin-left: 5px;
			}
		}
		.kdl-button { 
			margin-top: 0;
		}
		.kdl-meta { 

		}
	}


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  

	.kidlet-single {
		a.kdl-cart-button {
			margin-top: 0;
		}
	}


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	.kidlet-single {

		section.new-products {
			margin-top: 40px;
			margin-bottom: 50px;
			.new-prod-wrap {
				h2 { 
					font-size: 22px;
				}
				.new-products-nav {
					.owl-prev,
					.owl-next {
						display: inline-block;
					}
				}
				.new-prod-slider-wrap {
					padding: 15px 0;
					margin-left: 0;
					&.owl-carousel {
						width: 100%;
						.owl-stage {
							left: 0px;
						}
					}
					.owl-item {
					    &.active {
							.new-prod-item {
							    a {
								    box-shadow: none;
							    }
						    }
					    }
					    .new-prod-item  {
						    .name {
						    	padding: 0 15px;
						    }
						    .price {
						    	padding: 0 15px;
						    }
					    }
					}
				}

		
			}
		}
	}

	#kidlet-search-inline {
		padding: 50px;
		.alert.alert-success {
			padding: 12px 20px;
			margin-bottom: 15px;
			font-size: 16px;
		}
		h4 {
			font-size: 26px;
	   		font-weight: 800;
	   		margin-top: 0;
		}
		.product-container {
			img {
				display: block;
				margin: 0 auto;
			}
			.cart-details {

				p.cart-product-title {
					font-weight: 800;
					font-size: 20px;
					margin-bottom: 20px;
				}
				p.cart-product-data {
					margin-bottom: 10px;
				}
			}
		}
		.add-cart-buttons {
			a.btn-success {
				background-color: #8bd592;
			    border-color: #8bd592;
			    font-weight: 800;
			    margin-bottom: 30px;
			    width: auto;
			    min-width: 300px;
			    i {
			    	position: relative;
			    	top: 2px;
			    }
			}
			a.btn-primary {
				background-color: #8bc5eb;
			    border-color: #8bc5eb;
			    font-weight: 800;
			    margin-bottom: 30px;
			    width: auto;
			    min-width: 300px;
				i {
			    	position: relative;
			    	top: 2px;
			    }

			}
		}
	}
	.kidlet-single {
		margin-top: 40px;
		.kdl-title {
			h1 {
				font-size: 26px;
			}
		}
		.kdl-meta {
			margin-top: 0;
		}
		.kdl-cart-button {
			margin-top: 0;
		}

		.price-shipping {
			margin-top: 70px;
		}

		.measures.new-m {
		    .mes-title {
		    	margin-top: 40px;
		    }
		}

	}

	.user-section-wrap {
		&.tab-section-single {
			margin-top: 75px;
			.tab-content {

			}
			.collapse {
				box-shadow: none;
			}
			.card {
				.card-body {
					padding: 0;
				}
			}
		}
	}
	button.lity-close {
		color: #ffffff;
	}


	#kidlet-masuri-modal {
		padding: 30px;
		h2 {
			font-size: 26px;
		}
		p {
			font-size: 16px;
		}
		.image-holder {
			padding: 30px 0;
		}
		.button-holder {
			padding: 15px 0;
			button.btn {
				font-size: 24px;
				font-weight: 800;
			}
		}
	}




}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}





