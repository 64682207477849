section.step-line {
	padding-top: 30px;
	.stepliner {
		height: 100%;
		width: 100%;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			width: 18px;
			left: 50%;
			margin-left: -9px;
			top: -60px;
			bottom: -30px;
			background-color: #f5f5f5;
			border-radius: 9px;
			z-index: 6;
		}
		&:after {
			content: '';
			position: absolute;
			width: 1px;
			left: 50%;
			top: -60px;
			bottom: -30px;
			background-color: #fff;
			z-index: 7;
		}
	}
	.item {
		box-shadow: 0px 5px 45px 0px rgba(0, 0, 0, 0.06);
		border-radius: 15px;
		position: relative;
		margin-bottom: 30px;
		&:before {
			content: '';
			background-image: url(../images/step-bg.png);
			width: 64px;
			height: 100px;
			position: absolute;
			z-index: 5;
			
		}
		&:after {
			content: '';
			font-weight: 800;
			font-size: 22px;
			color: #000;
			width: 52px;
			height: 52px;
			background-color: #fff;
			border-radius: 50%;
			line-height: 46px;
			text-align: center;
			position: absolute;
			border: 4px solid #f5f5f5;
			z-index: 10;
			transition: all 0.3s ease-in-out;

		}
		&:hover {
			&:after {
				//background-color: #f6dd86;
				transition: all 0.3s ease-in-out;
				transform: scale(1.25);
				border-color: transparent;
				box-shadow: 0 5px 50px 0 rgba(0,0,0,.06);
			}
		}
		&.odd {
			&:before {
				right: -32%;
				left: auto;
				top: -50px;
			}
			&:after {
				right: -30.45%;
				left: auto;
				top: -26px;
			}
		}
		&.even {
			&:before {
				left: -32%;
				right: auto;
				top: -50px;
			}
			&:after {
				left: -30.65%;
				right: auto;
				top: -26px;
			}
		}
		&.first {
			&:after {
				content: '1';
			}
		}
		&.sec {
			&:after {
				content: '2';
			}
		}
		&.third {
			&:after {
				content: '3';
			}
		}
		&.forth {
			&:after {
				content: '4';
			}
		}
		&.fifth {
			&:after {
				content: '5';
			}
		}
		&.sixth {
			&:after {
				content: '6';
			}
		} 
		&.seventh { 
			&:after {
				content: '7';
			}
		}
		&.eight {
			&:after {
				content: '8';
			}
		}
		.item-top {
			background-size: cover;
			height: 255px;
			background-position: center;
			background-repeat: no-repeat;
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
			position: relative;
			a {
				position: relative;
				display: block;
				width: 100%;
				height: 100%;
				&:hover {
					span.meta .right .lnr {
						background-color: #f6dd86;
						transition: all 0.3s ease-in-out;
						margin-left: 10px;
					}
				}
			}
		}
		span.overlay {
			display: block;
		    position: absolute;
		    bottom: -1px;
		    left: 0;
		    right: 0;
		    background: linear-gradient(0deg,#000,transparent);
		    width: 100%;
		    height: 50%;
		}
		span.meta {
			position: absolute;
			display: block;
			padding: 15px;
			bottom: 0;
			top: auto;
			left: 0;
			right: 0;
			color: #fff;
			.left {
				width: 60%;
				position: relative;
				.nr {
					display: inline-block;
					margin-right: 15px;
					font-weight: 800;
				    font-size: 45px;
				    line-height: 0;
				    position: relative;
    				top: 2px;
				}
				.text {
					display: inline-block;
					font-size: 20px;
					font-weight: 800;
					line-height: 22px;
				}
				&:after {
					content: '';
					right: 15px;
					top: -3px;
					bottom: -3px;
					width: 1px;
					background-color: #fff;
					position: absolute;
				}
			}
			.right {
				width: 40%;
				.text {
					display: inline-block;
					display: inline-block;
					font-size: 14px;
					font-weight: 800;
					line-height: 14px;
					text-transform: uppercase;
					position: relative;
    				top: 2px;
				}
				.lnr {
					display: inline-block;
					width: 40px;
					height: 40px;
					background-color: #fff;
					line-height: 40px;
					text-align: center;
					font-size: 24px;
					margin-left: 20px;
					color: #000;
					border-radius: 50%;
					transition: all 0.3s ease-in-out;
				}
			}
		}
		.item-bottom {
			padding: 15px;
			p {
				margin-bottom: 0;
				font-size: 15px;
			}
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.step-line {
		padding-top: 60px;
		.item {
			margin-bottom: 0;
			&.odd {
				&:before {
					right: -34%;
				}
				&:after {
					right: -32.45%;
				}
			}
			&.even {
				margin-top: 255px;
				&:before {
					left: -34.4%;
				}
				&:after {
					left: -32.65%;
				}
			}
			.item-top {
				height: 260px;
			}
			
			span.meta {
				padding: 15px 30px;
				.left {
					.nr {
					    font-size: 50px;
	    				top: 2px;
					}
					.text {
						font-size: 22px;
						line-height: 22px;
					}
				}
				.right {
					.text {
						font-size: 14px;
						line-height: 14px;
	    				top: 2px;
					}
				}
			}
			.item-bottom {
				padding: 30px;
				p {
					margin-bottom: 0;
				}
			}
		}
	}



}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  
	section.step-line {
		.item {
			&.odd {
				&:before {
					right: -32%;
				}
				&:after {
					right: -30.45%;
				}
			}
			&.even {
				&:before {
					left: -32%;
				}
				&:after {
					left: -30.65%;

				}
			}
		}
	}

}