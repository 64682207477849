section.kidlet-shoes {
	margin-bottom: 50px;
	.kdl-shoes-wrap {
		background-size: contain;
		background-position: right;
		background-repeat: no-repeat;
		background-image: none;
	}
	h2 {
		font-size: 22px;
		color: #000;
		font-weight: 800;
		letter-spacing: 5;
		span {
			position: relative;
			display: inline-block;
			&:before {
				content: '';
				position: absolute;
				width: 100%;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: #f5dbc8;
				border-radius: 5px;
				height: 12px;
				z-index: -1;
			}
		}
	}
	h3 {
		color: #a5a7ad;
		margin-top: 25px;
		font-size: 20px;
		font-weight: 200;
	}
	.shoe-banners {
		margin-top: 25px;
		margin-left: 0;
		a {
			display: block;
			&:hover {
				.banner {
					box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
					.title {
						transition: all 0.2s ease-out;
						transform: translateY(5px);
					}
					&.boy-banner .title{
						transform: translateY(5px);
					}
				}
			} 
		}
		.banner {
			padding: 30px 35px;
			display: block;
			width: 100%;
			.title {
				font-size: 20px;
				font-weight: 700;
				color: #fff;
				display: block;
				line-height: 1.4;
				transition: all 0.2s ease-out;
				span {
					display: block;
					font-weight: 400;
				}
			}
		}
		.girl-banner {
			background-color: #f4c9aa;
			padding-bottom: 0;
			.title span {
				color: #c07d4d;
			}
		}
		.boy-banner {
			background-color: #c8def0;
			padding-top: 0;
			.title span {
				color: #5585ad;
			}
		}
	}
}


// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  
	
	
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  
	
	section.kidlet-shoes {
		.kdl-shoes-wrap {
			background-image: url('../images/Kdl-Pantofi-bg.jpg');
		}
	}

}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.kidlet-shoes {
		margin-bottom: 100px;
		h2 { 
			font-size: 28px;
		}
		h3 {
			font-size: 26px;
		}
		.shoe-banners {
			margin-top: 35px;
			margin-left: 8.33%;
			.banner { 
				.title {
					font-size: 24px;	
				}
			}
			.boy-banner { 
				margin-left: 1px;
				padding-top: 30px;
			}
			.girl-banner { 
				padding-bottom: 30px;
				margin-left: 1px;
			}
		}
	}



}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}

