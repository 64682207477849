section.kdl-coming-soon {
	.coming-soon-wrap {
		background-color: #e9f8ff;
		color: #1d4a68;
		padding: 40px 30px;
		border-radius: 6px;
		background-size: contain;
		background-position: right;
		background-repeat: no-repeat;

		h2 {
			color: #1d4a68;
			margin-bottom: 25px;
		}
		p {
			color: #1d4a68;
			margin-bottom: 25px;
			font-size: 16px;
    		line-height: unset;
		}
		a.btn {
			background-color: #1d4a68;
			font-size: 12px;
			color: #fff;
			font-weight: 800;
			text-transform: uppercase;
			border-radius: 16px;
			&:hover {
				background-color: #6ab6e7;
			}
		}
		.coming-soon-counter {
			margin-bottom: 30px;
			.count-wrap {
				display: inline-block;
				text-align: center; 
				padding: 0 20px;
				position: relative;
				color: #1d4a68;
				&:first-child {
					padding-left: 0;
				}
				&.hr,
				&.min {
					&:after {
						content: ':';
						font-family: 'Nunito';
						font-weight: 300;
						font-size: 60px;
						position: absolute;
						right: -10px;
						top: -5px;
						color: #1d4a68;
						line-height: 1;
					}
				}
				.count-val {
					font-family: 'Nunito';
					font-weight: 300;
					font-size: 46px;
					position: relative;
					line-height: 1;
				}
				.count-text {
					font-weight: 700;
					font-size: 16px;
				}
			}
		}
	}
}


// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.kdl-coming-soon {
		.coming-soon-wrap {
			padding: 60px 80px;
			background-image: url(../images/coming-prod-bg.jpg); 
		}
		.coming-soon-counter { 
			.count-wrap {
				.count-val {
					font-size: 60px;
				}
				.count-text {
					font-size: 18px;
				}
			}
		}
	}

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}
