//Blog Call Styles

.blog-call-cnt {
	background: linear-gradient(320deg, #c7faf3, #c0f7ff);
	background-size: 400% 400%;
	-webkit-animation: GradientBg 8s linear infinite;
	-moz-animation: GradientBg 8s linear infinite;
	animation: GradientBg 8s linear infinite;
	padding: 30px;
	border-radius: 8px;
	h2 {
		color: #000b41;
		font-size: 26px;
		font-weight: 800;
		margin-bottom: 15px;
		margin-top: 0;
	}
	a.btn {
		background-color: transparent;
		border: 2px solid #000b41;
		border-radius: 3px;
		display: block;
		text-transform: uppercase;
		font-size: 16px;
		font-weight: 700;
		padding: 11px 12px;
		color: #000b41;
	}
	p {
		font-size: 17px;
		font-weight: normal;
		color: #000b41;
		padding-right: 15px;
		margin-bottom: 0;
		margin-top: 0 !important;
	}
}

@-webkit-keyframes GradientBg {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@-moz-keyframes GradientBg {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@keyframes GradientBg { 
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}

ul.card-list {
	li {
		span {
			display: block;
			width: 100%;
			height: 100%;
			padding: 30px;
			border-radius: 8px;
			font-weight: 600;
		    color: #000b41;
		    background-color: #d3fff9;
		    font-size: 18px;
		}
	}
}