//Catalgo Page

.catalog-page-wrap {

	position: relative;
	.catalog-image-holder {
		position: absolute;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		min-height: 350px;
		top: 0;
		left: -100px;
		right: -100px;
	}
	.catalog-header {
		h1 {
			font-weight: 900;
			color: #000;
			font-size: 30px;
			margin-top: 0;
			margin-bottom: 3px;
		}
		h2 {
			font-weight: 400;
			color: #000;
			font-size: 18px;
			margin-top: 0;
		}
	}
	.catalog-header-inner {
		padding-top: 40px;
		.promo-links {
			margin-top: 36px;
			a {
				color: #000;
				font-size: 18px;
				padding: 0 20px;
				position: relative;
				i {
					color: #fff;
					margin-right: 10px;
					position: relative;
					top: 2px;
				}
				&:first-child {
					&:after {
						content: '|';
						position: absolute;
						right: 0;
					}
				}
			}
		}
		.breadcrumb {
			background-color: transparent;
			padding: 8px 0;
			margin-top: 12px;
			position: relative;
			a {
				color: #000;
				font-style: italic;
				font-size: 12px;
			}
			span.breadcrumb-item.active {
				font-style: italic;
				font-size: 12px;
				color: lighten(#000,25%);
			}
			&:before {
				content: '';
				position: absolute;
				width: 40px;
				height: 1px;
				top: 0;
				background-color: lighten(#000,35%);
			}
		}
	}
	.catalog-main {
		.container {
			background-color: #fff;
		}
		.catalog-main-inner {
			background-color: #fff;
			padding-top: 18px;
		}
	}
	.kidlet-sidebar {
		h4.filter-head {
			font-weight: 700;
			color: #000;
			font-size: 18px;
			margin: 0;

		}
		h5.filter-subhead {
			font-weight: 700;
			color: #000;
			font-size: 14px;
			margin-top: 0;
		}
		.filter-block {
			margin-top: 23px;
			&.inner-title {
				background-color: #eff9ff;
				padding: 15px 15px 20px 15px;
				h5.filter-subhead {
					color: #4595c9; 
				}
			}
		}
		ul.kidlet-filter-list {
			list-style: none;
			margin: 0;
			padding: 0;
			&.sibling-cat {
				li {
					a {
						color: #59a5d7;
						padding-left: 10px;
						font-weight: 600;
						&:before {
							display: none;
						}
						&:after {
							display: none;
						}
						&.active,
						&:hover,
						&:focus,
						&:active {
							color: #4595c9;
							font-weight: 700;
						}
					}
				}
			}
			&.active-filters {
				li {
					a {
						display: inline-block;
						&:before {
							display: none;
						} 
						&:after {
							content: "";
						    width: 10px;
						    height: 10px;
						    background-image: url(../images/kidlet-remove-icon.svg);
						    background-size: contain;
						    top: 6px;
						    position: absolute;
						    left: 2px;
						}
						&.active,
						&:hover,
						&:focus,
						&:active {
							color: #f05764;
							&:after {
								content: "";
							    width: 10px;
							    height: 10px;
							    background-image: url(../images/kidlet-remove-icon.svg);
							    background-size: contain;
							    top: 6px;
							    position: absolute;
							    left: 2px;
							}
						}
						&.delete-filter {
							color: #f05764;
							font-weight: 700;
							margin-top: 5px;
							&:hover {
								text-decoration: underline;
							}
						}
					}
					&:last-child {
						a {
							color: #f05764;
							font-weight: 700;
							margin-top: 5px;
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
			li {
				margin: 1px 0;
				a  {
					color: #252525;
					font-size: 12px;
					padding-left: 20px;
					padding-top: 2px;
					padding-bottom: 2px;
					position: relative;
					display: block;
    				padding-right: 10px;
					&:hover {
						color: #000;
						&:before {
							border: 1px solid #000;
						}

					}
					&:before {
						content: '';
						width: 13px;
						height: 13px;
						background-color: transparent;
						border: 1px solid #6b6b6b;
						position: absolute;
						left: 0;
						top: 3px;
					}
					&.active,
					&:active {
						color: #000;
						&:after {
							content: '';
							position: absolute;
							background-image: url('../images/checkmark-blue.svg');
							background-size: contain;
							fill: red;
							left: 3px;
							top: 7px;
							width: 7px;
							height: 5px;
						}
						&:before {
							background-color: #c8def0;
							border-color: #8bc5eb;
						}
						&:hover {
							&:before {

							}
						}
					}
					span.count {
						margin-left: 3px;
						color: lighten(#000,60%);
					}
				}
			}
		}
		.filter-additional {
			margin: 15px 0;
			select.kidlet-filter-select {
				width: 100%;
				//height: 32px;
			}
			span.select2 {
				span.select2-selection {
					border-radius: 0;
					//min-height: 32px;
					border-color: #e8e8e8;
				}
			}
			span.select2-container {
				span.select2-dropdown {
					span.select2-results {
						ul.select2-results__options {
							li.select2-results__option {
								color: red;
							}
						}
					}
				}
			}
		}
		.filter-additional-2 {
			.filter-dropdown {
				.dropdown-menu {
					max-width: 860px;
					width: 75vw;
					padding: 15px; 
					border-radius: 0;
					border: none;
					box-shadow: 0 40px 70px 0 rgba(0,0,0,.15);
				    transition: transform .3s cubic-bezier(0.4, 0, 1, 1);
				    -webkit-transform: translateY(-20px);
				    transform: translateY(-20px);
				    display: block;
				    visibility: hidden;
    				opacity: 0;
    				.top-bar {
    					margin-bottom: 15px;
						span.brand {
							font-weight: 700;
						    color: #000;
						    font-size: 14px;
						    margin-top: 0;
						    margin-right: 15px;
						}
						span.closefilter {
							font-size: 14px;
							font-weight: 700;
							color: #4595c9;
							cursor: pointer;
							display: inline-block;
							margin-top: 9px;
							i {
								position: relative;
								top: 1px;
								margin-right: 5px;

							}
						}
						input.innerfilterinput {
							padding-left: 10px;
							border-color: #e8e8e8;
							border-radius: 0;
							width: 30%;
							float: none;
							display: inline-block;
						}
						::-webkit-input-placeholder { /* Chrome/Opera/Safari */
						  color: #9f9f9f;
						  font-weight: 400;
						  font-size: 12px;
						  margin-left: 5px;

						}
						::-moz-placeholder { /* Firefox 19+ */
						  color: #9f9f9f;
						  font-weight: 400;
						  font-size: 12px;
						  margin-left: 5px;
						}
						:-ms-input-placeholder { /* IE 10+ */
						  color: #9f9f9f;
						  font-weight: 400;
						  font-size: 12px;
						  margin-left: 5px;
						}
						:-moz-placeholder { /* Firefox 18- */
						  color: #9f9f9f;
						  font-weight: 400;
						  font-size: 12px;
						  margin-left: 5px;
						}
    				}
    				.bottom-bar {
						a.outline-blue {
							color: #f05764;
						    background-color: transparent;
						    border-color: #f05764;
						    font-weight: 400;
						    border-radius: 0;
						    margin-right: 15px; 
						}
						a.filled-blue {
							color: #fff;
						    background-color: #90cc90;
						    border-color: #90cc90;
						    font-weight: 700;
						    border-radius: 0; 


						}
    				}
				}
				&.show {
					.dropdown-menu { 
						-webkit-transform: translateY(0px);
				    	transform: translateY(0px);
				    	transition: transform .3s cubic-bezier(0.4, 0, 1, 1);
						visibility: visible;
    					opacity: 1;
					}
					a.addition-filter-button,
					a.dropdown-toggle {
						&:hover,
						&:active,
						&:focus {
							background-color: transparent;
						}
					}
				}
				ul.kidlet-filter-list {
					@include clearfix;
					li {
						width: 33.333333%;
						float: left;

					}
				}
			}
			.addition-filter-button {
				border: none;
				margin: 5px 0;
				padding: 5px 0;
				color: #4595c9;
				font-size: 12px;
				font-weight: 700;
				cursor: pointer;
				i.kidlet-plus-icon {
					width: 12px;
					height: 12px;
					background-image: url('../images/kidlet-plus-icon.svg');
					background-size: contain;
					position: relative;
					margin-right: 5px;
					display: inline-block;
					transition: all 0.3s ease-out;
					top: 2px;	
				}
				&:after {
					display: none;
				}
				&:hover,
				&:focus,
				&:active {
					background-color: transparent;
					color: darken(#4595c9, 15%);
					i.kidlet-plus-icon {
						transform: rotate(180deg);
						transition: all 0.3s ease-out;
					}
				}
			}
		}
		ul.kidlet-filter-list-colors {
			list-style: none;
			margin: 0;
			padding: 0;
			@include clearfix;
			li {
			display: inline-block;
			float: left;
			margin: 3px 6px 3px 0;
				a {
					width: 27px;
					height: 27px;
					display: block;
					border: 1px solid transparent;
					background-color: transparent;
					position: relative;
					&:hover,
					&:focus,
					&:active,
					&.active {
						&:after {
							content: '';
							position: absolute;
							background-image: url('../images/checkmark-white.svg');
							background-size: contain;
							left: 6px;
							top: 8px;
							width: 14px;
							height: 10px;
						}
					}
					
					&.kdl-red {
						background-color: #f05764;
					}
					&.kdl-blue {
						background-color: #c8def0;
					}
					&.kdl-orange {
						background-color: #FFA04A;
					}
					&.kdl-yellow {
						background-color: #f6dd86;
					}
					&.kdl-green {
						background-color: #badfbc;
					}
					&.kdl-black {
						background-color: #000;
					}
					&.kdl-white {
						background-color: #fff; 
						border-color: #e8e8e8;
						&:hover,
						&:focus,
						&:active,
						&.active {
							&:after {
								background-image: url('../images/checkmark-black.svg');
							}
						}
					}
					&.kdl-turq {
						background-color: #8DC7BB;
					}
					&.kdl-violet {
						background-color: #A55FEB;
					}	
				}
			}
		}
	}
	.catalog-list {
		//padding-top: 100px;
		position: relative;
		.rapid-filter {
			min-height: 100px;
			z-index: 9999;
    		background-color: rgba(255, 255, 255, 0.95);
		}
		.rapid-filter-bar {
			position: absolute;
			top: 15px;
			left: 0;
			width: 100%;
			min-height: 50px;
			background-color: #fcfafa;
			box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.12);
			padding: 10px;
			.filter-bar-inner {
				
				.col-3 {
					padding-left: 10px;
					padding-right: 10px;
				}
				.btn-group.bootstrap-select {
					width: 100%;
				}

				.custom-select {
					width: 100%;
				}
				.rapid-filter-dropdown {
					display: -ms-flexbox;
				    display: -webkit-flex;
				    display: flex;
					a.dropdown-toggle,
					a.remove {
						-webkit-flex: 1 0 auto;
					    -ms-flex: 1 0 auto;
					    flex: 1 0 auto;
						background-color: #fff;
						text-align: left;
						border: 1px solid #e8e8e8;
						font-weight: 600;
						font-size: 14px;
						color: #000;
						border-radius: 0;
						cursor: pointer;
					}
					a.dropdown-toggle {
						min-width: 80%;
						padding-right: 10px;
						padding-left: 10px;
						&:after {
							content: "\e604";
						    display: inline-block;
						    width: auto;
						    height: auto;
						    border: none;
						    font-family: simple-line-icons;
						    font-size: 10px;
						    position: absolute;
						    right: 10px;
						    top: 12px;
						}

					}
					a.remove {
						&:after {
							content: "";
						    width: 12px;
						    height: 12px;
						    background-image: url(../images/kidlet-remove-icon.svg);
						    background-size: contain;
						    position: relative;
						    display: inline-block;
						    top: 2px;
						    position: absolute;
						    top: 50%;
						    margin-top: -6px;
						    left: 50%;
						    margin-left: -6px;
						}
					}
					.dropdown-menu {
						width: calc(100% + 20px);
						box-shadow: 0 40px 70px 0 rgba(0,0,0,.15);
					    border-radius: 0;
					    border: none;
					    display: block;
					    -webkit-transform: translateY(-10px);
					    transform: translateY(-10px);
					    transition: -webkit-transform .2s cubic-bezier(.4,0,1,1);
					    transition: transform .2s cubic-bezier(.4,0,1,1);
					    transition: transform .2s cubic-bezier(.4,0,1,1),-webkit-transform .2s cubic-bezier(.4,0,1,1);
					    visibility: hidden;
					    opacity: 0;
					    padding-left: 15px;
					    padding-top: 15px;
					    padding-right: 5px;
					}
					&.show {
						.dropdown-menu {
							-webkit-transform: translateY(0);
						    transform: translateY(0);
						    transition: -webkit-transform .2s cubic-bezier(.4,0,1,1);
						    transition: transform .2s cubic-bezier(.4,0,1,1);
						    transition: transform .2s cubic-bezier(.4,0,1,1),-webkit-transform .2s cubic-bezier(.4,0,1,1);
						    visibility: visible;
						    opacity: 1;
						}
					}
					.scroller {
						max-height: 350px;
						overflow-y: auto;
						.mCSB_scrollTools {
							.mCSB_dragger {
								.mCSB_dragger_bar {
									background-color:rgba(0,0,0,.8);
								}
							}
						}
					}
					ul.kidlet-filter-list {
						list-style: none;
						margin: 0;
						padding: 0;
						li {
							margin: 1px 0;
							a  {
								color: #252525;
								font-size: 12px;
								padding-left: 20px;
								padding-top: 3px;
								padding-bottom: 3px;
								position: relative;
								display: inline-block;
								&:hover {
									color: #000;
									&:before {
										border: 1px solid #000;
									}

								}
								&:before {
									content: '';
									width: 13px;
									height: 13px;
									background-color: transparent;
									border: 1px solid #6b6b6b;
									position: absolute;
									left: 0;
									top: 5px;
								}
								&.active,
								&:active {
									color: #000;
									&:after {
										content: '';
										position: absolute;
										background-image: url('../images/checkmark-blue.svg');
										background-size: contain;
										fill: red;
										left: 3px;
										top: 9px;
										width: 7px;
										height: 5px;
									}
									&:before {
										background-color: #c8def0;
										border-color: #8bc5eb;
									}
									&:hover {
										&:before {

										}
									}
								}
								span.count {
									margin-left: 3px;
									color: lighten(#000,60%);
								}
							}
						}
					}
				}
			}
			.filtrrapid {
				float: left;
				font-size: 16px;
				font-weight: 600;
				margin: 0 10px;
				line-height: 38px;
			}

		}
		.layout-order-select {
			.layout-select {
				margin-top: 8px;
				a {
					color: #878787;
					margin-right: 15px;
					font-size: 18px;
					i {

					}
					&:hover,
					&:focus,
					&:active,
					&.active {
						color: #000;
					}
				}
			}
			.order-select {
				.dropdown{
					display: inline-block;
					a.btn.dropdown-toggle {
						font-size: 14px;
						color: #000;
						padding-right: 0;
						cursor: pointer;
						&:after {
							content: "\e604";
							display: inline-block;
						    width: auto;
						    height: auto;
						    margin-left: 5px;
						    vertical-align: middle;
						    border: none;
						    font-family: 'simple-line-icons';
						    font-size: 12px;
						    transition: all .2s ease-out;
						}
						
					}
					.dropdown-menu {
						box-shadow: 0 40px 70px 0 rgba(0,0,0,.15);
						border-radius: 0;
						border: none;
						display: block;
						-webkit-transform: translateY(-10px);
				    	transform: translateY(-10px);
				    	transition: transform .2s cubic-bezier(0.4, 0, 1, 1);
						visibility: hidden;
						opacity: 0;
					}
					&.show {
						a.btn.dropdown-toggle {
							&:after {
								-webkit-transform: rotate(180deg);
							    transform: rotate(180deg);
							    transition: all .2s ease-out;
							}
						}
						.dropdown-menu {
							-webkit-transform: translateY(0px);
					    	transform: translateY(0px);
					    	transition: transform .2s cubic-bezier(0.4, 0, 1, 1);
							visibility: visible;
	    					opacity: 1;
							font-size: 14px;
							>a {
								padding: 3px 17px;
							}
						}
						
					} 
				}
				.ord-label {
					font-weight: 600;
					font-size: 14px;
					color: #878787;
				}
			}
		}
	}
	.kdl-pagination {
		margin-top: 50px;
		padding-bottom: 110px;
		.pagination {
			.page-item {
				margin: 0 5px;
				.page-link {
					border: none;
					border-radius: 0;
					color: #696969;
					font-weight: 700;
					padding: 7px 12px;
					&:hover,
					&:active,
					&:focus {
						background-color: transparent;
						color: #000;
					}
				
				}
				&:last-child {
					margin: 0;
					.page-link {
						padding-right: 0;
						text-align: right;
					}
				}
				&:first-child {
					margin: 0;
					.page-link {
						padding-left: 0;
					}
				}
				&.active {
					.page-link {
						border: 1px solid #000;
						color: #000;
						background-color: #fff;
					}
				}
			}
		}
		.product-number-select {
			.dropdown{
				display: inline-block;
				a.btn.dropdown-toggle {
					font-size: 14px;
					color: #000;
					cursor: pointer;
					border: 1px solid #e8e8e8;
					border-radius: 0;
					&:after {
						content: "\e604";
						display: inline-block;
					    width: auto;
					    height: auto;
					    margin-left: 5px;
					    vertical-align: middle;
					    border: none;
					    font-family: 'simple-line-icons';
					    font-size: 12px;
					    transition: all .2s ease-out;
					    color: #9c9c9c;
					}
					
				}
				.dropdown-menu {
					box-shadow: 0px 10px 15px 0px rgba(0,0,0,.15);
					border-radius: 0;
					border: none;
					display: block;
					-webkit-transform: translateY(-10px);
			    	transform: translateY(-10px);
			    	transition: transform .2s cubic-bezier(0.4, 0, 1, 1);
					visibility: hidden;
					opacity: 0;
					width: auto;
   					min-width: 70px;
				}
				&.show {
					a.btn.dropdown-toggle {
						&:after {
							-webkit-transform: rotate(180deg);
						    transform: rotate(180deg);
						    transition: all .2s ease-out;
						}
					}
					.dropdown-menu {
						-webkit-transform: translateY(0px);
				    	transform: translateY(0px);
				    	transition: transform .2s cubic-bezier(0.4, 0, 1, 1);
						visibility: visible;
    					opacity: 1;
						font-size: 14px;
						>a {
							padding: 3px 17px;
						}
					}
					
				} 
			}
			.ord-label {
				font-weight: 600;
				font-size: 14px;
				color: #878787;
				margin-right: 10px;
			}
		}
	}
}

.product-list {
	// a.prod-link {
	// 	.new-badge,
	// 	.promo-badge {
	// 		display: none;
	// 	}
	// 	&.new {
	//         .new-badge {
	//             display: block;
	//             position: absolute;
	//             top: 0;
	//             left: 0;
	//             background-image: url('../images/new-badge@2x.svg');
	//             background-position: center;
	//             background-size: cover;
	//             width: 42px;
	//             height: 41px;
	//             font-weight: 800;
	//             color: #fff;
	//             text-transform: uppercase;
	//             font-size: 12px;
	//             line-height: 45px;
	//             transition: all .1s ease-in;
	//             span {  
	//                 display: block;
	//                 position: relative;
	//                 text-align: center;
	//                 line-height: 1; 
	//                 &:first-child {
	//                     color: #5ca070;
	//                     font-size: 12px;
	//                     font-weight: 600; 
	//                     text-transform: initial;
	//                     margin-top: 8px;
	//                     margin-right: 5px;
	//                 }
	//             }
	//         }
	//     }
	//     &.promo {
	//         .promo-badge {
	//             display: block;
	//             position: absolute;
	//             top: 0;
	//             right: 0;
	//             background-image: url('../images/promo-badge@2x.svg');
	//             background-position: center;
	//             background-size: cover;
	//             width: 42px;
	//             height: 41px;
	//             font-weight: 800;
	//             color: #fff;
	//             text-transform: uppercase;
	//             font-size: 12px;
	//             line-height: 45px;
	//             text-align: center;
	//             transition: all .1s ease-in;
	//         }
	//     }
	//     &:hover {
	//     	span.gender { 
	// 			display: block;
	//     	}
	//     	&.new {
	//     		span.gender { 
	// 				top: 55px;
	//     		}
	//     		.new-badge {
	// 	    		background-image: url('../images/new-badge-black@2x.svg');
	// 	    		color: #fff;
	// 	    		transition: all .1s ease-in;	    		
	// 	    		span {
	// 	    			color: #fff;
	// 	    		}
	//     		}
	//     	}
	//     	&.promo .promo-badge {
	// 			background-image: url('../images/promo-badge-black@2x.svg');
	//     		color: #fff;
	//     		transition: all .1s ease-in;
	//     	}
	//     }
 //    }
	// a.prod-link {
	// 	display: block;
	// 	font-size: 12px;
	// 	color: #000;
	// 	font-weight: 600;
	// 	position: relative;
	// 	margin: 10px 0 30px 0;
	// 	transition: all .1s ease-in;
	// }
	// span.product-item,
	// span.img-holder,
	// span.bottom-content,
	// span.product-name,
	// span.product-price,
	// span.marime-wrap,
	// span.stare-wrap,
	// span.image-overlay,
	// span.background-shadow {
	// 	display: block;
	// }
	// span.img-holder {
	// 	text-align: center;
	// }
	// span.bottom-content {
	// 	padding-top: 15px;
	// 	padding-bottom: 15px;
	// 	background-color: #fff;
	// 	transition: all 5ms ease;
	// 	position: relative;
	// 	&:before {
	// 		content: '';
	// 		background-color: #fff;
	// 		height: 100%;
	// 		left: -8px;
	// 		width: 8px;
	// 		position: absolute;
	// 		top: 0; 
	// 	}
	// 	&:after {
	// 		content: '';
	// 		background-color: #fff;
	// 		height: 100%;
	// 		right: -8px;
	// 		width: 8px;
	// 		position: absolute;
	// 		top: 0; 
	// 	}
	// }
	// span.product-name {
	// 	.brand {
	// 		font-weight: 700;
	// 		text-transform: uppercase;
	// 	}
	// }
	// span.product-price {
	// 	margin-top: 10px;
	// 	.price {
	// 		font-weight: 700;
	// 		margin-right: 8px;
	// 		display: inline-block;
	// 	}
	// 	.promo-price {
	// 		font-weight: 800;
	// 		color: #cbcbcb;
	// 		text-decoration: line-through;
	// 		display: inline-block;
	// 	}
	// }
	// span.marime-wrap {
	// 	margin-top: 10px;
	// 	.marime {
	// 		font-weight: 700;
	// 		margin-right: 8px;
	// 		display: inline-block;
	// 	}
	// 	.marime-list {
	// 		font-weight: 800;
	// 		color: #cbcbcb;
	// 		display: inline-block;
	// 	}
	// }
	// span.stare-wrap {
	// 	margin-top: 10px;
	// 	.stare {
	// 		font-weight: 700;
	// 		margin-right: 8px;
	// 		display: inline-block;
	// 	}
	// 	.stare-list {
	// 		font-weight: 800;
	// 		color: #cbcbcb;
	// 		display: inline-block;
	// 	}
	// }
	// span.kdl-visible {
	// 	display: block;
	// }
	// span.kdl-invisible {
	// 	display: block;
		
	// }

	span.gender {
		position: absolute;
		top: 10px;
  		left: 5px;
		font-size: 11px;
		color: #fff;
		padding: 1px 4px;
		border: 1px solid #fff;
		font-weight: 800;
		text-transform: uppercase;
		letter-spacing: 1px;
		display: none;
		&.fete {
			text-shadow: 0px 1px 0px rgba(133, 46, 48, 0.15);
		}
	}

	span.seeicon {
		position: absolute;
		width: 48px;
		height: 48px;
		background-color: #fff;
		top: 45px;
		box-shadow: 0px 40px 63px 7px rgba(0, 0, 0, 0.15); 
		opacity: 0;
		visibility: hidden;
		border-radius: 50%;
		left: 50%;
		margin-left: -24px;
		i {
			font-size: 26px;
		    position: absolute;
		    top: 11px;
		    left: 11px;
		}
	}

	//Hover animation
	span.image-overlay-multi,
	span.image-overlay-soft {
		position: absolute;
	    top: -8px;
	    left: -8px;
	    bottom: 0;
	    right: -8px;
	    width: auto;
	    height: auto;
	    background: transparent;
	    opacity: 0;
	    transition: all .1s ease-in; 
	}
	span.background-shadow {
		position: absolute;
	    top: -8px;
	    left: -8px;
	    bottom: 0;
	    right: -8px;
	    width: auto;
	    height: auto;
	    background: transparent;
	    opacity: 0;
	    box-shadow: none;
	    transition: all .1s ease-in; 
	}
	span.bottom-content-invisible {
		position: absolute;
		top: 100%;
		visibility: hidden;
		opacity: 0;
		left: -8px;
		right: -8px;
		padding: 15px 8px;
		background-color: #fff; 
		//transition: transform 1s ease-out;
	}
	a.prod-link.animate,
	a.prod-link-new.animate {
		transition: all .1s ease-in; 
		&.fete {
			span.image-overlay-multi {
			    background: #f7c5c6;
    			mix-blend-mode: multiply;
    			opacity: .9;
    			transition: all .1s ease-in; 
			}
			span.image-overlay-soft {
			    background: #f7c5c6;
    			mix-blend-mode: soft-light;
    			opacity: 0;
    			transition: all .1s ease-in; 
			}
		}
		&.baieti {
			span.image-overlay-multi {
			    background: #c8def0;
    			mix-blend-mode: multiply;
    			opacity: .9;
    			transition: all .1s ease-in; 
			}
			span.image-overlay-soft {
			    background: #c8def0;
    			mix-blend-mode: soft-light;
    			opacity: 0;
    			transition: all .1s ease-in; 
			}
		}
		span.seeicon {
			transition: transform .3s cubic-bezier(0, 0, 0.2, 1);
			transform: translateY(10px); 
			opacity: 1;
			visibility: visible;
		}
		span.bottom-content {
			//display: none;
			position: relative;
			&:before {
				content: '';
				background-color: #fff;
				height: 100%;
				left: -8px;
				width: 8px;
				position: absolute;
				top: 0; 
			}
			&:after {
				content: '';
				background-color: #fff;
				height: 100%;
				right: -8px;
				width: 8px;
				position: absolute;
				top: 0; 
			} 
			.kdl-visible,
			.kdl-invisible { 
				//display: none;
			}
		}
		span.background-shadow { 
			box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
			opacity: 1;
			transition: all .1s ease-in;
		}
		span.bottom-content-invisible {
			position: absolute;
			top: auto;
			bottom: -25px;
			visibility: visible;
			opacity: 1;
			transition: transform .3s cubic-bezier(0, 0, 0.2, 1);
			transform: translateY(-25px); 
		}
	}
	
}

.mobile-filter-order {
	margin-bottom: 20px;
	a.btn {
		border: 1px solid #e8e8e8;
		border-radius: 0;
		color: #000;
		cursor: pointer;
		&:hover,
		&:focus,
		&:active {
			border-color: #4595c9;
			color: #4595c9;
		}
		span {
			display: block;
			&:first-child {
				font-size: 14px;
				font-weight: 600;
			}
			&:last-child {
				font-size: 12px;
				font-weight: 500;
			}
		}
	}
	#filter-activation {

	}
	#order-activation {

	}
}


//select2
span.select2-container {
	span.select2-dropdown {
		border-color: #e8e8e8;
		span.select2-results {
			ul.select2-results__options {
				li.select2-results__option {
					font-size: 12px;
				}
				li.select2-results__option--highlighted {
					background-color: #64a5d0;
    				color: #fff;
				}
			}
		}
	}
}


span.select2 {
	span.selection {
		span.select2-selection {
			ul.select2-selection__rendered {
				li.select2-search {
					input.select2-search__field {
						margin-top: 3px;
						margin-left: 3px;
					}
					::-webkit-input-placeholder { /* Chrome/Opera/Safari */
					  color: #000;
					  font-weight: 700;
					  font-size: 12px;

					}
					::-moz-placeholder { /* Firefox 19+ */
					  color: #000;
					  font-weight: 700;
					  font-size: 12px;
					}
					:-ms-input-placeholder { /* IE 10+ */
					  color: #000;
					  font-weight: 700;
					  font-size: 12px;
					}
					:-moz-placeholder { /* Firefox 18- */
					  color: #000;
					  font-weight: 700;
					  font-size: 12px;
					}
				}
				li.select2-selection__choice {
					font-size: 12px;
					background-color: #c8def0;
				    border: 1px solid #8bc5eb;
				    border-radius: 0px;
				    color: #3a769e;
				    margin-top: 8px;
				    span.select2-selection__choice__remove {
				    	color: #3a769e;
				    }
				}
			}
		}
	}
}

.kdl-page-desc {
	margin-top: 100px;
	h1 {
		font-weight: 800;
		font-size: 28px;
		margin-top: 30px;
		color: #000;
	}
	h2 {
		font-weight: 800;
		font-size: 26px;
		margin-top: 30px;
		color: #000;
	}
	h3 {
		font-weight: 800;
		font-size: 24px;
		margin-top: 30px;
		color: #000;
	}
	h4 {
		font-weight: 800;
		font-size: 20px;
		margin-top: 30px;
		color: #000;
	}
	a {

	}
	ul {
		li {
			color: #000;
		}
	}
	p {
		font-size: 14px;
	    padding-right: 5%;
	    line-height: 1.8;
	    color: #000;
	}
	&.general-page {
		font-weight: 700;
		font-size: 18px;
		margin-top: 50px;
		color: #000;
		h3 {
			font-weight: 800;
			font-size: 20px;
			color: #000;
		}
		h4 {
			font-weight: 700;
			font-size: 16px;
			margin-top: 30px;
			color: #000;
		}
		p {
			font-weight: 600;
			font-size: 16px;
			margin-top: 30px;
			color: #000;
		} 
		ul {
			margin-top: 30px;
			li {
				font-weight: 600;
				font-size: 16px;
				color: #000;
			}
		}
		ul {
			margin-top: 30px;
			ol {
				font-weight: 600;
				font-size: 16px;
				color: #000;
			}
		}
	}
	.green-box {
		font-weight: 800;
	    font-size: 20px;
	    margin-top: 30px;
	    padding: 15px;
	    color: black;
	    background-color: #cce8cd;
	    font-style: italic;
	}
}


// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

	.catalog-page-wrap {
	    margin-top: 35px;
	    position: relative;
	}

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {


}


