section.true-false {
	padding-bottom: 30px;
	.true-false-inner {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			background-color: #f7f9fb;
			top: 30%;
			bottom: -5%;
			width: 100%;
			left: 0;
			right: 0;
			z-index: -1;
			display: none;
		}
		.no-padding {
			padding-left:0;
			padding-right:0;
		}
	}
	.imtems-wrap {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: bottom center;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			background-color: rgba(255, 255, 255, .95);
			top: 30px;
			bottom: 0;
			width: 50%;
			left: 0;
			right: auto;
			z-index: 5;
			border-radius: 15px;
		}
		&:after {
			content: '';
			position: absolute;
			background-color: rgba(255, 255, 255, .95);
			top: 30px;
			bottom: 0;
			width: 50%;
			right: 0;
			left: auto;
			z-index: 5; 
			border-radius: 15px;
		}
	}
	.item-header {
		position: relative;
		z-index: 10;
		ul {
			padding: 0 15px;
			li {
				border-radius: 15px;
				padding: 15px;
				text-align: center;
				font-size: 22px;
				color: #fff;
				font-weight: 800;
				&:first-child {
					background-color: #94d99d;
				}
				&:last-child {
					background-color: #d54855;
				}
			}
		}
		&:after {
			content: 'VS.';
			text-align: center;
			font-size: 24px;
			font-weight: 800;
			color: #000;
			line-height: 80px;
			border-radius: 50%;
			height: 80px;
			width: 80px;
			background-color: #fff;
			top: 50%;
			margin-top: -40px;
			left: 50%;
			margin-left: -40px;
			position: absolute;
		}
		&:before {
			content: '';
			height: 30px;
			width: 100%;
			background-color: #fff;
			top: 0;
			position: absolute;
		}
	}
	.item {
		position: relative;
		z-index: 10;
		ul {
			margin-bottom: 0;
			li {
				font-weight: 700;
				font-size: 15px;
				color: #000;
				position: relative;
				&:first-child {
					color: #4eb54e;
					padding: 8px 30px 8px 15px;
					text-align: right;	
					&:after {
						content: "adevarat";
					    padding: 0px 4px;
					    background-color: #9ed997;
					    color: #2a6922;
					    position: absolute;
					    font-size: 10px;
					    border-radius: 3px;
					    font-weight: 800;
					    transform: rotate(-90deg) translateX(-38px);
					    right: -5px;
					    top: 33%;
						display: block;
						opacity: 0;
						visibility: hidden;
						transition: all .3s ease;
					}
				}
				&:last-child {
					padding: 8px 15px 8px 30px;
					color: #e44956;
					&:after {
						content: "fals";
					    padding: 0px 6px;
					    background-color: #e44956;
					    color: #fff;
					    position: absolute;
					    font-size: 10px;
					    border-radius: 3px;
					    font-weight: 800;
					    transform: rotate(-90deg) translateX(-38px);
					    right: 20px;
					    top: 33%;
						display: block;
						opacity: 0;
						visibility: hidden;
						transition: all .3s ease;
					}
				}
				&:hover {
					&:first-child,
					&:last-child {
						&:after {
							opacity: 1;
							visibility: visible;
							transition: all .4s ease;
							transform: rotate(-90deg) translateX(-15px);
						}
					}
				}
			}
		}
	}
}


// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.true-false {
		padding-bottom: 60px;
		.true-false-inner {
			&:before {
				display: block;
			}
			.no-padding {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		.item-header {
			ul {
				li {
					padding: 30px 15px;
					text-align: center;
					font-size: 26px;
				}
			}
			&:after {
				font-size: 26px;
				font-weight: 800;
				line-height: 80px;
				height: 80px;
				width: 80px;
			}
		}
		.item {
			ul {
				li {
					font-size: 16px;
					&:first-child {
						padding: 15px 30px 15px 45px;
					}
					&:last-child {
						padding: 15px 45px 15px 30px;
						
					}
				}
			}
		}
	}


}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}