body.checkout {
	background-color: #f7f9f9;
}

header.checkout-desktop {
	margin-top: 30px;
	.back-to-site-link {
		margin-top: 4px;
		margin-left: 15px;
		a {
			font-weight: 700;
			color: #4792da;
			&:hover {
				color: darken(#4792da,5%);
				//text-decoration: underline;
			}
		}
		i {
			position: relative;
			top: 2px;
			margin-right: 8px;
			text-decoration: none;
		}
	}
	.logo {
		a {
			display: block;
		}
		img {
			//box-shadow: 0px 30px 30px 0px rgba(36, 63, 91, 0.1);
		}
	}
	.ssl-ready {
		margin-top: 4px;
		margin-right: 15px;
		i {
			color: #5ca070;
			margin-left: 8px;
		}
		a {
			font-weight: 700;
			color: #9ea6b3;
		}
	}
}

.kidlet-cart-wrap {
	margin-top: 45px;
	margin-bottom: 80px;
	h2 {
		font-weight: 800;
		font-size: 26px;
		margin-bottom: 15px;
	}
	.kidlet-cart {
		box-shadow: 0px 30px 30px 0px rgba(36, 63, 91, 0.1);
		background-color: #fff;
		padding: 0;
		.cart-header-desktop {
			background-color: #f9f9f9;
			color: #b2b2b2;
			font-size: 14px;
			padding: 15px 30px;
			font-weight: 600;
		}
		.cart-item {
			padding: 15px;
			border-bottom: 1px solid #f3f3f3;
			.item-img-name {
				.item-img {
					display: block;
					float: none;
					text-align: center;
					margin-bottom: 15px
				}
				.item-name {
					margin-left: 0px;
					float: left;
					display: inline-block;
					span {
						display: block;
					}
					.name {
						font-weight: 600;
						font-size: 16px;
						margin-bottom: 12px;
						a {
							color: #000;
							&:hover {
								color: #4792da;
								text-decoration: underline;
							}
						}
					}
					.desc {
						color: #afafaf;
						font-weight: 600;
						font-size: 14px;
					}
				}
			}
			.item-price {
				margin-top: 25px;
				font-weight: 600;
			}
			.product-number-select {
				margin-top: 20px;
				.dropdown{
					display: inline-block;
					a.btn.dropdown-toggle {
						font-size: 16px;
						color: #000;
						cursor: pointer;
						border: 1px solid #9da5b2;
						border-radius: 0;
						font-weight: 600;
						&:after {
							content: "\e604";
							display: inline-block;
						    width: auto;
						    height: auto;
						    margin-left: 5px;
						    vertical-align: middle;
						    border: none;
						    font-family: 'simple-line-icons';
						    font-size: 12px;
						    transition: all .2s ease-out;
						    color: #9c9c9c;
						}
						
					}
					.dropdown-menu {
						box-shadow: 0px 10px 15px 0px rgba(0,0,0,.15);
						border-radius: 0;
						border: none;
						display: block;
						-webkit-transform: translateY(-10px);
				    	transform: translateY(-10px);
				    	transition: transform .2s cubic-bezier(0.4, 0, 1, 1);
						visibility: hidden;
						opacity: 0;
						width: auto;
	   					min-width: 70px;
					}
					&.show {
						a.btn.dropdown-toggle {
							&:after {
								-webkit-transform: rotate(180deg);
							    transform: rotate(180deg);
							    transition: all .2s ease-out;
							}
						}
						.dropdown-menu {
							-webkit-transform: translateY(0px);
					    	transform: translateY(0px);
					    	transition: transform .2s cubic-bezier(0.4, 0, 1, 1);
							visibility: visible;
	    					opacity: 1;
							font-size: 14px;
							>a {
								padding: 3px 17px;
							}
						}
						
					} 
				}
				.ord-label {
					font-weight: 600;
					font-size: 14px;
					color: #000;
					margin-left: 5px;

				}
			}
			.item-delete {
				margin-top: 25px;
				font-weight: 600;
				a {
					color: #000;
					font-weight: 600;
					&:hover {
						color: #f05764;
					}
					i {
						margin-right: 5px;
					}
				}
			}
			.item-total {
				margin-top: 25px;
				font-weight: 600;
				text-align: center;
			}
		}
		.cart-total-coupon {
			padding: 15px;
			.user-coupon {
				ul {
					li {
						border: 1px solid #4792da;
						margin-bottom: 5px;
					}
				}
				span.desc {
					padding: 2px 15px;
					color: #4792da;
					font-size: 16px;
					font-weight: 700;
					i {
						position: relative;
						margin-right: 5px;
						top: 2px;
						font-size: 18px;
					}
				}
				a.button {				
					font-size: 14px;
					font-weight: 800;
					background-color: #4792da;
					border-color: #4792da;
					color: #fff;
					display: flex;
					justify-content: center;
				    align-items: center;
				    align-self: stretch;
				    &:hover {
				    	background-color: darken(#4792da,10%);
						border-color: darken(#4792da,10%);
				    }
				}
			}
			.coupon {
				border: 1px solid #cce7cd;
				a.btn-coupon {
					color: #78b978;
					font-size: 16px;
					font-weight: 700;
					&:hover,
					&:focus,
					&:active,
					&.active {
						outline: 0;
						box-shadow: none;
						border-color: transparent;
					} 
					i {
						position: relative;
						top: 2px;
						margin-left: 8px;
					}
				} 
				.card.card-block {
					border: none;
					border-radius: 0;
					border-top: 1px solid #cce7cd;
					button.btn-block {
						font-size: 18px;
					    font-weight: 800;
					    margin-bottom: 15px;
					    cursor: pointer;
					    color: #4792da;
					    background-color: #fff;
					    border-color: #4792da;
					    border-width: 2px;
						&:hover {
							background-color: darken(#4792da,5%);
							color: #fff;
						}
					}
				}
			}
		}
		.total {
			font-weight: 600;
			font-size: 16px;
			margin-top: 25px;
			hr {
				margin: 8px 0;
			}
			.float-right {
				font-weight: 700;
			}
			.gratuit {
				color: #78bf78; 
			}
			span {
				display: inline-block;
			}
			.absolute-total {
				font-size: 18px;
				font-weight: 800;
			}
			span.sterge {
				a {
					font-size: 10px;
					font-weight:600;
					padding: 0 10px;
					border-radius: 8px;
					border: 1px solid #f05865;
					background-color: #fff;
					color: #f05865;
					line-height: 14px;
					span {
						font-size: 11px;
						font-weight: 700;
						position: relative;
						margin-right: 4px;
					}
					&:hover {
						background-color: #f05865;
						color: #fff;
					}
				}
			}
		}
	}
}

.existing-client {
	margin: 0;
	border: 2px solid #8bc5eb;
	background-color: #fff;
	margin-bottom: 75px;
	a.btn-existclient {
		line-height: 50px;
		color: #8bc5eb;
		font-size: 16px;
		font-weight: 700;
		background-color: #f5faff;
		&:hover,
		&:focus,
		&:active,
		&.active {
			outline: 0;
			box-shadow: none;
			border-color: transparent;
		}
		&.collapsed {
			background-color: #fff;
		}
		i {
			font-size: 30px;
			position: relative;
			top: 5px;
			margin-right: 15px;
		}
		.login_message {
			white-space: normal;
			line-height: 1.5;
			display: block;
			>span {
				color: #4792da;
				text-decoration: underline;

			}
		}
	}
	.card.card-block {
		border: none;
		border-top: 1px solid #8bc5eb;
		.new-client-message {
			font-size: 14px;
    		font-weight: 600;
    		i {
    			position: relative;
    			top: 1px;
    			margin-left: 5px;
    		}
		}
		button.btn {
			font-size: 18px;
			font-weight: 800;
			margin-bottom: 15px;
			cursor: pointer;
		    color: #4792da;
		    background-color: #fff;
		    border-color: #4792da;
		    border-width: 2px;
			&:hover,
			&:focus,
			&:active,
			&.active {
				color: #fff;
			    background-color: #4792da;
			    border-color: #4792da;
			    border-width: 2px;
			}
		}
		form {
			label {
				font-size: 14px;
				font-weight: 600;
			}
		}
	}
}

.checkout-form-section {
	background-color: #fff;
	box-shadow: 0px 30px 30px 0px rgba(36, 63, 91, 0.1);
	padding: 20px 15px 20px;
	margin-bottom: 40px;
	.title-section {
		margin-bottom: 30px;	
	}
	h2 {
		font-weight: 800;
		margin-bottom: 1px;
		font-size: 26px; 
	}
	h3 {
		font-weight: 800;
		font-size: 20px;
		color: #000;
		margin-bottom: 18px;
	}
	.form-group {
		input {
			border: none;
			border-bottom: 1px solid #000;
			margin-bottom: 20px;
			padding-left: 0;
			padding-right: 0;
			font-family: 'Nunito';
			color: #000;
			&:focus {
				border-color: #78bf78;
				border-width: 1px;
				box-shadow: 0px 2px 0px 0 #78bf78;
			}
		}
		select.custom-select {
			border-radius: 0;
			width: 100%;
			color: #000;
			margin-bottom: 20px;
			border-color: #000;
		}
		input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: #000;
		  	font-size: 16px;
		  	font-weight: 500;
		  	font-family: 'Nunito';
		}

		input:-moz-placeholder { /* Firefox 18- */
			color: #000;
		  	font-size: 16px;
		  	font-weight: 500;
		  	font-family: 'Nunito';
		}

		input::-moz-placeholder { /* Firefox 19+ */
			color: #000;
		  	font-size: 16px;
		  	font-weight: 500;
		  	font-family: 'Nunito';
		}

		input:-ms-input-placeholder { /* IE */
			color: #000;
		  	font-size: 16px;
		  	font-weight: 500;
		  	font-family: 'Nunito';
		}

		input::-ms-input-placeholder { /* IE Edge */
			color: #000;
		  	font-size: 16px;
		  	font-weight: 500;
		  	font-family: 'Nunito';
		}
	}
	.planifica-livrare { 
		.plan-livrare-text { 
			margin-top: 10px;
			i {
				font-size: 24px;
			    position: relative;
			    top: 4px;
			    margin-left: 8px;
			}
		}
	}
	.observatii {
		textarea {
			border-color: #000;
		}
	}

  

	.firm-check {
		.custom-checkbox:not(.collapsed) {
			.custom-control-input~.custom-control-indicator {
				color: #fff;
    			background-color: #0275d8;
	 			background-image: $custom-checkbox-checked-icon;
			}	
		}
	}
}

.total-sticky {
	width: 100%;
	font-weight: 600;
	font-size: 16px;
	background-color: #fff;
	padding: 20px 15px 30px 15px;
	box-shadow: 0px 30px 30px 0px rgba(36, 63, 91, 0.1);
	margin-bottom: 40px;
	h3 {
		font-weight: 800;
		font-size: 20px; 
	}
	hr {
		margin: 8px 0;
	}
	.float-right {
		font-weight: 700;
	}
	.gratuit {
		color: #78bf78; 
	}
	span {
		display: inline-block;
	}
	.absolute-total {
		font-size: 18px;
		font-weight: 800;
	}
	span.sterge {
		a {
			font-size: 10px;
			font-weight:600;
			padding: 0 10px;
			border-radius: 8px;
			border: 1px solid #f05865;
			background-color: #fff;
			color: #f05865;
			line-height: 14px;
			span {
				font-size: 11px;
				font-weight: 700;
				position: relative;
				margin-right: 4px;
			}
			&:hover {
				background-color: #f05865;
				color: #fff;
			}
		}
	}
}

.checkout-submit-section {
	.news-checkbox-section {
		.custom-control {
			.custom-control-description{
				font-size: 14px;
				margin-top: 2px;
			}
		}
	}
	.submit-section {
		button {
			background-color: #5caa5c;
			color: #fff;
			font-size: 22px;
			font-weight: 900;
			font-family: 'Nunito';
			padding: 12px 5px;
			cursor: pointer;
			box-shadow: 0px 30px 30px 0px rgba(23, 99, 23, 0.15);
			margin-top: 30px;
			width: 100%;
			display: block;
			i {
				margin-left: 0;
			}
			&:hover {
				background-color: darken(#5caa5c, 5%);
			}
		}
	}
}

footer.checkout-footer {
	margin-top: 75px;
	padding: 30px 15px;
	hr {

	}
	.kidlet-benefit-item {
		i {
			display: block;
			font-size: 28px;
			color: #2e2e2e;
			position: relative;
			&:after {
				content: "";
			    background-color: #c8c8c8;
			    width: 24px;
			    height: 24px;
			    border-radius: 100%;
			    position: absolute;
			    left: -10px;
			    top: -5px;
			    z-index: -1;
			}
		}
		h4 {
			color: #252525;
			font-size: 16px;
			font-weight: 700;
			margin-top: 15px;
			margin-bottom: 10px;
		}
		p {
			font-weight: 600;
			color: #747474;
			font-size: 14px;
		}
	}
	.footer-bottom {
		width: 100%;
		span {
			font-size: 12px;
			color: #747474;
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  

	header.checkout-desktop {
		margin-top: 50px;
		.back-to-site-link {
			margin-top: 14px;
			margin-left: 0;
		}
		.ssl-ready {
			margin-top: 14px;
			margin-right: 0;
		}
	}

	.checkout-form-section {
		h2 {
			font-size: 30px;
		}
	}	


	.checkout-submit-section {
		.submit-section { 
			button {
				padding: 12px 30px;
				font-size: 24px;
				width: auto;
				display: inline-block;
				i {
					margin-left: 10px;
				}
			}
		}
	}
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  

	.kidlet-cart-wrap {
		margin-top: 85px;
		margin-bottom: 80px;
		h2 {
			font-size: 30px;
			margin-bottom: 25px;
		}
		.kidlet-cart {
			padding: 4px;
			.cart-item {
				padding: 30px;
				.item-img-name {
					.item-img {
						display: inline-block;
						float: left;
					} 
					.item-name {
						margin-left: 15px;
						float: left;
						display: inline-block;
						span {
							display: block;
						}
						.name {
							margin-bottom: 12px;
						}
					}
				}
				.item-total {
					margin-top: 25px;
					font-weight: 600;
					text-align: right;
				}
			}
			.cart-total-coupon {
				padding: 30px;
				.coupon {
					float: left;
				}
				.total {
					width: 75%;
					float: right;
					margin-top: 0;
					span {
						display: inline-block;
					}
				}
			}

		}

	}
	
	.existing-client {
		margin: 0 50px;
		margin-bottom: 75px;
		a.btn-existclient {
			.login_message {
				display: inline;
			}
			
		}
		.card.card-block {
			
			.new-client-message {

	    		i {
	    			position: relative;
	    			top: 1px;
	    			margin-left: 5px;
	    		}
			}
			button.btn {
				
			}
			form {
				label {
					font-size: 14px;
					font-weight: 600;
				}
			}
		}
	}

	.checkout-form-section {
		padding: 35px 30px 60px 30px;
	}
	
	// .total-sticky {
	// 	width: 92%;
	// 	padding: 35px 30px 60px;
	// }

	footer.checkout-footer {
		margin-top: 175px;
		padding: 30px 0;
	}
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	.total-sticky {
		width: 92%;
		padding: 35px 30px 60px;
	}

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}


