//New products

section.new-products {
	margin-top: 30px;
	margin-bottom: 60px;
	padding-top: 70px;
	.new-prod-wrap {
		padding-top: 30px;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			bottom: 60px;
			top: 0;
			left: -15px;
			right: -15px;
			background-color: #cbedcf;
		}
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			top: 0px;
			left: 35%;
			right: -15px; 
			background-color: transparent;
			background-image: url(../images/kidlet-noutati-palm.png);
			background-size: auto;
			background-position-x: left;
    		background-position-y: top;
			background-repeat: no-repeat;
		}
		h2.noutati-title{
			margin-top: 20px;
			font-weight: 800;
    		font-size: 24px;
    		color: #000;
    		letter-spacing: .5px;
		}
		.new-products-nav {
			text-align: left;
			margin: 15px 0;
			.owl-prev,
			.owl-next {
				font-size: 20px; 
				color: #000;
				margin: 5px auto;
				cursor: pointer;
				display: inline-block;
				&:hover {

				}
			}
			.owl-next {
			}
		}
		.new-prod-link {
			a {
			    position: absolute;
			    background-color: #ffffff;
			    padding: 18px 25px;
			    top: -33px;
			    z-index: 100;
			    left: auto;
			    right: 0;
			    box-shadow: 8px 10px 20px 0 rgba(46,61,73,.15);
			    color: #000;
			    transition: all 0.3s ease;
			    transform: translateX(0px);
				i {
					position: relative;
					top: 1px;
					margin-left: 5px;
					transition: all 0.3s ease;
				}
				&:hover {
					box-shadow: 2px 4px 8px 0 rgba(46,61,73,.2);
					transition: all 0.3s ease;
					transform: translateX(5px);
					i {
						margin-left: 10px;
						transition: all 0.3s ease;
					}
				}
			}
		} 
	}
	.new-prod-slider-wrap,
	.new-prod-slider-wrap-single-page {
		.new-prod-item {
			img.img-fluid {
				width: auto;
			}
		}
	}
	.new-prod-slider-wrap,
	.new-prod-slider-wrap-single-page { 
		padding: 15px;
		box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.15);
		background-color: #fff;
		.owl-dots {
		   	text-align: left;
		   	margin-right: 15px;  		
		}
	}
	.new-prod-item {
		
		a {
			color: #000;
			font-weight: 500;
			font-size: 14px;
			display: block;
			padding: 15px;
			margin-bottom: 8px;
			margin-left: 5px;
			margin-right: 5px;
			transition: all 0.3s ease;
			// &:hover {
			// 	transition: all 0.3s ease;
			// 	box-shadow: 2px 4px 8px 0 rgba(46,61,73,.2);
			// }
		}
		.item-img {
			margin-bottom:20px;
		}
		.name {
			margin-bottom: 15px;
			padding: 0 10px;
			font-size: 14px;
			.brand {
				font-weight: 600;
				text-transform: uppercase;
				letter-spacing: .5px;
				font-size: 14px;
			}
		}
		.price {
			font-weight: 600;
			padding-right: 15px;
			.discount {
				margin-right: 10px;
				color: #a0a0a0;
				text-decoration: line-through;
			}
		}
	}
}


// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  

	section.new-products {
		margin-bottom: 100px;	
		.new-prod-slider-wrap,
			.new-prod-slider-wrap-single-page {
			padding: 40px 15px 15px;
			box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.15);
			background-color: #fff;
		}
		.new-prod-wrap { 
			padding-top: 60px;
			&:before {
			    bottom: 60px;
			    top: 0;
			    left: 0;
			    right: 50%;
			}
			&:after {
			    bottom: 0;
			    top: -70px;
			    left: 61px;
			    right: 49%;
			    background-size: auto;
			    background-position-x: right;
			    background-position-y: top;
			}
			.new-products-nav {
				text-align: center;	
				margin: 0;
			}
			h2.noutati-title {
				-webkit-transform: rotate(-90deg);
   				transform: rotate(-90deg);
   				font-size: 28px;
			}
			.new-prod-link {
				a {
				    padding: 18px 25px;
				    top: -33px;
				    z-index: 100;
				    left: 38%;
				    right: auto;
				}
			}
			.new-products-nav {
				margin-top: 120px;
				.owl-prev,
				.owl-next {
					font-size: 20px; 
					color: #000;
					margin: 5px auto;
					cursor: pointer;
					display: block;
					
				}
				.owl-next {

				}
			}
		}
	}


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  



}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}




