section.kdl-promo-slider {
	.section-title {
		margin-top: 10px;
	}
	.section-link {
		text-transform: uppercase;
	    font-size: 12px;
	    font-weight: 700;
	    letter-spacing: 1px;
	    a {
			&:hover {
				.lnr {
					transition: all .3s ease;
					margin-left: 15px;
				}
			}
		}
	    .lnr {
	    	color: red;
		    margin-left: 10px;
		    -webkit-text-stroke: 1px red;
		    position: relative;
		    top: 1px;
		    transition: all .3s ease;
	    }
	}
	.promo-slider-wrap {
		position: relative;
		.products-carousel-nav {
			position: absolute;
		    top: 60%;
		    right: -15px; 
		    left: -15px;
		    z-index: 10;
		    .right,
		    .left {
		    	i {
		    		position: absolute;
				    left: 0;
				    right: auto;
				    line-height: 30px;
				    width: 30px;
				    height: 30px;
				    font-size: 15px;
				    background-color: #000;
				    text-align: center;
				    color: #fff;
				    border-radius: 50%;
				    cursor: pointer;
		    	}
		    }
		    .right {
		    	i {
			    	right: 0;
			    	left: auto;
		    	}
		    }
		}
	}
	.owl-dots {
		.owl-dot {
			&.active {
				span {
					border:2px solid #f05764;
					background-color: #fff;
				}
			}
		}
	}
	.hide {
		display: none;
	}
	.owl-stage {
		left: -40px;
	}
	.owl-nav {
		margin-top: 0;
	}
	.owl-item {
		.product {
			a {
				//box-shadow: rgba(45,45,45,0.03) 0px 2px 2px, rgba(49,49,49,0.03) 0px 4px 4px, rgba(42,42,42,0.03) 0px 8px 8px, rgba(32,32,32,0.03) 0px 16px 16px, rgba(49,49,49,0.05) 0px 16px 16px, rgba(35,35,35,0.03) 0px 16px 16px;
				transition: box-shadow .3s ease-out, transform .2s ease-out;
			    transition-delay: .1s;
			    transform: translateY(0);
			    border-radius: 6px;
			    display: block;
			    z-index: 90;
			    position: relative;
			    box-shadow: none;
			    //opacity: 0.5;
			   	//filter: grayscale(100%);
			}
		}
		&.active {
			.product {
				a {
					transition: box-shadow .3s ease-out, transform .2s ease-out;
				    transition-delay: .1s;
				    transform: translateY(-5px);
				    box-shadow: rgba(45,45,45,0.03) 0px 2px 2px, rgba(49,49,49,0.03) 0px 4px 4px, rgba(42,42,42,0.03) 0px 8px 8px, rgba(32,32,32,0.03) 0px 16px 16px, rgba(49,49,49,0.05) 0px 16px 16px, rgba(35,35,35,0.03) 0px 16px 16px;
					//opacity: 1;
					//filter: grayscale(0);
				}
			}
		}
	}
	.product{
		border-radius: 6px;
		font-size: 12px;
		color: #000;
		font-weight: 600;
		margin-bottom: 20px;
		.img-holder {
			padding: 6px;
		}
		.desc{
			padding: 15px;
		}
		.name {
			margin-bottom: 5px;
		}
		.brand {
			font-weight: 800;
			text-transform: uppercase;
		}
		.price {
			font-size: 14px;
			margin-bottom: 5px;
			font-weight: 800;
		}
		.discount {
			color: #999;
			text-decoration: line-through;
			display: inline-block;
			font-weight: 700;
			margin-right: 12px;
		}
		.marimi {
			span {
				color: #afafaf;
				display: inline-block;
				margin-left: 5px;
				font-weight: 700;
			}
			span.marime {
				font-weight: 700;
				margin-right: 7px;
				color: #000;
				margin-left: 0;
			}
		}
	}
	#product-terms {
		.list-inline {
			padding-left: 0;
		    list-style: none;
		    li {
		    	display: inline-block;
			    padding: 10px;
			    a {
			    	font-size: 14px;
			    	font-weight: 800;
			    	color: #999;
			    	text-transform: uppercase;
			    	&:hover,
			    	&:active,
			    	&.active {
			    		color: #000;
			    	}
			    }
			    &:first-child {
			    	padding-left: 0;
			    }
		    }
		}
	}
}


// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  
	
	section.kdl-promo-slider {
		.owl-stage {
			left: auto;
		}
	}

}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  
		
	section.kdl-promo-slider {
		.owl-stage {
			left: auto;
		}
	}

}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.kdl-promo-slider {
		.section-title {
			margin-top: 0;
		}
		.owl-stage {
			left: auto;
		}
		.owl-nav {
			position: absolute;
			top: -16%;
			right: 0;
			left: auto;
			.owl-next,
			.owl-prev {
				&:hover {
					background-color: #a9b5c8;
				}
				&.disabled {
					&:hover {
						background-color: #d6d6d6;
						opacity: .5;
					}
				}
			}
		}
		.owl-item {
			.product {
				a {
					box-shadow: none;
					transition: box-shadow .3s ease-out, transform .3s ease-out;
				    transition-delay: .1s;
				    transform: translateY(10px);
				}
			}
			&.active {
				.product {
					a {
						transition: box-shadow .3s ease-out, transform .3s ease-out;
					    transition-delay: .1s;
					    transform: translateY(0);
					    box-shadow: none;
					    &:hover {
							transform: translateY(-5px);
							transition: box-shadow .3s ease-out, transform .2s ease-out;
							box-shadow: rgba(45,45,45,0.03) 0px 2px 2px, rgba(49,49,49,0.03) 0px 4px 4px, rgba(42,42,42,0.03) 0px 8px 8px, rgba(32,32,32,0.03) 0px 16px 16px, rgba(49,49,49,0.05) 0px 16px 16px, rgba(35,35,35,0.03) 0px 16px 16px;
					    }
					}
				}
			}
		}
	}

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}
