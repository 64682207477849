section.kdl-auto-type {
	.container {
		padding-top: 40px;
		padding-bottom: 60px;
	}
	span#typed {
		font-size: 26px;
		//color: #717f8c;
		color: #000;
		font-weight: 800;
		display: inline;
		position: relative;
		transition: all 0.5s ease-in-out;
		z-index: 5;
		line-height: 18px;
		&:before {
			position: absolute;
			content:'';
			top: -40px;
			bottom: -40px;
			left: -40px;
			right: 50%;
			background-color: #fcf6cd;
			transition: all 0.5s ease-in-out;
			border-radius: 6px;
			z-index: -1;
			display: block;
			min-height: 154px;
		}
	}
	span.typed-cursor {
		font-size: 36px;
		color: #f05764;
		display: inline;
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.kdl-auto-type {
		span#typed {
			font-size: 36px;
			line-height: auto;
			&:before {
				top: -50px;
				bottom: -50px;
				left: -50px;
				right: 50%;
				min-height: 154px;
			}
		}
	}



}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}