section.promo-grid {
	.full-banner {
		margin-bottom: 30px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		min-height: 350px;
		border-radius: 6px;
		
		a {
			display: block;
			width: 100%;
			height: 100%;
			padding: 40px 50px 30px 50px;
		} 
		.content-wrap {
			display: block;
			float: right;
		}
		.link-text {
			font-size: 22px;
			margin-bottom: 20px;
			display: block;
			line-height: 26px;
			color: #003737; 
			font-weight: 700;
		}
		span.fancy-link {
			display: block;
			//margin: -14px;
			margin-bottom: 18px;
			color: #003737;
			font-size: 12px; 
		}
		span.price {
			display: block;
			margin-left: 25%;
			background-color: #fff;
			line-height: 115px;
			width: 115px;
			height: 115px;
			border-radius: 50%;
			text-align: center;
			color: #003737;
			span {
				color: #003737;;
				font-weight: 700;
			}
		}
	}
	span.fancy-link {
		font-size: 12px; 
	}
	.color-banner {
		padding:  20px;
		font-size: 22px;
		font-weight: 700;
		border-radius: 6px;
		margin-bottom: 30px;
		a {
			width: 100%;
		    height: 100%;
		    display: block;
		} 
		&.mb-fix {
			margin-bottom: 0;
		}
		.gen-text {
			line-height: 26px;
			font-weight: 700;
			font-size: 22px;
		}
		.promo-text {
			font-weight: 300;
			font-size: 33px;
			margin-top: 10px;
		}
		&.medium-banner {
			min-height: auto;
		}
		&.small-banner {
			min-height: 160px;
		}
		&.yellow-banner {
			background-color: #f8efb1;
			color: #000;
		}
		&.turq-banner {
			background-size: auto;
			background-repeat: no-repeat;
			background-position: 75% 60%;
			.promo-text-small {
				font-size: 25px;
			    color: #68ad99;
			    font-weight: 700;
			    line-height: 1;
			    letter-spacing: 4px;
			    margin-left: 4px;
			}
			.promo-text-large {
				font-size: 44px;
				color: #68ad99;
				font-weight: 600;
				line-height: 1;
			}
			span.fancy-link {
				margin: 5px 0;
			}
		}
		&.blue-banner {
			background-size: auto;
			background-repeat: no-repeat;
			background-position: 15% 50%;
			.promo-text-large {
				font-size: 55px;
				color: #fff;
				font-weight: 300;
				line-height: 1;
				margin-top: 35px;
				position: relative;
				margin-bottom: 15px;
				sub {
					position: absolute;
				    bottom: 13px;
				    font-weight: 700;
				    left: 40px;
				    font-size: 14px;
				}
				sup {
					position: absolute;
				    top: 18px;
				    font-weight: 600;
				    font-size: 28px;
				}
			}
		}
		&.tall-banner {
			min-height: 400px; 
		}
		&.orange-banner {
			background-color: #fbd1ab;
			.promo-text-large {
				font-size: 55px;
				color: #f4582e;
				font-weight: 300;
				line-height: 1;
				position: relative;
				text-align: right;
				margin-right: 10px;
				&:after {
					content: 'PROMO';
					transform: rotate(-90deg);
					position: absolute;
				    right: -25px;
    				top: 20px;
					font-size: 10px;
					font-weight: 800;
					line-height: 1;
				}
				
			}
			span.fancy-link {
				padding: 0;
			}
		}
		&.bw-banner {
			background-size: auto;
			background-repeat: no-repeat;
			background-position: center;
			margin-bottom: 30px;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				bottom: 0;
				top: 50%;
				left: 0;
				right: 0;
				background: rgb(0,0,0);
				background: -webkit-linear-gradient(bottom, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
				background: -o-linear-gradient(bottom, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
				background: linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
				border-bottom-right-radius: 6px;
				border-bottom-left-radius: 6px;
			}
			a.full-link {
				position: absolute;
				width: 100%;
				height: 100%;
			}
			a.fancy-link {
				color: #fff;
				font-size: 12px;
			}
			.desc-wrap {
				position: absolute;
				bottom: 20px;
				left: 20px;
				right: 20px;
				span.gen-text {
					color: #fff;
					a {
						display: inline;
						width: auto;
						height: auto;
						color: #fff;
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
		&.disney-banner {
			background-color: #fff;
			padding: 0;
			border-radius: 6px;

			a {
				position: relative;
				overflow: hidden;
				padding: 20px;
				border-radius: 6px;
				&:before {
					content: '';
					width: 15px; 
					height: 15px;
					position: absolute;
					top: 48%;
					left: 48%;
					background-color: transparent;
					transition: all 0.3s ease-in;
					display: block;
					border-radius: 50%;

				}
				&:hover {
					&:before {
						transform: scale(25);
						transition: all 0.3s ease-in;
						background-color: #ffb6b7;
					}
				}
				span {
					position: relative;
					z-index: 10;
				}
				.promo-text-large {
				    color: #a73537;
				    text-align: center;
				}
			}
		}
		&.second-banner {
			background-color: #fff;
			padding-top: 10px;
    		padding-bottom: 10px;
    		span.fancy-link {
    			margin-top: 10px;
    		}
			.promo-text-large {
				margin-top: 4px;
				margin-right: 0;
				margin-bottom: 10px;
				&:after {
					content:'Noutati saptamanal';
					bottom: -10px;
					left: auto;
					transform: none;
					right: 0;
					text-transform: uppercase;
					bottom: -10px;
					top: auto;
					letter-spacing: .5px;
				}
			}
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.promo-grid {
		.full-banner {

		}
		.color-banner {
			margin-bottom: 0;
			&.medium-banner {
				min-height: 230px;
			}
			&.blue-banner {
				margin-top: 30px;
			}
			&.mb-fix {
				margin-bottom: 30px;
			}
		}
	}



}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}
