//Kidlet Landing Page
.kidlet-new	{
	font-family: 'Nunito', sans-serif;
	h1, h2, h3 {
		font-family: 'Nunito', sans-serif;
		color: #000;
	}
	h2 {
		font-weight: 900;
		font-size: 40px;
		color: #000;
	}
	.kdl-button { 
		padding: 1px 15px 9px 15px;
		background-color: #fadbdc;
		border-radius: 0;
		font-size: 24px;
		font-weight: 900;
		font-family: 'Nunito';
		color: #000; 
		border: 3px solid #000;
		position: relative;
		z-index: 50;
		margin-top: 5px;
		margin-left: 6px;
		&:before {
			content: '';
		    border: 3px solid #000;
		    bottom: 2px;
		    right: 2px;
		    top: -8px;
		    left: -8px;
		    position: absolute;
		    z-index: -1;
		    display: block;
		    background-color: #fadbdc;
		    transform: translate3d(0,0,0);
		    -webkit-transition: all 0.2s ease-out;
			-moz-transition: all 0.2s ease-out;
			-ms-transition: all 0.2s ease-out;
			-o-transition: all 0.2s ease-out;
			transition: all 0.2s ease-out;

		}
		&.blue {
			border-color: #88c6f2;
			&:before {
				border-color: #88c6f2;
			}
		}
		&.green {
			border-color: #8bd592;
			&:before {
				border-color: #8bd592;
			}
		}
		&.white {
			background-color: #fff !important;
			&:before {
				background-color: #fff;
			}
		}
		&:hover,&:focus,&:active {
			outline: none;
			&:before {
				transform: translate3d(-3px,-3px,0);
				-webkit-transition: all 0.2s ease-out;
				-moz-transition: all 0.2s ease-out;
				-ms-transition: all 0.2s ease-out;
				-o-transition: all 0.2s ease-out;
				transition: all 0.2s ease-out;
			}
		} 
	}
}

section.kidlet-main {
	margin-top: 0;
	font-family: 'Nunito', sans-serif;
	h1, h2, h3 {
		font-family: 'Nunito', sans-serif;
	}
	h1 {
		font-weight: 800;
		font-size: 45px;
		margin: 0;
		color: #000;
	}
	.kdl-button {
		padding: 1px 15px 9px 15px;
		background-color: #fadbdc;
		border-radius: 0;
		font-size: 24px;
		font-weight: 900;
		font-family: 'Nunito';
		color: #000;
		border: 3px solid #000;
		position: relative;
		z-index: 50;
		margin-top: 5px;
		&:before {
			content: '';
		    border: 3px solid #000;
		    bottom: 2px;
		    right: 2px;
		    top: -8px;
		    left: -8px;
		    position: absolute;
		    z-index: -1;
		    display: block;
		    background-color: #fadbdc;

		&:hover {
			&:before {
				transform: translate3d(-2px,-2px,0);
			}
		} 
		}
	}

	
	.kdl-logo {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	.bg-red {
		background-color: #fadbdc;
		display: block;
		height: 100%;
		padding-top: 80px;
		padding-bottom: 30px;
		padding-left: 30px;
		padding-right: 30px;
	}
	.main-cnt {
		position: relative;
		padding: 60px 30px 60px;
		&:after {
			background: url(../images/kidlet-main-img.png);
			content: '';
			position: absolute;
			width: 100%;
			max-width: 100%;
			height: 100%;
			display: block;
			left: 0;
			top: 0;
			background-repeat: no-repeat;
			background-size: cover;
			z-index: -1;
		}
		.bg {
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
		}
		.bg-multi {
			background-color: #ae7b7e;
			mix-blend-mode: multiply;
		}
		.bg-light {
			background-color: #fadbdc;
			mix-blend-mode: hard-light;
		}
		.main-inner {
			max-width: 100%;
			position: relative;
			z-index: 10;
		}
		p {
			font-size: 26px;
			font-style: italic;
			font-weight: 300;
			margin-bottom: 60px;
		}
	}
	
}
section.number-1 {
	.kdl-block-nr {
		display: block;
		margin-top: 12px;
	}
}
.white-bg {
	background-color: #fff;
}

section.kidlet-outlet {
	.img-holder {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			background-color: #f9efb2;
			top: 0;
			bottom: 30px;
			width: 8.333333%;
			left: -8.333333%;
		}
		&:after {
			content: '';
			position: absolute;
			background-color: #ebebeb;
			top: 11%;
			bottom: 0;
			width: 30px;
			right: -30px;
			z-index: 10;
		}
		span.outlet-icon {
			content: '';
			position: absolute;
			background: url(../images/kidlet-outlet-icon.png);
			height: 156px;
			width: 82px;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			right: -71px;
			top: 55%;
			margin-top: -78px;
			z-index: 15;
		}
	}
	.button-holder-1 {
		text-align: right;
		margin-top: 40px;
	}
	#collapseOutlet {
		-webkit-transition: all 0.2s ease-out;
		-moz-transition: all 0.2s ease-out;
		-ms-transition: all 0.2s ease-out;
		-o-transition: all 0.2s ease-out;
		transition: all 0.2s ease-out;
	}
	.inner-form-outlet {
		background-color: #c8def1;
		padding: 60px 15px;
	    background-color: #c8def1;
	    margin-top: 30px;
	    -webkit-transition: all 0.2s ease-out;
		-moz-transition: all 0.2s ease-out;
		-ms-transition: all 0.2s ease-out;
		-o-transition: all 0.2s ease-out;
		transition: all 0.2s ease-out;
	    input {
	    	box-shadow: none;
	    	border-radius: 0;
	    	font-size: 16px;
		    background-color: transparent;
		    border: 2px solid #88c6f2;
		    color: #fff;
		    height: 50px;
		    width: 100%;
		    &:focus {
		    	border-color: #729ebe;
		    }
	    }
	    .btn-holder {
	    	margin-top: 30px;
	    	button {
	    		border-radius: 0;
	    		height: 50px;
	    		line-height: 25px;
	    		border: none;
	    		font-size: 18px;
	    		background-color: #88c6f2;
	    		color: #fff;
	    		outline: none;
	    		&:hover,&:focus,&:active {
	    			background-color: darken(#88c6f2, 10%);
	    			outline: none;
	    		}
	    	}
	    }
	    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: #fff;
		  font-size: 16px;
		}
		::-moz-placeholder { /* Firefox 19+ */
		  color: #fff;
		  font-size: 16px;
		}
		:-ms-input-placeholder { /* IE 10+ */
		  color: #fff;
		  font-size: 16px;
		}
		:-moz-placeholder { /* Firefox 18- */
		  color: #fff;
		  font-size: 16px;
		}
	}
	form.form-inline {
		background-color: #c8def1;
	}
	.outlet-desc {
		h2 {
			margin-top: 30px;
			margin-bottom: 55px;
			line-height: 50px;
		}
		p {
			font-size: 18px;
			font-style: italic;
			font-weight: 300;
		}

	}
	.success-message-outlet {
		background-color: #c8def1;
		.icon-holder {
			display: block;
		    color: #fff;
		    font-size: 125px;
		}
		h3, p {
			color: #4e7a99;
		}
		.code-field-outlet {
		    margin-top: 15px;
		    width: 100%;
		    text-align: center;
		    padding: 8px 8px;
		    border: 2px solid #729ebe;
		    display: block;
		    font-size: 18px;
		    font-weight: 700;
		    color: #4e7a99;
		}
		.btn-clip-outlet {
			margin-top: 15px;
			background-color: #88c6f2;
			border-radius: 0;
			color: #729ebe;
			font-weight: 700;
			color: #4e7a99;
		}
	}
}
section.number-2 {
	margin: 60px 0;
}

section.kidlet-disney {
	.img-holder {
		margin-left: -47px;
	}
	.disney-desc {
		h2 {
			margin-top: 30px;
			margin-bottom: 55px;
			line-height: 50px;
		}
		p {
			font-size: 18px;
			font-style: italic;
			font-weight: 300;
			margin-left: 16.333333%;
		}
		a {
			margin-top: 40px;
		}
	}
}

section.kidlet-landing-second {
	font-family: 'Nunito';
	margin: 60px 0;
	position: relative;
	padding-top: 80px;
	.inactive-message {
		display: none;
	}
	&.inactive {
		.kidlet-newsletter-bg {
			position: relative;
			.promo-desc {
				visibility: hidden;
				opacity: 0;
			}
			.form-inline,
			.success-message {
				display: none;
			}
			.inactive-message {
				display: block;
				color: #000;
				padding: 30px 30px 60px;
				h3 {
					color: #000;
					margin-bottom: 20px;
				}
				.icon-holder {
					display: block;
					text-align: center;
					margin-bottom: 30px;
				}
				i {
					color: #ff7970;
					border-radius: 50%;
					font-size: 80px;
				}
				h4 {
					font-size: 16px;
				}
				a.kdl-submit {
					padding-left: 30px;
					padding-right: 30px;
					margin-left: 0;
					margin-top: 30px;
				}
			}
		}
	}
	h2, h3 {
		color: #000;
		font-weight: 900;
		font-style: normal;
		font-family: 'Nunito';
		margin-left: 15px;
		letter-spacing: 2px;
	}
	h2 {
		font-size: 40px;
		margin-top: 0;
	}
	h3 {
		font-size: 34px;
		margin-top: 0;
		margin-bottom: 30px;
	}
	.kidlet-newsletter-inner {
		position: relative;
		.img-holder {
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			display: block;
			position: absolute;
			top: 0;
			right: -5%;
			height: 100%;
		}
	}
	.inner-cnt {
		padding: 60px;
		color: #000;
		h2 {
			margin: 0;
			margin-bottom: 30px;
		}
		p {
			font-size: 18px;
		    font-style: italic;
		    font-weight: 300;
		}
	}
	.kidlet-newsletter-bg{
		padding: 60px 15px 0 30px;
		color: #fff;
		font-size: 16px;
		-webkit-transition: all 0.9s ease;
		-moz-transition: all 0.9s ease;
		-ms-transition: all 0.9s ease;
		-o-transition: all 0.9s ease;
		transition: all 0.9s ease;
		&:after {
			content: '';
			background-image: url(../images/kidlet-icon-3.png);
			background-position: center;
			background-repeat: no-repeat;
			position: absolute;
			top: -80px;
			left: auto;
			width: 98px;
    		height: 108px;
		}
		h3 {
			color: #fff;
			margin-bottom: 0;
			margin-left: 0;
		}
		span.bg {
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			-webkit-transition: all 0.9s ease;
			-moz-transition: all 0.9s ease;
			-ms-transition: all 0.9s ease;
			-o-transition: all 0.9s ease;
			transition: all 0.9s ease;
			left: -30px;
		}
		span.bg-soft {
			background-color: #b8e6be;
			//background: linear-gradient(45deg,#ff7b75 0%,#e086a7 100%);
			mix-blend-mode: hard-light;
			//box-shadow: 0px 8px 18px 2px rgba(0, 0, 0, 0.15);
		}
		span.bg-multi {
			background-color: #738f77;
			mix-blend-mode: multiply;
		}
	}
	ul.principles {
		margin-top: 15px;
		padding: 15px 15px 30px;
		list-style: none;
		background-color: rgba(0,0,0,.95);
		li {
			margin-top: 20px;
			word-wrap: break-word;
			span {
				font-size: 14px;
				font-style: italic;
			}
		}
	}
	#myKidletModal {
		.modal-content {
			border-radius: 0;
			border: none;
			padding-bottom: 30px;
			background-color: #8bd592;
			.modal-header {
				border-bottom: 0;
			}
		}
	}
	.news-form {
		background-color: #8bd592;
		padding-left: 0;
		padding-right: 30px;
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		-ms-transition: all 0.3s ease;
		-o-transition: all 0.3s ease;
		transition: all 0.3s ease;
		min-height: 300px;

		span.obs {
			display: block;
			font-size: 14px;
			font-weight: 500;
    		font-style: italic;
    		color: #fff;
		}
		h4 {
			font-size: 22px;
			margin-top: 0;
			margin-bottom: 20px;
			font-weight: 400;
			font-family: 'Nunito';
			&.you {
				color: #fff;
			}
			&.friend {
				color: #fff;
			}
		}
		.form-group {
			margin-bottom: 20px;
			position: relative;
			background-color: #8bd592;
			-webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-ms-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;
			transition: all 0.3s ease;
			padding-left: 0;
			&:after {
				content: '';
				-webkit-transition: all 0.3s ease;
				-moz-transition: all 0.3s ease;
				-ms-transition: all 0.3s ease;
				-o-transition: all 0.3s ease;
				transition: all 0.3s ease;
			}
			input.form-control {
				width: 100%;
				border-radius: 0;
				box-shadow: none;
				border: 2px solid #7ac181;
				height: 48px;
				background-color: #8bd592;
				&:focus {
					border: 2px solid #5ca070;
				}
				&.lilla {
					border: 2px solid #7ac181;
					&:focus {
						border: 2px solid #5ca070;
					}
				}
			}
			&.mail-ok {
				&:after {
					content: '\f00c';
					width: 48px;
					height: 48px;
					right: -93px;
					background-color: #fff;
					position: absolute;
					font-family: 'FontAwesome';
					color: #5ca070;
					text-align: center;
					line-height: 48px;
					font-size: 20px;
					top: 0;
					box-shadow: 0px 8px 18px 2px rgba(0, 0, 0, 0.15);
					-moz-transform: translateX(-48px);
					-webkit-transform: translateX(-48px);
					-o-transform: translateX(-48px);
					-ms-transform: translateX(-48px);
					transform: translateX(-48px);
					-webkit-transition: all 0.3s ease;
					-moz-transition: all 0.3s ease;
					-ms-transition: all 0.3s ease;
					-o-transition: all 0.3s ease;
					transition: all 0.3s ease;
				} 
			}
			&.mail-invalid {
				&:after {
					content: '\f00d';
					background-color: #fff;
					color: #f85563;
					width: 48px;
					height: 48px;
					right: -93px;
					position: absolute;
					font-family: 'FontAwesome';
					text-align: center;
					line-height: 48px;
					font-size: 20px;
					top: 0;
					box-shadow: 0px 8px 18px 2px rgba(0, 0, 0, 0.15);
					-moz-transform: translateX(-48px);
					-webkit-transform: translateX(-48px);
					-o-transform: translateX(-48px);
					-ms-transform: translateX(-48px);
					transform: translateX(-48px);
					-webkit-transition: all 0.3s ease;
					-moz-transition: all 0.3s ease;
					-ms-transition: all 0.3s ease;
					-o-transition: all 0.3s ease;
					transition: all 0.3s ease;
				}
				& + .invalid-message {
					display: inline-block;
				}
			}
			::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: #fff;
			  font-size: 14px;
			}
			::-moz-placeholder { /* Firefox 19+ */
			  color: #fff;
			  font-size: 14px;
			}
			:-ms-input-placeholder { /* IE 10+ */
			  color: #fff;
			  font-size: 14px;
			}
			:-moz-placeholder { /* Firefox 18- */
			  color: #fff;
			  font-size: 14px;
			}
		}
		.invalid-message {
			color: #f85563;
			font-style: italic;
			font-size: 14px;
			display: none;
			margin-top: -10px;
			margin-bottom: 20px;
		}
	}
	.success-message {
		color: #4d0925;
		padding: 60px 30px 100px;
		display: none;
		background-color: #fff;
		.icon-holder {
			display: block;
			text-align: center;
			margin-bottom: 30px;
		}
		i {
			color: #9de7a6;
			border-radius: 50%;
			border: 4px solid #b8e6be;
			padding: 20px 22px;
			font-size: 50px;
		}
		h3 {
			color: #000;
			text-align: center;
			margin-bottom: 20px;
		}
		h4 {
			text-align: center;
			color: #000;
			margin-bottom: 30px;
			font-size: 16px;
		}
		span.code-field {
			border: 3px solid #ff7b75;
			color: #ff7b75;
			font-size: 34px;
			font-weight: 900;
			display: block;
			text-align: center;
			margin-bottom: 20px;
			padding: 10px 0;

		}
		.btn {
			background: #fff;
		    font-weight: 800;
		    color: #1f030e;
		    padding: 5px 20px;
		    border-radius: 0;
		    border: 3px solid #4d0925;
		    -webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-ms-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;
			transition: all 0.3s ease;
		    &:hover,
		    &:active,
		    &:focus {
		    	border-color: #ff7b75;
		    	outline: none;
		    	color: #ff7b75;
		    	-webkit-transition: all 0.3s ease;
				-moz-transition: all 0.3s ease;
				-ms-transition: all 0.3s ease;
				-o-transition: all 0.3s ease;
				transition: all 0.3s ease;
		    }
		}
	}
	.button-holder {
		margin: 15px 0;
	}
	a.kdl-submit {
		margin-left: -35px;
		padding: 1px 80px 9px 80px;
		background-color: #fff;
		border-radius: 0;
		font-size: 24px;
		font-weight: 900;
		font-family: 'Nunito';
		text-transform: uppercase;
		color: #000;
		border: 3px solid #5ca070;
		position: relative;
		z-index: 50;
		margin-top: 5px;
		&:before {
			content: '';
		    border: 3px solid #5ca070;
		    bottom: 2px;
		    right: 2px;
		    top: -8px;
		    left: -8px;
		    position: absolute;
		    z-index: -1;
		    display: block;
		    background-color: #fff;

		}
	}
}

.modal-backdrop.in {
	opacity: .8;
}

// MEDIA QUERIES

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
	section.kidlet-main {
		margin-top: 60px;
		.kdl-logo {
			padding-top: 60px;
			padding-bottom: 0;
		}
		.bg-red {
		    height: 100%;
		    padding-top: 180px;
		    padding-bottom: 30px;
		    padding-left: 0;
			padding-right: 0;
		}
		h1 {
			font-weight: 800;
			font-size: 45px;
			margin: 0;
			margin-left: -10%;
			margin-right: 10%;
		}
		.main-cnt {
			padding: 60px 30px 170px;
			&:after {
				background-size: contain;
				left: -41.6666666667%;
			}
			.main-inner {
			    max-width: 50%;
			}
			.bg {
				bottom: 20%;
			}
		}
	}

	section.kidlet-landing-second {
		.news-form {
			padding-left: 30px;
			padding-right: 30px;
			.form-group {
				padding-left: 15px;
			}
		}
	}
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

