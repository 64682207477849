.faq-wrap {
	.faq-section-title {
		margin-top: 60px;
		h1 {
		    font-weight: 800;
		    font-size: 45px;
		    color: #000;
		    line-height: 38px;
		    margin-bottom: 48px;

		}
	}
	section {
		margin-bottom: 60px;
		h2 {
			font-size: 28px;
			font-weight: 800;
			color: #000;
			margin-bottom:20px;
		}
		.panel-group {
			.panel {
				border-radius: 3px;
				margin-bottom: 30px;
				border: 1px solid rgba(215, 234, 255, 0.7);
    			box-shadow: 0 15px 25px 0 rgba(5, 49, 93, 0.03);
				.panel-heading { 
					h4 {
						margin: 0;
					}
					a {
						padding: 22px 90px 22px 30px; 
						background-color: #f5faff; 
						display: block;
						font-weight: 600;
						font-size:20px;
						color: #4792da;  
						position: relative;
						&:after {
							content: "\e604";
						    font-family: simple-line-icons;
						    speak: none;
						    font-style: normal;
						    font-weight: 400;
						    font-variant: normal;
						    text-transform: none;
						    line-height: 1;
						    -webkit-font-smoothing: antialiased;
						    background-color: rgba(215, 234, 255, 0.7);
						    padding: 7px;
						    border-radius: 100%;
						    font-size: 14px;
						    float: right;
						    transition: all 0.3s ease;
						    position: absolute;
    						top: 50%;
    						right: 30px;
							margin-top: -14px;
						}
						&:not(.collapsed) {
							&:after {
								transform: rotate(180deg);
								transition: all 0.3s ease;
							}
						}
					}
				}
				.panel-body {
					padding: 25px 30px;
					border-top: 1px solid #d7e9ff;
				}
			}
		}
	}
}