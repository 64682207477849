//Product slider

section.product-slider {
	margin-top: 80px;
	margin-bottom: 40px;
	.product-slider-bgimg-hold {
		padding-top: 30px;
		padding-bottom: 0px;
		position: relative;
		.bg-img {
			position: absolute;
			top: 0;
			bottom: 4px;
			left: -15px;
			width: 85%;
			background-position-x: left;
    		background-position-y: top;
			background-repeat: no-repeat;
		}
	}

	.col-lg-3 {
		box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.05);
		background-color: #fff;
		z-index: 15;
	}
	.col-lg-9 {
		box-shadow: 0 15px 30px 0 rgba(0,0,0,.1);
		background-color: #fff;
		z-index: 5;
	}
	.product-slider-wrap {

	}
	.prod-slider-desc {
		padding: 30px;
		position: relative;
		z-index: 10; 
		height: 100%;
		h2 {
			font-size: 24px;
			font-weight: 800;
			margin-bottom: 15px;
		}
		p {
			font-weight: 600;
			margin-bottom: 10px;
			font-size: 14px;
		} 
		a {
			font-weight: 500;
			font-style: italic;
			text-decoration: underline;
			color: #4792da;
			margin-bottom: 10px;
			display: block;

		}
		.prod-slider-nav {
			position: absolute;
			bottom: -1px;
			right: 0;
			background-color: #000;
			z-index: 15;
			.owl-prev,
			.owl-next {
				display: inline-block;
				padding: 5px 12px;
				color: #fff;
				background-color: #000;
				float: left;
				cursor: pointer;
				i {
					position: relative;
					top: 2px;
				}
			}
			.owl-prev {
				background-color: #252525;
			}
		}
	}
	.product-slider-inner {
		position: relative;
		background-color: #fff;
		z-index: 5;
		margin-top: 15px;
	}	
	.product-slider-cnt {
		padding-bottom: 34px;
		pading-top: 15px;
		.img-holder {
			margin-bottom: 20px;
		}
		.prod-item {
			padding: 28px;
			background-color: #fff;
			a {
				display: block;
				color: #000;
				font-size: 12px;
				font-weight: 500;
				.brand { 
					font-weight: 600;
					letter-spacing: .5px;
					text-transform: uppercase;
				}
				.discount {
					margin-right: 10px;
				    color: #cbcbcb;
				    text-decoration: line-through;
				}
				.price {
					font-weight: 700;
				}
				.name {
					margin-bottom: 10px;
				}
			}
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.product-slider {
		margin-top: 100px;
		margin-bottom: 120px;
		.product-slider-bgimg-hold {
			padding-top: 100px;
			padding-bottom: 80px;
			position: relative;
			.bg-img {
				position: absolute;
				top: 0;
				bottom: 4px;
				left: -30px;
				width: 37%;
				background-position-x: left;
	    		background-position-y: top;
				box-shadow: 0px 10px 30px 0 rgba(0,0,0,.10);
			}
		}
		.col-lg-3 {
			box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.15);

		}
		.col-lg-9 {
			box-shadow: 0 15px 30px 0 rgba(0,0,0,.1);
			background-color: #fff;
		}
		.prod-slider-desc {
			padding: 30px;
			position: relative;
			z-index: 10;
			h2 {
				font-size: 28px;
				font-weight: 800;
				margin-bottom: 40px;
			}
			p {
				font-weight: 600;
				margin-bottom: 30px;
			}
			a {
				font-weight: 500;
				font-style: italic;
				text-decoration: underline;
				color: #4792da;
				

			}
			.prod-slider-nav {
				position: absolute;
				bottom: -1px;
				right: -40px;
				background-color: #000;
				z-index: 15;
				.owl-prev,
				.owl-next {
					display: inline-block;
					padding: 5px 12px;
					color: #fff;
					background-color: #000;
					float: left;
					cursor: pointer;
					i {
						position: relative;
						top: 2px;
					}
				}
				.owl-prev {
					background-color: #252525;
				}
			}
		}
		.product-slider-inner {
			position: relative;
			background-color: #fff;
			z-index: 5;
			margin-top: 15px;
		}	
		.product-slider-cnt {
			padding-bottom: 34px;
			pading-top: 15px;
			.img-holder {
				margin-bottom: 20px;
			}
			.prod-item {
				padding: 28px;
			}
		}
	}



}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}



