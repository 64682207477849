section.benefits-percent {
	.kdl-divider {
		margin: 15px 0;
	}
	.item	{
		position: relative;
	}
	p {
		font-weight: 800;
		line-height: 26px;
		width: 80%;
		padding: 30px 0 45px 0;
		z-index: 10;
		position: relative;
		margin-bottom: 0; 
		font-size: 16px;
	}
	span.badge {
		position: absolute;
		box-shadow: 0px 5px 45px 0px rgba(0, 0, 0, 0.1);
		border-radius: 15px;
		background-color: #fff;
		top: 0;
		right: 0;
		padding: 10px 20px 15px;
		text-align: center;
		color: #e84b58;
		z-index: 15;
		span.lnr {
			display: block;
			font-size: 45px;
		}
		span.txt {
			display: block;
			font-size: 12px;
			font-weight: 800;
			margin-top: 10px;
		}
	}
	span.percent {
		position: absolute;
		z-index: 5;
		color: #efefef;
		font-size: 100px;
		line-height: 1;
		font-weight: 900;
		bottom: 0;
		text-align: center;
		left: 0;
    	right: 0;
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.benefits-percent {
		p {
			font-weight: 800;
			line-height: 30px;
			padding: 30px 0 65px 0;
			margin-bottom: 0; 
			font-size: 17px;
		}

		span.percent {
			font-size: 120px;
			bottom: 0;
		}
		.kdl-divider {
			margin: 20px 0;
		}
	}



}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}