//Kidlet Slider

section.slider {
	.count-haine-noi {
		position: relative;
		height: 100%;
		.counter {
			position: relative;
			background-color: #cce8cd;
			z-index: 10; 
			height: 100px;	
			text-align: center;
			margin-top: 30px;
			.count-wrap {
				display: inline-block;
				float: left;
				text-align: center;
				padding: 0 20px;
				position: relative;
				&.hr,
				&.min {
					&:after {
						content: ':';
						font-family: 'Nunito';
						font-weight: 700;
						font-size: 24px;
						position: absolute;
						right: 0;
						top: -2px;
						color: #000;
					}
				}
				
			}
			.count-val {
				color: #000;
				font-family: 'Nunito';
				font-weight: 700;
				font-size: 24px;
				position: relative;
			}
			.count-text {
				color: #000;
				font-weight: 700;
				font-size: 14px;
			}
			#haine-noi-count {
				position: relative;
			    height: auto;
			    display: inline-block;
			    margin-top: 24px;
			}
		}
	}
}


section.slider {
	margin-top: 30px;
	margin-bottom: 80px; 
	.overflow-hidden {
		overflow: hidden !important;
	}
	.container {
		//width: auto; 
		.owl-wrapper  {
			min-height: 480px;
			box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.15);
			background-color: #fff;
			background-size: cover;
			background-position: center;

		}
		.owl-item-kdl {
			background-size: cover;
			background-position: center;
			.col {
				overflow: hidden;
			}
			nav.slider-nav {
				padding: 15px 30px 30px 80px;
				.owl-prev,
				.owl-next {
					display: inline-block; 
					span {
						border-radius: 100%;
						width: 35px;
						height: 35px;
						line-height: 40px;
						border: 2px solid #efefef;
						display: inline-block;
						transition: all 0.3s ease;
						cursor: pointer;
						i {
							position: relative;
							left: 8px;
							font-size: 12px;
							top: -4px;
						}
					}
					&:hover {
						span {
							border-color: #8bc5eb;
							transition: all 0.3s ease;
							i {
								color: #8bc5eb;
							}
						}
					}
				}
				.owl-next {
					margin-left: 10px;
					span {
						i {
							left: 10px;
						}
					}
				}
			}

		}
		.slider-wrap {
			
		}
		.slider-top {
			background-color: hsla(0,0%,100%,.95);
			padding: 20px 15px;
			nav.slider-nav {
				padding: 0;
			}
		}
		.slider-current {
			padding: 0; 
			margin-top: 5px;
			span {
				display: inline-block;
			}
			.current,
			.next {
				font-weight: 700;
				font-size: 14px;
			}
			.line{
				width: 75px;
				margin: 0 5px;
				position: relative;
				&:after {
					content: '';
					height: 2px;
					width: 100%;
					position: absolute;
					left: 0;
					right: 0;
					background-color: #000;
					bottom: 0;
				}
			}
			.next {

			}
		}
		.heading {
			padding: 15px 30px 30px 30px;
			background-color: rgba(255,255,255, .8);
			a {
				display: block;
				color: #000;
				&:hover {
					h1 {
						color: #599bdd
					}
				}
			}
			h1 {
				font-weight: 800;
				font-size: 24px;
				color: #000;
				margin-bottom: 15px;
			} 
			p {
				font-size: 14px;
				font-weight: 600;

			}
		}
		.img-wrap {
			min-height: 480px;
			background-size: cover;
			background-position: center;
			height: 100%;

		}
		.small-title {
			font-weight: 700;
			font-size: 12px;
			display: block;	
			text-transform: uppercase;
		}
		.promo-box-top,
		.promo-box-bottom {
			background-color: #fff;
			.row {
				height: 100%;
			}
			.img-holder {
				height: 100%;
				background-position: right;
			}
			a {
				color: #000;
				&:hover {
					color: #599bdd;
					h2 span.arrow .arrow-line {
						background-color: #599bdd;
					}
					h2 span.arrow {
						transform: translateX(4px) translateY(0px);
						transition: all 0.3s ease;
					}
				}
			}
			h2 {
				font-weight: 800;
				font-size: 24px;
				margin-top: 30px;
				width: 80%;
				display: block;
				span.arrow {
					position: relative;
					display: inline-block;
					margin-left: 12px;
					top: 1px;
					transition: all 0.3s ease;
					&.large {
						width: 60px;
					}
					&.small {
						width: 30px;
					}
					&.blue-arrow {
						i {
							color: #599bdd;
						}
						.arrow-line {
							background-color: #599bdd;
							margin-top: 0px;
						}
					}
					i {
						float: right;
					    position: relative;
					    left: 4px;
					    font-size: 17px;
					}
					.arrow-line {
						position: absolute;
						left: 0;
						right: 0;
						height: 1px;
						width: 100%;
						background-color: #000;
						top: 50%;
						margin-top: -1px;
					}

				}
			}
		}
	
		.promo-box-top {
			padding: 30px;
			height: 42%;
			background-color: #f3f3f3;
			h2 {
				width: 50%;
			}

		}
		.promo-box-bottom { 
			padding: 30px;
			height: 220px; 
			background-color: hsla(0,0%,100%,.9);
			margin: 130px 30px 70px 30px;
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.slider {
		margin-bottom: 120px;
		.container {
			.slider-top {
				padding: 15px 30px;
			}
			.owl-item-kdl  { 
				background-image: none !important;
				
				nav.slider-nav {
					.owl-prev,
					.owl-next {
						span {
							width: 40px;
							height: 40px;
							i {
								font-size: 14px;
								top: 0;
								
							}
						}
					}
				}
				
			}

			.heading {
				padding-left: 30px;
				padding-right: 30px;
				h1 {
					font-weight: 800;
					font-size: 34px;
					color: #000;
					line-height: 38px;
					margin-bottom: 48px;
				} 
				p {
					font-size: 16px;
					font-weight: 600;

				}
			}
			.promo-box-top,
			.promo-box-bottom {
				.img-holder {
					height: 100%;
					background-position: right;
					background-repeat: no-repeat;
				}
			}
			.promo-box-top {
				background-color: #f8f8f8;
				h2 {
					width: 50%;
				}

			}
			.promo-box-bottom {
				margin: 0;
				padding: 30px;
				height: 58%;
				h2 {
					width: 100%;
				}
			}
		}

	}
 
}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

	section.slider {
	
		.container {
			.slider-top {
				padding: 0;
			}
			.slider-current {
				padding: 72px 30px 55px 80px;
			}
			.heading {
				padding-left: 80px;
				padding-right: 30px;
				h1 {
					font-size: 36px;
					line-height: 38px;
					margin-bottom: 48px;
				} 
			}
		}

	}

}


@media (min-width: 1300px) {  

	section.slider {
		.container {
			width: 1300px;
		}
	}

}
