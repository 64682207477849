//Replanificare


#replanificare {
	span.label {
		font-size: 12px;
		font-weight: 700;
		color: #6e6e6e;
		margin-bottom: 5px;
	}
	span.value {
		font-size: 16px;
		font-weight: 700;
		color: #000;
	}
	.order-main-cnt {
		padding: 30px 15px 50px 15px;
		background-color: #fff;
		.order-desc-block {
			margin-bottom: 40px;
		}
	}
	.order-detail-cnt {
		padding: 0 30px;
	}
	.order-detail-top {
		a.download {
			display: inline-block;
		    margin-left: 5px;
		    width: 25px;
		    height: 25px;
		    background-color: #8fcd90;
		    text-align: center;
		    border-radius: 50%;
		    color: #000;
		    i {
		    	position: relative;
		    	top: 2px;
		    }
		}
		ul {
			padding-bottom: 10px;
    		border-bottom: 1px solid #e2e2e2;
    		margin-bottom: 10px;
    		li {
    			margin-right: 30px;
    		}
		}
	}
	.order-detail-bottom {
		position: relative;
		.navigation-slick {
			position: absolute;
			top: 50%;
			margin-top: -15px;
			width: 100%;
			span.slick-arrow {
				display: block;
			    width: 30px;
			    height: 34px;
			    background-color: #000;
			    color: #fff;
			    text-align: center;
			    font-size: 16px;
			    left: -30px;
			    position: absolute;
			    right: auto;
			    cursor: pointer;
			    i {
			    	position: relative;
			    	top: 7px;
			    }
				&.left {

				}
				&.right {
					right: -30px;
				    position: absolute;
				    left: auto;
				}
			}
		}
		h4 {
			font-size: 18px;
			font-weight: 800;
			color: #000;
			margin-bottom: 0;
		}
	}
	.btn {
		background-color: #badfbd;
	    padding: 3px 18px;
	    font-weight: 700;
	    border-radius: 14px;
	    cursor: pointer;
	    i {
	    	position: relative;
	    	margin-left: 5px;
	    	top: 2px;
	    }
	    &.remove {
	    	color: #bb2832;
	    	background-color: #f7c5c6;
	    	margin-bottom: 10px;
	    }
	    &.replan {
	    	color: #194a6c;
	    	background-color: #acd1ef;
	    	margin-bottom: 10px;
	    }
	    &.finalize {
	    	color: #085a20;
	    	background-color: #90cc90;
	    	margin: 5px 0;
	    	width: 100%;
	    	padding: 7px 18px;
    		border-radius: 18px;
	    }
	}
	.order-detail-products {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left:0;
			width: 25.5%;
    		z-index: 1;
    		background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0.75) 75%, rgba(255,255,255,0.75) 100%);
			background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,0.75) 75%,rgba(255,255,255,0.75) 100%); 
			background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0.75) 75%,rgba(255,255,255,0.75) 100%); 
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#bfffffff',GradientType=1 ); 
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right:0;
			width: 26%;
			background: -moz-linear-gradient(left, rgba(255,255,255,0.75) 0%, rgba(255,255,255,0.75) 25%, rgba(255,255,255,1) 100%); 
			background: -webkit-linear-gradient(left, rgba(255,255,255,0.75) 0%,rgba(255,255,255,0.75) 25%,rgba(255,255,255,1) 100%); 
			background: linear-gradient(to right, rgba(255,255,255,0.75) 0%,rgba(255,255,255,0.75) 25%,rgba(255,255,255,1) 100%); 
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bfffffff', endColorstr='#ffffff',GradientType=1 );
		}
	}


	.order-item {
		max-width: 400px;
		margin-bottom: 30px;
		margin-top: 50px;
		padding: 20px 12px 30px 12px;
		position: relative;
		&.dimmed {
			&:after {
				content: '';
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: rgba(0,0,0,.78);
				position: absolute;
				
			}
		}
		.padding-fix {
			padding-left: 8px;
		}
		span.value {
			font-size: 14px;
			font-weight: 700;
			color: #000;
		}
		span.title {
			font-weight: 700;
		}
		span.d-block {
			margin-bottom: 3px;
		}
		

		img {
			width: 120px;
			height: 120px;
		}
		.bottom-buttons {
			margin-top: 30px;
			.btn-group {
				position: initial;
			
			}
			.btn {
				margin-bottom: 0;
				&.remove {
					border: 1px solid #bb2832;
					background-color: #fff;
					margin-right: 15px;
					margin-bottom: 0;
					&:hover {
						background-color: #f7c5c6;
					}
				}
				&.replan {
					border: 1px solid #194a6c;
					background-color: #fff;
					margin-bottom: 0;
					&:hover {
						background-color: #acd1ef;
					}
				}
			}
			.dropup {
				position: initial;
				&.show {
					> a.btn {
						z-index: 1000;
					}
				}
			}
			.dropdown-menu {
				top: -50px;
				left: 30px;
				right: 30px;
				bottom: 60px;
				box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.9);
				border-radius: 5px;
				border-color: transparent;
				padding: 15px;
				label {
					display: block;
					margin-bottom: 10px;
				}
			}
		}
	}
	.order-main-cnt {

		.dropdown-menu {
			border-radius: 5px;
			border-color: transparent;
			padding: 15px;
			box-shadow: 0 10px 30px 0 rgba(0,0,0,.15);
			width: 260%;
			label {
				display: block;
				margin-bottom: 10px;
			}
		}

	}
	.replan-wrap {
		.dropdown-menu { 
			.form-header {
				padding-left: 24px;
				border-bottom: 1px solid #e2e2e2;
			}
			.form-selection {
				padding: 10px 0;
				border-bottom: 1px solid #e2e2e2;
				label {
					font-size: 14px;
					font-weight: 700;
					color: #000;
					margin-right: 0;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.form-button {
				margin-top: 10px;
				text-align: right;
				a.btn.replan {
					color: #194a6c;
				    background-color: #acd1ef;
				    &:hover {
				    	background-color: darken(#acd1ef,10%);
				    }
				}
			}
		}
	}
	.remove-wrap {
		.dropdown-menu { 
			.form-header {
				border-bottom: 1px solid #e2e2e2;
				padding-bottom: 10px;
    			margin-bottom: 15px;
				span {
					font-size: 16px;
				    font-weight: 700;
				    color: #000;
				}
			}
			.form-button {
				margin-top: 10px;
				a.btn.remove {
					color: #bb2832;
			    	background-color: #f7c5c6;
			    	margin-right: 0;
			    	&:hover {
			    		background-color: darken(#f7c5c6,10%);
			    	}
				}
			}
		}
	}
	.slick-current {
		.order-item {
			box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
		}
	}
}


