//Review Section

section.review {
	margin-bottom: 0;
	padding-bottom: 50px; 
	.review-wrap {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.review-title {
		padding: 50px 30px 30px 30px;
		h2 {
			font-size: 24px;
			color: #000;
			font-weight: 800;
		}
	}
	.slider-wrap {
		background-color: #fff;
		box-shadow: 0px 40px 70px 0px rgba(0, 0, 0, 0.15);
		padding: 50px 30px;
		margin: 0 30px;
			.slider-item {
				background-color: #fff;
			}

			.owl-theme{
				height: 100%;
				.slider-dots {
					text-align: center;
				}
				.owl-dots {
					position: relative;
					top: 0;
					.owl-dot {
						display: inline-block;
						margin: 0 5px;
						span {
							display: inline-block;
							width: 14px;
    						height: 14px;
    						margin: 0;
    						border: 1px solid #747474;;
    						background-color: #fff;

						}
						&.active {
							span {
    							background-color: #f7ea9a;
    							border-color: #f7ea9a;  
    						}
    					}
					} 
				}
			}
	}
	.review-text {
		font-size: 18px;
		font-weight: 300;
    	font-style: italic;
	}
	.divider {
		display: block;
		width: 50px;
		height: 2px;
		text-aling: center;
		margin: 20px auto;
		background-color: grey;
	}
	.person {
		margin-top: 30px;
		color: #7fbcb9;
		font-weight: 800;
		font-size: 16px;
		text-transform: uppercase;
	}
	.occupation {
		margin-top: 10px; 
		color: #747474;
		font-size: 14px;
		font-weight: 700;
	}
	.slider-nav-parent {
		position: relative;
	}
	.slider-nav-wrap {
		position: absolute;
		top: 50%;
		width: 100%;
		left: 0;
		right: 0;
		.owl-prev,
		.owl-next {
			display: inline-block;
			font-size: 18px;
			font-weight: bold;
			position: absolute;
			cursor: pointer;
			color: #000;
		}
		.owl-next {
			right: 50px;
		}
		.owl-prev {
			left: 50px;
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}
 
// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.review {
		margin-bottom: 80px;
		.review-title {
			padding: 30px 50px;
			h2 {
				font-size: 28px;
			}
		}
		.slider-wrap {
			margin-bottom: -50px;
			padding: 50px 0;
				.owl-theme{
					.owl-dots {
						//top: 40%;
						.owl-dot {
							display: block;
						} 
					}
				}
		}
		.review-text {
			font-size: 20px;
		}
	}



}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}


