section.kidlet-newsletter {
	margin: 60px 0;
	.newsl-wrap {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		min-height: 200px;
		padding: 30px;
	}
	h2 {
		font-size: 28px;
		color: #000;
		font-weight: 800;
		margin-bottom: 15px;
	}
	p {
		font-weight: 500;
		color: #000;
		margin-bottom: 25px;
	}
	form.newsletter-form {
		button.btn {
			background-color: #000;
			color: #fff;
			font-size: 22px;
			font-weight: 800;
			text-align: center;
			box-shadow: 0px 8px 18px 2px rgba(0, 0, 0, 0.15);
			padding: 0.7rem .75rem;
			cursor: pointer;
			position: relative;
		}
		input {
			display: block;
			width: 100%;
			box-shadow: 0px 8px 18px 2px rgba(0, 0, 0, 0.15);
			border: none;
			margin-bottom: 20px;
			padding: 0.9rem .75rem;
		}
		input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: #656565;
		  	font-size: 16px;
		  	font-weight: 500;
		  	font-family: 'Nunito';
		}

		input:-moz-placeholder { /* Firefox 18- */
			color: #656565;
		  	font-size: 16px;
		  	font-weight: 500;
		  	font-family: 'Nunito';
		}

		input::-moz-placeholder { /* Firefox 19+ */
			color: #656565;
		  	font-size: 16px;
		  	font-weight: 500;
		  	font-family: 'Nunito';
		}

		input:-ms-input-placeholder { /* IE */
			color: #656565;
		  	font-size: 16px;
		  	font-weight: 500;
		  	font-family: 'Nunito';
		}

		input::-ms-input-placeholder { /* IE Edge */
			color: #656565;
		  	font-size: 16px;
		  	font-weight: 500;
		  	font-family: 'Nunito';
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.kidlet-newsletter {
		margin-bottom: 110px;
	}

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}
