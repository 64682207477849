// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


// Start with assigning color names to specific hex values.
$white:  #fff !default;
$black:  #000 !default;
$red:    #d9534f !default;
$orange: #f0ad4e !default;
$yellow: #ffd500 !default;
$green:  #5cb85c !default;
$blue:   #0275d8 !default;
$teal:   #5bc0de !default;
$pink:   #ff5b77 !default;
$purple: #613d7c !default;

// Create grayscale
$gray-dark:                 #292b2c !default;
$gray:                      #464a4c !default;
$gray-light:                #636c72 !default;
$gray-lighter:              #eceeef !default;
$gray-lightest:             #f7f7f9 !default;

// Reassign color vars to semantic color scheme
$brand-primary:             $blue !default;
$brand-success:             $green !default;
$brand-info:                $teal !default;
$brand-warning:             $orange !default;
$brand-danger:              $red !default;
$brand-inverse:             $gray-dark !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer:   1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: ($spacer-x * .25),
    y: ($spacer-y * .25)
  ),
  2: (
    x: ($spacer-x * .5),
    y: ($spacer-y * .5)
  ),
  3: (
    x: $spacer-x,
    y: $spacer-y
  ),
  4: (
    x: ($spacer-x * 1.5),
    y: ($spacer-y * 1.5)
  ),
  5: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  )
) !default;
$border-width: 1px !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
) !default;



// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            false !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;

// Links
//
// Style anchor elements.

$link-color:            $brand-primary !default;
$link-decoration:       none !default;
$link-hover-color:      darken($link-color, 15%) !default;
$link-hover-decoration: none !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Nunito", -apple-system, system-ui, BlinkMacSystemFont;
$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:   1.25rem !default;
$font-size-sm:   .875rem !default;
$font-size-xs:   .75rem !default;

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$font-size-h1: 2.5rem !default;
$font-size-h2: 2rem !default;
$font-size-h3: 1.75rem !default;
$font-size-h4: 1.5rem !default;
$font-size-h5: 1.25rem !default;
$font-size-h6: 1rem !default;

$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family:   inherit !default;
$headings-font-weight:   500 !default;
$headings-line-height:   1.1 !default;
$headings-color:         inherit !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight:     300 !default;
$display2-weight:     300 !default;
$display3-weight:     300 !default;
$display4-weight:     300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size:   1.25rem !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-light !default;

$abbr-border-color: $gray-light !default;

$blockquote-small-color:  $gray-light !default;
$blockquote-font-size:    ($font-size-base * 1.25) !default;
$blockquote-border-color: $gray-lighter !default;
$blockquote-border-width: .25rem !default;

$hr-border-color: rgba($black,.1) !default;
$hr-border-width: $border-width !default;

$mark-padding: .2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow:         inset 0 -.1rem 0 rgba($black,.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 5px !default;