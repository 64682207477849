footer.kidlet-footer {
	margin-top: 80px;
	.container {

	}
	.card {
		padding: 0 15px;
		border: none;
		border-radius: 6px;

		h4 {
			padding: 15px;
			color: #fff;
			margin: 0 -15px;
			transition: all .3s ease;
		    border-radius: 6px;
		    background-color: #1d4a68;
		    cursor: pointer;
		    &:after {
		    	content: "\e887";
		    	@extend .lnr;
		    	position: absolute;
			    right: 15px;
			    display: inline-block;
			    transition: all .3s ease;
			    transform-origin: center;
			    transform: rotate(0deg);
		    }
			&:not(.collapsed) {
				background-color: red;
				margin-bottom: 15px;
				border-bottom-left-radius: 0;
		    	border-bottom-right-radius: 0;
		    	background-color: #6ab6e7;
		    	&:after {
					transition: all .3s ease;
					transform: rotate(180deg);
					transform-origin: center;
		    	}
			}
			
		}
		.dont-collapse-sm {
			transition: all .3s ease;
			
		}
	}
	.footer-copyright {
		font-size: 12px;
	    font-weight: 600;
	    color: #143e66;
	    padding: 20px 30px 0 30px; 
	    margin-bottom: 30px;
	    margin-top: -50px;
	    background-color: #fff;
	    box-shadow: 0 -15px 30px 0px rgba(0,104,189,.08);
	}
	.footer-copyright-wrap {
		padding: 15px;
	}
	.footer-wrap {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		min-height: 490px; 
		color: #143e66;
		padding: 30px 15px 60px;
		background-image: url(../images/kdl-bg-footer-mob.jpg);
		h4 {
			color: #fff;
			font-size: 18px;
			margin-bottom: 0;
			font-weight: 600;
		}
		h5 {
			font-size: 16px;
			margin-bottom: 10px;
			font-weight: 600;
		}
		p {
			font-size: 14px;
			color: #143e66;
			font-weight: 600;  
		}
		.row {
			&.no-gutters {
				> .col,
				> [class*="col-"] {
					margin-bottom: 30px;
				}
			}
		}
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			margin-bottom: 14px;
			a {
				color: #143e66;
				font-size: 14px;
				font-weight: 600;
				display: block;
				&:hover {
					color: #f23142;
				}
				i {
					margin-right: 6px;
					&.icon-arrow-right {
						font-size: 11px;
						position: relative;
					}
				}
			}
		}
	}
	.text-widget-wrap {
		padding: 0;
	}
	.text-widget-item {
		margin-bottom: 20px;
	}
	.newsletter-wrap {
		background-color: #fff;
		form.newsletter-form {
			button.btn {
				background-color: #8bc5eb;
				color: #fff;
				font-size: 22px;
				font-weight: 800;
				text-align: center;
				padding: 0.7rem .75rem;
				cursor: pointer;
				position: relative;
				font-family: 'Nunito';
				margin-top: 10px;
				margin-bottom: 20px;
				&:hover {
					background-color: darken(#8bc5eb,5%);
				}
			}
			input {
				display: block;
				width: 100%;
				box-shadow: 0px 10px 30px 0px rgba(0, 36, 71, 0.05);
				border: none;
				margin-bottom: 20px;
				padding: 0.9rem .75rem;
				color: #143e66;
			  	font-size: 16px;
			  	font-weight: 600;
			  	font-family: 'Nunito';
			}
			input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: #143e66;
			  	font-size: 16px;
			  	font-weight: 600;
			  	font-family: 'Nunito';
			}

			input:-moz-placeholder { /* Firefox 18- */
				color: #143e66;
			  	font-size: 16px;
			  	font-weight: 600;
			  	font-family: 'Nunito';
			}

			input::-moz-placeholder { /* Firefox 19+ */
				color: #143e66;
			  	font-size: 16px;
			  	font-weight: 600;
			  	font-family: 'Nunito';
			}

			input:-ms-input-placeholder { /* IE */
				color: #143e66;
			  	font-size: 16px;
			  	font-weight: 600;
			  	font-family: 'Nunito';
			}

			input::-ms-input-placeholder { /* IE Edge */
				color: #143e66;
			  	font-size: 16px;
			  	font-weight: 600;
			  	font-family: 'Nunito';
			}
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  
	
	footer.kidlet-footer {
		.newsletter-wrap {
			//margin-top: -30px;
		} 
		.footer-copyright-wrap {
			padding: 15px;
		}
	}

}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  

	footer.kidlet-footer {
		.card {
			background-color: transparent;
    		padding: 0;
			.collapse.dont-collapse-sm,
			.collapsed.dont-collapse-sm,
			.collapsing.dont-collapse-sm,
			.show.dont-collapse-sm {
			    display: block;
			    height: auto !important;
			    visibility: visible;
			}
			h4:not(.collapsed),
			h4.collapsed,
			h4.collapse,
			h4 {
				color: #1d4a68;
				background-color: transparent;
				margin: 0 0 15px 0;
				padding: 0;
				&:after {
					display: none;
				}
			}
		}
		margin-top: 150px;
		.newsletter-wrap {
			margin-top: 0;
			padding: 30px 15px 40px;
			box-shadow: 0px 15px 25px 0px rgba(0, 104, 189, 0.08);
			button.btn {
				margin-bottom: 0;
			}
		}
		.text-widget-wrap {
			padding: 0;
		}
		.footer-wrap {
			padding: 30px 30px 60px 30px;
		}
	}


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	footer.kidlet-footer {
		.footer-wrap {
			padding: 30px 15px;
			background-image: url(../images/kdl-footer-bg-2.jpg);
			.card {
				h4 {
					margin-bottom: 28px;
				}
				h4[data-target="#collapseFooter2"],
				h4[data-target="#collapseFooter4"] {
					margin-left: 15px;
				}
			}
			.text-widget-wrap {
				padding: 0 15px;
			}
			.newsletter-wrap {
				padding: 15px 30px 60px 30px;
				form.newsletter-form {
					button.btn {
						margin-bottom: 0;
					}
				}
			}
		}
	}


}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

	footer.kidlet-footer {

		.container {
			width: 1300px;
		}
		.footer-wrap {
			padding: 50px 65px;
		}
		.no-gutters {
		    margin-right: -15px;
    		margin-left: -15px;
			> .col,
			> [class*="col-"] {
				padding-right: 15px;
				padding-left: 15px;
			}
		}
	}

}





