html {
	* {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}
//Header Mobile
#kidlet-mobile-cnt {
	//margin-top: 100px;
	background-color: #fff;
	color: #000;
	.mm-panel {
		.mm-navbar {
			a.mm-btn.mm-prev {

				&:after {
					content: 'inapoi la';
					text-align: left;
					position: absolute;
					right: -40px;
					font-size: 12px;
				}
			}
		}
	}
}

#kidlet-mobile-header {
	.navbar {
		padding: 15px 0;
		border-bottom: 1px solid #e6e6e6;
		.navbar-top {
			margin-bottom: 15px;
		}
		.navbar-bottom {
			.no-padding {
				padding: 0 !important;
			}
			span {
				a {
					color: #000;
					font-size: 14px;
					display: inline-block;
					&:hover {
						i.icon {
							-moz-transform: translateX(-2px);
							-webkit-transform: translateX(-2px);
							-o-transform: translateX(-2px);
							-ms-transform: translateX(-2px);
							transform: translateX(-2px);
							-webkit-transition: all 0.2s ease-out;
							-moz-transition: all 0.2s ease-out;
							-ms-transition: all 0.2s ease-out;
							-o-transition: all 0.2s ease-out;
							transition: all 0.2s ease-out;
						}
					}
				}
				i {
					font-size: 16px;
					position: relative;
					top: 2px;
					margin-right: 10px;
					position: relative;
					display: inline-block;
					-webkit-transition: all 0.2s ease-out;
					-moz-transition: all 0.2s ease-out;
					-ms-transition: all 0.2s ease-out;
					-o-transition: all 0.2s ease-out;
					transition: all 0.2s ease-out;
					&:after {
						content: '';
						position: absolute;
						width: 19px;
						height: 19px;
						border-radius: 50%;
						left: 0;
		    			top: 0;
		    			z-index: -1;
					}
					&.icon-magnifier {
						&:after {
							background-color: #dce9f5;
							left: 2px;
   							top: 1px;
						}
					}
					&.icon-user {
						&:after {
							background-color: #c8e8e8;
							left: -5px;
    						top: 2px;
						}
					} 
					&.icon-bag {
						&:after {
							background-color: #f8efb1;
							left: 2px;
   							top: 1px;
						}
					}
				}
			}
		}
	}
	.icon-holder {
		margin-top: 10px;
		a {
			position: relative;
			color: #000;
			&:before {
				content: '';
				position: absolute;
				width: 19px;
				height: 19px;
				border-radius: 50%;
				background-color: #cce7cd;
				left: 3px;
    			top: 2px;
			}
		}
	}
}

//Desktop-Header
#kidlet-mobile-header {
	.user-section, 
	.minicart-section {
		display: inline-block;
		margin: 0;
		.dropdown {
			a.btn {
				font-size: 14px;
				font-weight: 500;
				color: #000;
				padding-left: 0;
				padding-right: 0;
				padding-top: 0;
				padding-bottom: 0;
				position: relative;
				border: none;
				line-height: normal; 
				&:hover,
				&:focus {
					outline: none;
					border-color: transparent;
					box-shadow: none;
					color: #000;
				}
				&:after {
					display: none;
				}
			}
			.item-nr {
				position: absolute;
			    left: 8px;
			    top: 18px;
			    font-size: 12px;
			    font-weight: 600;
			    color: #245a5a;
				z-index: 10;
				transition: all 0.2s ease-out;
			}
			i.icon {
				font-size: 16px;
				position: relative;
				top: 2px;
				margin-right: 10px;
				transition: all 0.2s ease-out;
				display: inline-block;
				&:after {
					content: '';
					position: absolute;
					width: 19px;
					height: 19px;
					border-radius: 50%;
					left: -3px;
	    			top: 0;
	    			z-index: -1;
	    			background-color: #c8e8e8;
				}
				&.icon-user {
					&:after {
						background-color: #c8e8e8;
						left: -5px;
    					top: 0px;
					}
				}
			}
			i.icon-arrow-down {
				font-size: 10px;
				margin-left: 8px;
				display: inline-block;
				transition: all 0.2s ease-out;
			}
			&.show {
				i.icon-arrow-down {
					transform: rotate(180deg);
					transition: all 0.2s ease-out;
				}
				i.icon {
					transform: translateX(-4px);
					transition: all 0.2s ease-out;
				}
				.item-nr {
					left: 4px;
					transition: all 0.2s ease-out;
				}
			}
		}
		.dropdown-menu-user {
			border:none;
			border-radius: 0; 
    		box-shadow: 0 15px 35px 0 rgba(0,0,0,.1);
    		padding-bottom: 0;
    		left: -100%;
    		right: -100%;
    		padding-bottom: 20px;
    		padding-top: 20px;
    		p.dropdown-item {
    			padding-right: 15px;
    			padding-left: 15px;
    			margin-top: 5px;
    			margin-bottom: 5px;
    		}

    		a.dropdown-item {
    			padding-right: 15px;
    			padding-left: 15px;
    			padding-top: 5px;
    			padding-bottom: 5px;
    			font-size: 14px;
    			&:before {
    				@extend .icon-arrow-right;
    				font-size: 10px;
    				margin-right: 10px;
    				color: #6aa8d2;
    			}
    			&.logout {
    				background-color: #dbeaf7;
				    margin-top: 5px;
				    padding-top: 8px;
				    padding-bottom: 8px;
				    color: #6aa8d2;
    				&:before {
	    				@extend .icon-logout;
	    				font-size: 12px;
	    				margin-right: 10px;
	    			}
    			}
    		}
    		.loggedin {
    			font-size: 14px;
    		}
    		.not-loggedin {
    			min-width: 100%;
    			padding-bottom: 15px;
    			.login-head {
				    background-color: #fcfafa;
				    padding: 5px 0;
    				p {
    					display: block;
					    width: 100%;
					    white-space: nowrap;
					    padding: 15px;
					    margin-bottom: 0
    				}
    				i.icon-login {
    					margin: 0;
    					transform: none;
    					transition: none;
    					margin-left: 10px;
    					font-size: 14px;
    					position: relative;
    					top: 1px;
    				}
    			}
    			form.minilogin {
    				padding: 15px;
    				.form-control {
    					border-radius: 0;
    					border: none;
    					border-bottom: 1px solid #e0e0e0;
    					padding-left: 0;
    					transition: all 0.3s ease;
    					font-size: 14px;
    					&:focus {
							border-bottom: 1px solid #252525;
							transition: all 0.3s ease;
						}
    				}
    				::-webkit-input-placeholder { /* Chrome/Opera/Safari */
					  color: #757575;
					  font-size: 12px;
					}
					::-moz-placeholder { /* Firefox 19+ */
					  color: #757575;
					  font-size: 12px;
					}
					:-ms-input-placeholder { /* IE 10+ */
					  color: #757575;
					  font-size: 12px;
					}
					:-moz-placeholder { /* Firefox 18- */
					  color: #757575;
					  font-size: 12px;
					}


    			}
    			.forgotpass {
    				display: block;
    				a{
    					color: #4796c9;
    					font-size: 12px;
    					margin-top: 5px;
    					display: inline-block;
    				}
    			}
    			.buttons {
    				margin-top: 5px;
    				button.btn {
    					color: #fff;
					    background-color: #050808;
					    border: none;
					    border-radius: 0;
					    transition: all 0.3s ease;
					    cursor: pointer;
					    i {
					    	margin-left: 5px;
					    	color: #fff;
					    	transition: all 0.3s ease;
					    	position: relative;
					    	display: inline-block;  
					    	font-size: 14px;
					    }
					    &:focus,
					    &:hover,
					    &:active {
							i {
								transition: all 0.3s ease;
								transform: translateX(3px);
								color: #fff;
							}
					    }
    				}
    				a.btn {
    					color: #4796c9;
    					font-size: 16px;
    					cursor: pointer;
    					display: inline-block;
    					transition: all 0.3s ease;
    					border-radius: 0;
    					position: relative;
    					&:after {
    						content: '';
    						position: absolute;
    						height: 1px;
    						right: 0;
    						background-color: #fff;
    						bottom: 0;
    						left: 0;
    						transition: all 0.3s ease;
    						display: block;
    					}
    					&:focus,
					    &:hover,
					    &:active {
							transform: translateY(-3px);
							transition: all 0.3s ease;
							&:after {
								bottom: 5px;
								background-color: #4796c9;
								transition: all 0.3s ease;
							}
					    }
    				}
    			}
    		}
		}
	}
	.whislist-section {
		display: inline-block;
		width: 100%;
		padding-right: 10px;
		a {
			padding: 8px 0 8px 8px;
			color: #000;
			display: inline-block;
			position: relative;

			i {
				font-size: 18px;
    			position: relative;
    			top: 4px;
			}
			span.count {
				display: none;
				position: absolute;
				top: 11px;
				right: -10px;
				background-color: #e84556;
				font-size: 9px;
				line-height: 16px;
				border-radius: 50%;
				color: #fff;
				width: 16px;
				text-align: center;
				transform: scale(1); 
    			transition: transform 0.25s ease-in-out;
    			font-weight: 700;
    			height: 16px;
			}
			&.fav-active {
				span.count {
					display: block;
				}
				i {
					color: #e84556;
				}
			}
		}
	} 
}

#kidlet-desktop-header {
	.top-bar {
		margin-top: 22px;
	}
	.bottom-bar {
		margin-top: 27px;
	}
	.left-cnt {
		a.speclink {
			font-size: 18px;
			font-weight: 500;
			color: #000;
			position: relative;
			display: inline-block;
			margin-top: 5px;
			&:before {
				content: '';
				border-radius: 3px;
				position: absolute;
				height: 6px; 
				left: -5px;
			    right: -5px;
			    top: 10px;
			    z-index: -1;
			    transition: all 0.2s ease-out;
			}
			span {
				display: inline-block;
				transition: all 0.2s ease-out;
			}
			&.promo {
				margin-left: 10px;
				&:before {
					background-color: #f28d96;
				}
			}
			&.new {
				&:before {
					background-color: #cce7cd;
				}
			}
			&.search {
				&:before {
					display: none;
				}
				i {
					font-size: 23px;
				    position: relative;
				    top: 3px;
				    &:before {
				    	content: '';
						position: absolute;
						width: 19px;
						height: 19px;
						border-radius: 50%;
						background-color: #cce7cd;
						left: 3px;
		    			top: 2px;
		    			z-index: -1;
				    }
				}
			}
			&:hover {
				&:before {
					transform: translateX(-2px);
					transition: all 0.2s ease-out;
				}
				span {
					transform: translateX(2px);
					transition: all 0.2s ease-out;
				}
			}
		}
		a.search {
			font-size: 23px;
			color: #000;
			font-weight: 500;
			transition: all 0.2s ease-out;
			display: inline-block;
			i {
			    top: 3px;
			    position: relative;
			    &:after {
			    	content: '';
					position: absolute;
					width: 19px;
					height: 19px;
					border-radius: 50%;
					background-color: #dce9f5;
					left: 3px;
	    			top: 2px;
	    			z-index: -1;
			    }
			}
			&:hover {
				transition: all 0.2s ease-out;
				transform: scale(1.1);
			}
			&:hover,
			&:active,
			&:focus {
				outline: none;
				box-shadow: none;
			}
		}
	}
	.whislist-section {
		display: inline-block;
		width: 100%;
		a {
			padding: 8px 0 8px 8px;
			color: #000;
			display: inline-block;
			position: relative;

			i {
				font-size: 23px;
    			position: relative;
    			top: 4px;
			}
			span.count {
				//display: none;
				position: absolute;
				top: 11px;
				right: -10px;
				background-color: #e84556;
				font-size: 9px;
				line-height: 16px;
				border-radius: 50%;
				color: #fff;
				width: 16px;
				text-align: center;
				transform: scale(1); 
    			transition: transform 0.25s ease-in-out;
    			font-weight: 700;
    			height: 16px;
			}
			&.fav-active {
				span.count {
					display: block;
				}
				i {
					color: #e84556;
				}
			}
		}
	} 
	.user-section {
		margin-right: 25px;
	}
	.user-section, 
	.minicart-section, {
		display: inline-block;
		.dropdown {
			a.btn {
				font-size: 18px;
				font-weight: 500;
				color: #000;
				padding-left: 0;
				padding-right: 0;
				position: relative;
				&:hover,
				&:focus {
					outline: none;
					border-color: transparent;
					box-shadow: none;
					color: #000;
				}
				&:after {
					display: none;
				}
			}
			.item-nr {
				position: absolute;
			    left: 8px;
			    top: 18px;
			    font-size: 12px;
			    font-weight: 600;
			    color: #245a5a;
				z-index: 10;
				transition: all 0.2s ease-out;
			}
			i.icon {
				font-size: 23px;
				position: relative;
				top: 3px;
				margin-right: 10px;
				transition: all 0.2s ease-out;
				display: inline-block;
				&:after {
					content: '';
					position: absolute;
					width: 24px;
					height: 24px;
					border-radius: 50%;
					left: -3px;
	    			top: 3px;
	    			z-index: -1;
				}
				&.icon-user {
					&:after {
						background-color: #f8efb1;
						left: -5px;
    					top: 4px;
					}
				}
				&.icon-bag {
					&:after {
						background-color: #c8e8e8;
						left: -4px;
    					top: 4px;
					}
				}
			}
			i.icon-arrow-down {
				font-size: 10px;
				margin-left: 6px;
				display: inline-block;
				transition: all 0.2s ease-out;
			}
			&.show {
				i.icon-arrow-down {
					transform: rotate(180deg);
					transition: all 0.2s ease-out;
				}
				i.icon {
					transform: translateX(-4px);
					transition: all 0.2s ease-out;
				}
				.item-nr {
					left: 4px;
					transition: all 0.2s ease-out;
				}
			}
		}
		&:first-child {
			margin-right: 20px;
		}
		.dropdown-menu-user {
			border:none;
			border-radius: 0; 
    		box-shadow: 0 15px 35px 0 rgba(0,0,0,.1);
    		padding-bottom: 0;
    		p.dropdown-item {
    			padding-right: 15px;
    			padding-left: 15px;
    			margin-top: 5px;
    			margin-bottom: 5px;
    		}

    		a.dropdown-item {
    			padding-right: 15px;
    			padding-left: 15px;
    			padding-top: 5px;
    			padding-bottom: 5px;
    			&:before {
    				@extend .icon-arrow-right;
    				font-size: 10px;
    				margin-right: 10px;
    				color: #6aa8d2;
    			}
    			&.logout {
    				background-color: #dbeaf7;
				    margin-top: 5px;
				    padding-top: 8px;
				    padding-bottom: 8px;
				    color: #6aa8d2;
    				&:before {
	    				@extend .icon-logout;
	    				font-size: 12px;
	    				margin-right: 10px;
	    			}
    			}
    		}
    		.loggedin {
    			font-size: 14px;
    		}
    		.not-loggedin {
    			min-width: 325px;
    			padding-bottom: 15px;
    			.login-head {
				    background-color: #fcfafa;
				    padding: 5px 0;
    				p {
    					display: block;
					    width: 100%;
					    white-space: nowrap;
					    padding: 15px;
					    margin-bottom: 0
    				}
    				i.icon-login {
    					margin: 0;
    					transform: none;
    					transition: none;
    					margin-left: 10px;
    					font-size: 14px;
    					position: relative;
    					top: 1px;
    				}
    			}
    			form.minilogin {
    				padding: 15px;
    				.form-control {
    					border-radius: 0;
    					border: none;
    					border-bottom: 1px solid #e0e0e0;
    					padding-left: 0;
    					transition: all 0.3s ease;
    					font-size: 14px;
    					&:focus {
							border-bottom: 1px solid #252525;
							transition: all 0.3s ease;
						}
    				}
    				::-webkit-input-placeholder { /* Chrome/Opera/Safari */
					  color: #757575;
					  font-size: 12px;
					}
					::-moz-placeholder { /* Firefox 19+ */
					  color: #757575;
					  font-size: 12px;
					}
					:-ms-input-placeholder { /* IE 10+ */
					  color: #757575;
					  font-size: 12px;
					}
					:-moz-placeholder { /* Firefox 18- */
					  color: #757575;
					  font-size: 12px;
					}


    			}
    			.forgotpass {
    				display: block;
    				a{
    					color: #4796c9;
    					font-size: 12px;
    					margin-top: 5px;
    					display: inline-block;
    				}
    			}
    			.buttons {
    				margin-top: 5px;
    				button.btn {
    					color: #fff;
					    background-color: #050808;
					    border: none;
					    border-radius: 0;
					    transition: all 0.3s ease;
					    cursor: pointer;
					    i {
					    	margin-left: 5px;
					    	color: #fff;
					    	transition: all 0.3s ease;
					    	position: relative;
					    	display: inline-block;  
					    	font-size: 14px;
					    }
					    &:focus,
					    &:hover,
					    &:active {
							i {
								transition: all 0.3s ease;
								transform: translateX(3px);
								color: #fff;
							}
					    }
    				}
    				a.btn {
    					color: #4796c9;
    					font-size: 16px;
    					cursor: pointer;
    					display: inline-block;
    					transition: all 0.3s ease;
    					border-radius: 0;
    					position: relative;
    					&:after {
    						content: '';
    						position: absolute;
    						height: 1px;
    						right: 0;
    						background-color: #fff;
    						bottom: 0;
    						left: 0;
    						transition: all 0.3s ease;
    						display: block;
    					}
    					&:focus,
					    &:hover,
					    &:active {
							transform: translateY(-3px);
							transition: all 0.3s ease;
							&:after {
								bottom: 5px;
								background-color: #4796c9;
								transition: all 0.3s ease;
							}
					    }
    				}
    			}
    		}
		}
	}
	.minicart-section {
		.dropdown {
			.dropdown-menu-cart {
			    min-width: 325px;
				padding: 15px 0;
				border:none;
				border-radius: 0; 
	    		box-shadow: 0 15px 35px 0 rgba(0,0,0,.1);
	    		transform: translateY(30px);
	    		transition: all 0.3s ease;
	    		display: block;
	    		visibility: hidden;
	    		opacity: 0;
			}
			&.show {
				.dropdown-menu-cart {
					transform: translateY(0px);
	    			transition: all 0.3s ease;
	    			display: block;
					visibility: visible;
	    			opacity: 1;
				}
			}
		}
		.minicart-prodlist {

			.item {
				padding: 10px 15px;
				position: relative;
				@include clearfix;
				span {
					display: inline-block;
					font-size: 12px;
				}
				span.image {
					float: left;
					img {
						width: 50px;
						height: auto;
						display: inline-block;
					}
				}
				span.name {
					padding: 5px 5px 5px 10px;
    				max-width: 145px;
    				float: left;
    				a {
						color: #252525;
						&:hover {
							color: darken(#8bc5eb,25%);
						}
					}
				}
				span.cant {
					margin-left: 15px;
					margin-top: 10px;
					float: left;
					font-weight: 600;
				}
				span.pret {
					float: right;
					margin-top: 10px;
					span {
						font-weight: 700;
						margin-right: 5px;
					}
				}
				&:hover {
					//background-color: #fbfbfb;
				}
				&:before {
					content: "";
				    border-bottom: 1px solid #ececec;
				    margin: 0 15px;
				    display: none;
				    left: 0;
				    right: 0;
				    position: absolute;
				    bottom: 0;
				}
			}
		}
		.minicart-header {
			@include clearfix;
			margin-bottom: 10px;
			padding: 0 15px;
			span {
				display: inline-block;
				font-size: 12px;
				color: #000;
			}
			span.prod {
				width: 200px;
				float: left;

			}
			span.cant {
				float: left;
			}
			span.pret {
				float: right;
			}
		}
		.minicart-total {
			padding: 10px 0;
    		margin: 0 15px;
			font-size: 12px;
			border-top: 1px solid #ececec;
			@include clearfix;
			span {
				display: inline-block;
			}
			.total {
				font-weight: 600;
				text-transform: uppercase;
				span {
					text-transform: none;
					margin-left: 10px;
				}
			}
			.total-price {
				float: right;
				span {
					font-weight: 700;
					margin-right: 5px;
				}
			}
		}
		.minicart-cta {
			padding: 20px 15px 5px;
			.btn-cart {
				border-radius: 0;
				text-transform: capitalize;
				font-weight: 700;
				font-size: 18px;
				background-color: #cce7cd;
				font-family: 'Nunito';
				cursor: pointer;
				transition: all 0.2s ease-out;
				&:hover {
					background-color: darken(#cce7cd,10%);
					transition: all 0.2s ease-out;
					i {
						transform: translateX(5px);
						transition: all 0.2s ease-out;
					}
				}
				i {
					position: relative;
				    top: 2px;
				    margin-left: 5px;
				    transition: all 0.2s ease-out;
				    display: inline-block;	
				}
			}
		}
	}
}



.lity-content:after {
	box-shadow: none;
}

#kidlet-search-inline {
	background-color: #fff;
	padding: 50px;
	.input-group {
		input {
			border-radius: 0;
			border: 3px solid #8bd592;
			min-height: 80px;
		}
	}
	.input-group-btn {
		button {
			border: none;
			border-radius: 0;
			background-color: #8bd592;
			cursor: pointer;
			i {
				font-weight: 800;
				color: #fff;
				font-size: 30px;
			}
		}
	}
}



// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

	#kidlet-desktop-header {
		.whislist-section {
			a {
				padding-left: 0;
			}
		}
		.user-section {
			margin-right: 15px;
			.dropdown {
				a.btn {
					font-size: 15px;
					font-weight: 600;
				}
				i.icon {
					margin-right: 5px;
				}
			}
		}
		.minicart-section {  
			.dropdown {
				a.btn {
					font-size: 15px;
					font-weight: 600;
				}
				i.icon {
					margin-right: 5px;
				}
			}
		}
	}
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

	
	#kidlet-desktop-header {
		.whislist-section {
			a {
				padding-left: 8px;
			}
		}
		.user-section {
			margin-right: 25px;	
			.dropdown {
				a.btn {
					font-size: 18px;
					font-weight: 500;
				}
				i.icon {
					margin-right: 10px;
				}
			}
		}
		.minicart-section {
			.dropdown {
				a.btn {
					font-size: 18px;
					font-weight: 500;
				}
				i.icon {
					margin-right: 10px;
				}
			}
		}
	}


}


