section.photoswipe-gallery {
    padding-top: 25px;
    position: relative;
    .new-badge,
    .promo-badge {
        display: none;
    }
    &.new {
        .new-badge {
            display: block;
            position: absolute;
            top: 30px;
            left: 0;
            background-image: url('../images/new-badge@2x.svg');
            background-position: center;
            background-size: cover;
            width: 46px;
            height: 45px;
            font-weight: 800;
            color: #fff;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 45px;
            z-index: 10;
            span {  
                display: block;
                position: relative;
                text-align: center;
                line-height: 1; 
                &:first-child {
                    color: #5ca070;
                    font-size: 14px;
                    font-weight: 600; 
                    text-transform: initial;
                    margin-top: 8px;
                    margin-right: 5px;
                }
            }
        }
    }
    &.promo {
        .promo-badge {
            display: block;
            position: absolute;
            top: 30px;
            right: 0;
            background-image: url('../images/promo-badge@2x.svg');
            background-position: center;
            background-size: cover;
            width: 46px;
            height: 45px;
            font-weight: 800;
            color: #fff;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 45px;
            text-align: center;
            z-index: 10;
        }
    }
    .product-images {
        img {
            max-width: 100%;
            height: auto;
        }
        .large-image-container {
            margin-bottom: 20px;
            position: relative;
            
            span.kdl-fav {
                font-size: 18px;
                position: absolute;
                bottom: 5px;
                right: 0;
                background-color: #fff;
                border-radius: 50%;
                width: 28px;
                height: 28px;
                background-color: #fff;
                z-index: 200;
                text-align: center;
                display: block;
                cursor: pointer;
                i {
                    position: relative;
                    top: 4px;
                    color: #9e9e9e;
                }
                &.fav-active {
                    background-color: #e43d4c;
                    i {
                        color: #fff;
                    }
                    &:hover {
                        i {
                            color: #fff;
                        }
                    }
                }
                .popover-fav {
                    position: absolute;
                    background-color: #000;
                    border-radius: 3px;
                    color: #fff;
                    font-size: 11px;
                    bottom: 1px;
                    width: 160px;
                    right: 130%;
                    padding: 5px 3px;
                    opacity: 0;
                    visibility: hidden;
                    &:after {
                        content: '';
                        position: absolute;
                        right: -6px;
                        top: 9px;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 4px 0 4px 6px;
                        border-color: transparent transparent transparent #000;
                    }
                }
                &:hover {
                    //background-color: #e43d4c;
                    i {
                        color: #e43d4c;
                    }
                    .popover-fav {
                        visibility: visible;
                        opacity: 1;
                    }
                }

            }
        }
        .small-image-container {
            display: flex;
            justify-content: center;
            margin: 0 -10px;
            figure {
                margin: 0 10px;
                width: 100%;
                max-width: calc( 25% - 20px );
                a {
                    display: block;
                    width: 100%;
                    img {
                        border: 1px solid #999;
                        padding: 2px;
                        transition: all .2s ease;
                        opacity: 0.5;
                    }
                }
                &:hover {
                    a {
                        img {
                            opacity: 1;
                        }
                    }
                }
                &.thumb-active {
                    a {
                        img {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .pswp {
        img.pswp__img {
            box-shadow: 0 30px 30px 0 rgba(0,0,0,.15);
        }
        .pswp__button--arrow--right:before,
        .pswp__button--arrow--left:before {
            background-image: none;
            background-color: transparent;
            content: '\e606';
            font-family: 'simple-line-icons';
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            right: 15px;
            width: auto;
            height: auto;
            color: #000;
            font-size: 24px;
        }
        .pswp__button--arrow--left:before {
            content: '\e605';
            right: 15px;
            left: auto;
        }
    }
    .pswp__caption {
        display: flex;
        justify-content: center;
        background-color: transparent;
        .pswp__caption__center {
            padding: 20px 10px;
        }
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
            
            display: block;
            @include clearfix;
            li {
                list-style: none;
                display: inline-block;
                float: left;
                margin: 0 10px;
                cursor: pointer;
                opacity: 1;
                transition: all .3s ease;
                box-shadow: 0 30px 30px 0 rgba(0,0,0,.15);
                &:not(.active) {
                    opacity: .8;
                }
                &:hover {
                    opacity: 1;
                }
                img {
                    width: 75px;
                    height: 75px;
                }
            }
        }
    }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

    section.photoswipe-gallery {
        .pswp {
            .pswp__button--arrow--right:before,
            .pswp__button--arrow--left:before { 
                color: #fff;
                font-size: 48px;
            }
        }
        &.new {
            .new-badge {
                width: 56px;
                height: 55px;
                font-weight: 800;
                font-size: 16px;
                line-height: 56px;
                span {  
                    &:first-child {
                        color: #5ca070;
                        font-size: 16px;
                        font-weight: 600;
                        margin-top: 10px;
                        margin-right: 5px;
                    }
                }
            }
        }
        &.promo {
            .promo-badge {
                width: 56px;
                height: 55px;
                font-weight: 800;
                font-size: 16px;
                line-height: 56px;
            }
        }
        .product-images {
            .large-image-container {               
                span.kdl-fav {
                    font-size: 22px;
                    width: 40px;
                    height: 40px;
                    i {
                        top: 8px;
                    }
                    .popover-fav {
                        bottom: 7px;
                    }
                }
            }
        }


    }



}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}