section.kdl-categorii-new {
	.categorii-tab {
		margin-top: 15px;
		ul.nav-pills {
			li.nav-item {
				&:first-child {
					margin-right: 20px;
				}
				a.nav-link {
					background-color: #fff;
					border-radius: 8px;
					padding: 10px 25px 7px 25px;
					border: 1px solid transparent;
					font-size: 14px;
					font-weight: 600;
					position: relative;
					transition: all .3s ease;
					min-width: 135px;
    				text-align: center;
					span.txt {
						display: inline-block;
						margin-left: 9px;
						line-height: 1;
					}
					span.img-hold {
						width: 35px;
						height: 45px;
						display: inline-block;
						background-repeat: no-repeat;
					}
					span.check-hold {
						position: absolute;
						top: 10px;
						right: 10px;
						width: 15px;
						height: 15px;
						background-color: #fff;
						border: 1px solid transparent;
						border-radius: 50%;

						&:after {
							content: '';
							width: 10px;
							height: 8px;
							position: absolute;
							top: 2px;
							left: 1px;
							display: block;
							background-repeat: no-repeat;
						}
					}
					&.active {
						transition: all .3s ease;
						&:after {
							content: '';
							position: absolute;
							top: auto;
							left: 50%;
							width: 1px;
							height: 10px;
							background-color: #fff;
							bottom: -17px;
						}
					}
				}
				&.girl {
					a.nav-link {
						color: #f54554;
						border: 1px solid #f54554;
						box-shadow: inset 0px 1px 4px 0px rgba(245, 69, 84, 0.35);
						span.img-hold {
							background-image: url('../images/categorii-girl-red.svg');
							transition: all .3s ease;
						}
						span.check-hold {
							border: 1px solid #f54554;
						}
						&.active {
							background-color: #f54554;
							color: #fff;
							box-shadow: none;
							box-shadow: 0px 1px 4px 0px rgba(245, 69, 84, 0.35);
							span.img-hold {
								background-image: url('../images/categorii-girl-white.svg');
								transition: all .3s ease;
							}
							span.check-hold {
								&:after {
									background-image: url('../images/check-red.svg');
								}
							}
							&:after {
								background-color: #f54554;
							}
						}
					}
				}
				&.boy {
					a.nav-link {
						color: #2768f3;
						border: 1px solid #2768f3;
						box-shadow: inset 0 0 5px 1px rgba(39,104,243,.35);
						span.img-hold {
							background-image: url('../images/categorii-boy-blue.svg');
							transition: all .3s ease;
						}
						span.check-hold {
							border: 1px solid #2768f3;
						}
						&.active {
							background-color: #2768f3;
							color: #fff;
							box-shadow: 0px 1px 4px 0px rgba(39, 104, 243, 0.35);
							span.img-hold {
								background-image: url('../images/categorii-boy-white.svg');
								transition: all .3s ease;
							}
							span.check-hold {
								&:after {
									background-image: url('../images/check-blue.svg');
								}
							}
							&:after {
								background-color: #2768f3;
							}
						}
					}
				}
			}
		}
		.tab-content>.active {
		    animation-name: slideInUp;
		}
		.tab-content {
			margin-top: 20px;
			position: relative;
			margin-right: -15px;
			&:after {
				content: "";
			    position: absolute;
			    background-image: url(../images/categorii-bg-girl.svg);
			    top: -94%;
			    right: -30px;
			    left: -30px;
			    width: auto;
			    height: 238%;
			    display: block;
			    background-repeat: no-repeat;
			    background-size: cover;
			    background-position: 50%;
			    z-index: -1;
			    display: none;
			}
			.owl-theme .owl-nav {
				margin-top: 5px;
				.owl-prev,
				.owl-next {
					background-color: transparent;
					.lnr {
						color: #000;
						font-size: 16px;
						-webkit-text-stroke: 1px #000;
					}
				}
				.owl-prev,
				.owl-next {
					span {
						display: block;
						position: relative;
						&:before {
							content: "";
						    position: absolute;
						    width: 9px;
						    height: 9px;
						    right: -2px;
						    top: 4px;
						    background-color: #f54554;
						    border-radius: 50%;
						    z-index: -1;
						}
					}
				}
				.owl-next {
					span {
						&:before {
							right: auto;
							left: -2px;
						}
					}
				}
			}
			.tab-pane {
				.owl-carousel,
				.girl-carousel,
				.boy-carousel {
					.item {
						background-color: transparent;
						border: 1px solid transparent;
						color: #fff;
						border-radius: 6px;
						min-height: 84px;
						a {
							display: block;
							position: relative;
							text-align: center;
							padding: 12px;
							h4 {
								font-size: 12px;
								font-weight: bold;
								text-align: center;
								margin-top: 5px;
								margin-bottom: 0;
							}
						}
					}
					.owl-item img {
						width: auto;
						margin: 0 auto;

					}
					.owl-item {
						&:not(.active) {
							.item {
								background-color: #f5f5f5;
								box-shadow: 0px 2px 6px 0px rgba(147, 147, 147, 0.5);
								filter: grayscale(100%);
								border-color: transparent;
							}
						}
						
					}
					.owl-stage {
						left: -15px;
					}
				}
				&#fete-tab-cnt {
					.item {
						border: 1px solid #f54554;
						box-shadow: inset 0px 0px 5px 0px rgba(211, 17, 34, 0.5), 0px 1px 4px 0px rgba(211, 17, 34, 0.5) ;
						a {
							h4 {
								color: #cd1222;
							}
						}
					}
				}
				&#baieti-tab-cnt {
					.item {
						box-shadow: inset 0px 0px 5px 0px rgba(39, 104, 243, 0.6), 0px 1px 4px 0px rgba(39, 104, 243, 0.6);;
						border: 1px solid #2768f3;
						a {
							h4 {
								color: #2768f3;
							}
						}
					}
				}
			}
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  

	section.kdl-categorii-new {
		.categorii-tab {
			.tab-content {
				margin-right: 0;
				.tab-pane {
					.owl-carousel,
					.girl-carousel,
					.boy-carousel {
						.owl-stage {
							left: 0;
						}
					}
				}
			}
		}
	}
	
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  



}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}
