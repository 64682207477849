//bf-input-float-label

.has-float-label {
  display: block;
  position: relative;
  margin-top: 20px;
  	input.form-control {
		display: block;
	    width: 100%;
	    padding: .46875rem 1rem;
        color: #495057;
        box-shadow: none;
  		background: no-repeat bottom,50% calc(100% - 1px);
	    background-size: 0 100%,100% 100%;
	    border: 0;
	    height: 36px;
	    transition: background 0s ease-out;
	    padding-left: 0;
	    padding-right: 0;
	    border-radius: 0;
	    font-size: 14px;
	    background-image: linear-gradient(0deg,#5792ef 2px,rgba(156,39,176,0) 0),linear-gradient(0deg,#000 1px,hsla(0,0%,82%,0) 0);
        &:focus {
        	background-size: 100% 100%,100% 100%;
			transition-duration: .3s;
        }
  	}
}
.has-float-label label, .has-float-label > span {
  position: absolute;
  left: 0;
  top: 0;
  cursor: text;
  font-size: 75%;
  opacity: 1;
  -webkit-transition: all .2s;
  transition: all .2s;
  top: -.5em;
  left: 15px;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
  color: #5792ef;
  font-weight: 600;
}
.has-float-label label::after, .has-float-label > span::after {
  // content: " ";
  // display: block;
  // position: absolute;
  // background: white;
  // height: 2px;
  // top: 50%;
  // left: -.2em;
  // right: -.2em;
  // z-index: -1;
}
.has-float-label .form-control::-webkit-input-placeholder {
  opacity: 0;
  -webkit-transition: all .2s;
  transition: all .2s;
}
.has-float-label .form-control::-moz-placeholder {
  opacity: 0;
  transition: all .2s;
}
.has-float-label .form-control:-ms-input-placeholder {
  opacity: 0;
  transition: all .2s;
}
.has-float-label .form-control::placeholder {
  opacity: 0;
  -webkit-transition: all .2s;
  transition: all .2s;
}
.has-float-label .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}
.has-float-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}
.has-float-label .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}
.has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}
.has-float-label .form-control:placeholder-shown:not(:focus) + * {
  font-size: 16px;
  font-weight: 600;
  opacity: 1; 
  top: 15px;
  color: #000;
}

.input-group .has-float-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.input-group .has-float-label .form-control {
  width: 100%;
  border-radius: 0.25rem;
}
.input-group .has-float-label:not(:last-child), .input-group .has-float-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}
.input-group .has-float-label:not(:first-child), .input-group .has-float-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}