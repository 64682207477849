.user-section-title {
	margin-top: 50px;
	h1 {
		font-size: 30px;
		font-weight: 800;
		span.user-name {
			color: #78b978;
		}
	}

}
.user-section-wrap {
	margin-top: 30px;
	box-shadow: 0 30px 30px 0 rgba(36,63,91,.1);

	.nav.nav-tabs {
		background-color: #badfbc;
		border: none;
		li.nav-item {
			a.nav-link {
				font-size: 18px;
				font-weight: 700;
				color: #000;
				border: none;
				position: relative;
				transition: all 0.3s ease;
				&:after {
					content: '';
					position: absolute;
					width: 0;
					height: 3px;
					background-color: transparent;
					top: -6px;
					left: 0;
					right: 0;
					transition: all 0.3s ease;
				}
				&:before {
					content: '';
					position: absolute;
					width: 100%;
					height: 3px;
					background-color: #fff;
					top: -3px;
					left: 0;
					right: 0;
				}
				.tab-label {

				}
				i {
					margin-top: 5px;
					margin-bottom: 5px;
				}
				&:active,
				&:hover,
				&:focus,
				&.active {
					color: #75b975;  
					background-color: #fff;
					border-color: transparent; 
					border: none;
					padding-top: 12px;
					transition: all 0.3s ease;
					box-shadow: 0 -12px 30px 0 rgba(36,63,91,.05);
					&:after {
						width: 100%;
						background-color: #75b975;
					}
					.tab-label {
	
					}
					i {
						color: #75b975;
					}
				}
			}

		}
	} 
	.tab-content {
		padding: 0;
		margin-bottom: 50px;
		h2 {
			font-weight: 800;
    		font-size: 20px;
    		margin-bottom: 20px
		}
		.tab-pane {
			&.active {

			}
			table {
				font-size: 14px;
				margin-bottom: 0;
				&.table-hover {
					thead tr {
						border-bottom: 1px solid #000;
					}
					tbody tr:hover {
    					background-color: #f6f5f5;
    				}
    			}
				a.btn {
					box-shadow: 8px 10px 20px 0 rgba(46,61,73,.15);
					transition: all .3s ease;
					color: #000;
					background-color: #f6e999;
					&:hover {
						box-shadow: 2px 4px 8px 0 rgba(46,61,73,.2);
						transition: all .3s ease;
						background-color: darken(#f6e999,3%);
					}
				}
			}
		}
	}
	.card {
		border: none;
		.card-header {
			background-color: #badfbc;
			padding: 15px 20px;
			border-color: #86bf8a;
			h5 {
				position: relative;
			}
			a {
				font-weight: 700;
				color: #000;
				font-size: 22px;
				display: block;
				position: relative;
				&:after {
					content: "\e606";
					position: absolute;
					right: 0;
					font-family: 'simple-line-icons';
					display: inline-block;
					top: 3px;
					font-size: 18px;
					transform: rotate(0deg);
					transition: all 0.3s ease;
				}
				&:not(.collapsed) {
					&:after {
						transform: rotate(90deg);
						transition: all 0.3s ease;
					}
				}
			}
		}
		.card-body {
			padding: 30px 15px;
		}
	}
	.collapse { 
	    transition: all 0.3s ease;
	    margin: 0 -15px;
	    background-color: #fff;
	    box-shadow: 0 0 25px 1px rgba(0,0,0,.5);
	}
}


body.modal-open {
	.modal-backdrop.show {
		opacity: .85;
	}
}
.modal {
	h5 {
		font-weight: 800;
	    font-size: 20px;
	    color: #000;
	}
	.modal-header {
		padding: 15px;
		padding-bottom: 0;
		border-bottom: none;
		h4.modal-title {
			font-weight: 800;
	    	font-size: 26px;
		}
	}
	.modal-content {
		border: none;
	}
	.modal-body {
		padding: 15px; 
	}
	.ordered-products {
		box-shadow: 0px 5px 10px 0px rgba(0, 1, 1, 0.15);
		margin-bottom: 30px;
	}
	.cart-header-desktop {
		background-color: #dce9f5;
    	color: #000;
		font-size: 14px;
		padding: 15px 30px;
		font-weight: 600;
	}
	.cart-item {
		padding: 15px;
		border-bottom: 1px solid #f3f3f3;
		.item-img-name {
			.item-img {
				display: block;
				float: none;
				text-align: center;
				margin-bottom: 15px
			}
			.item-name {
				margin-left: 0px;
				float: left;
				display: inline-block;
				span {
					display: block;
				}
				.name {
					font-weight: 600;
					font-size: 16px;
					margin-bottom: 12px;
					a {
						color: #000;
						&:hover {
							color: #4792da;
							text-decoration: underline;
						}
					}
				}
				.desc {
					color: #afafaf;
					font-weight: 600;
					font-size: 14px;
				}
			}
		}
		.item-price {
			margin-top: 25px;
			font-weight: 600;
		}
		.product-number-select {
			margin-top: 20px;
		}
		.item-delete {
			margin-top: 25px;
			font-weight: 600;
			a {
				color: #000;
				font-weight: 600;
				&:hover {
					color: #f05764;
				}
				i {
					margin-right: 5px;
				}
			}
		}
		.item-total {
			margin-top: 25px;
			font-weight: 600;
			text-align: center;
		}
	}
	.info-card {
		box-shadow: 0 5px 10px 0 rgba(0,1,1,.15);
    	margin-bottom: 30px;
		.info-card-header {
			padding: 15px;
			background-color: #dce9f5;
    		color: #000;
    		margin-bottom: 0;
    		i {
    			margin-right: 10px;
    			position: relative;
    			top: 2px;
    		}
		}
		.info-card-block{
			ul{
				list-style: none;
				padding: 0;
				margin: 0;
				//width: 75%;
				padding: 15px;
				li {
					list-style: none;
					position: relative;
					font-weight: 600;
					span.key {
						display: inline-block;
						background-color: #fff;
						padding: 5px 5px 5px 0;
						z-index: 10;
						position: relative;
					}
					span.value {
						float: right;
						display: inline-block;
						background-color: #fff;
						padding: 5px 0px 5px 5px;
						z-index: 10;
						position: relative;
					}
					&:before {
						content: "";
					    border-bottom: 1px dotted #c5c5c5;
					    text-decoration: none;
					    position: absolute;
					    bottom: 10px;
					    width: 100%;
					    left: 0;
					    right: 0;
					    z-index: 1;
					}
				}
			}
		}
	}
}


.user-section-wrap { 
	button.modify {
		background-color: #000;
		font-weight: 600;
		font-size: 18px;
		color: #fff;
		cursor: pointer;
		box-shadow: 8px 10px 20px 0 rgba(46,61,73,.15);
		transition: all .3s ease;
		margin-top: 30px;
		line-height: 1.5;
		&:hover,
		&:focus, 
		&:active {
			box-shadow: 2px 4px 8px 0 rgba(46,61,73,.2);
		}
	}
	select.custom-select {
		border-radius: 0;
		border-color: #000;
		width: 100%;
		font-size: 16px;
	    font-weight: 600;
	    color: #000;
	    font-family: 'Nunito';
	    margin-top: 25px;
	}
}

.nav-tabs {
    display:none;
}

[ripple] {
	position: relative;
	overflow: hidden;
}
[ripple] .ripple--container {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
[ripple] .ripple--container span {
	transform: scale(0);
	border-radius: 100%;
	position: absolute;
	opacity: 0.50;
	background-color: #fff;
	animation: ripple 1000ms;
}
@-moz-keyframes ripple {
	to {
		opacity: 0;
		transform: scale(2);
	}
}
@-webkit-keyframes ripple {
	to {
		opacity: 0;
		transform: scale(2);
	}
}
@-o-keyframes ripple {
 	to {
    	opacity: 0;
    	transform: scale(2);
 	}
}
@keyframes ripple {
	to {
    	opacity: 0;
    	transform: scale(2);
	}
}


// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  

	.user-section-wrap {
		.collapse {
		    transition: all .3s ease;
		    margin: 0;
		    background-color: #fff;
		    box-shadow: none; 
		}
		.card {
			.card-body {
				padding: 0;
			}
		}
	}
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  
	
	body.my-account {
		.modal-dialog{
			max-width: 768px;
		}

	}

	.modal {
		.info-card {
			.info-card-header {
				padding: 15px 30px;
			}
			.info-card-block{
				ul{
					padding: 15px 30px;
					li { 
						span.key {
							
						}
						span.value {
							
						}
						&:before {
							
						}
					}
				}
			}
		}
		.modal-header {
			padding: 30px;
		}
		.modal-body {
			padding: 30px;
		}
		.cart-item {
			padding: 15px;
			.item-img-name {
				.item-img {
					display: inline-block;
					float: left;
				} 
				.item-name {
					margin-left: 15px;
					float: left;
					display: inline-block;
					span {
						display: block;
					}
					.name {
						margin-bottom: 12px;
					}
				}
			}
			.item-total {
				margin-top: 25px;
				font-weight: 600;
				text-align: right;
			}
		}
	}
	
	.user-section-wrap  {
		.tab-content {
			padding: 40px 18px;
			.tab-pane {
				table {
					font-size: 16px;
				}
			}
		}
	}

}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	body.my-account {
		.modal-dialog{
			max-width: 992px;
		}
	}

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  
	
	body.my-account {
		.modal-dialog{
			max-width: 1110px;
		}
	}
}


@media(min-width:768px) {
	.user-section-wrap {
	    .nav-tabs { 
	        display: flex;
	    }
	    
	    .card {
	        border: none;
	    }

	    .card .card-header {
	        display:none;
	    }  

	    .card .collapse{
	        display:block;
	    }
	}
}

@media(max-width:767px){
	.user-section-wrap {
	    .tab-pane {
	        display: block !important;
	        opacity: 1;
	    }
	    .istoric-comenzi-table {
			display: block;
		    width: 100%;
		    overflow-x: auto;
		    -ms-overflow-style: -ms-autohiding-scrollbar;
	    }
	}
}