section.main-banners {
	.banner-wrap {
		border-radius: 8px;
		position: relative;
		a.big-link {
			display: block;
			width: 100%;
			height: 100%;
			img {
				border-radius: 8px;
			}
		}
		span.title {
			position: absolute;
		    top: auto;
		    bottom: 0;
		    right: 0;
		    padding: 15px 0 15px 15px;
		    background-color: #fff;
		    border-top-left-radius: 8px;
		    box-shadow: -11px -11px 35px 0 rgba(0,0,0,.09);
		    h2 {
		    	margin-bottom: 0;
		    }
		    a {
		    	text-decoration: underline;
		    	&:hover {
		    		color: #f54554;
		    	}
		    }
		}
		&.girl-banner {
			margin-right: 30px;
		}
		&.boy-banner {
			margin-top: 30px;
    		margin-left: 30px;
			span.title { 
				top: auto;
				right: auto;
				bottom: 0;
				left: 0;
				padding: 15px 15px 15px 15px;
				border-top-right-radius: 8px;
				border-top-left-radius: 0;
				box-shadow: 11px -14px 35px 0 rgba(0,0,0,.09);
				a {
					&:hover {
						color: #2768f3;
					}
				}
			}
		}
	}
}

section.top-not {
	.top-not-cnt {
		padding: 10px 0;
	    font-weight: 700;
	    color: #fff;
	    font-size: 17px;
	    background-color: #2868f3;
	    text-align: center;
		span {

		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.main-banners {
		.banner-wrap { 
			&.girl-banner {
				span.title { 
					top: 0;
				    right: 0;
				    bottom: auto;
				    padding: 30px 0 30px 30px;
				    border-bottom-left-radius: 8px;
				    border-top-left-radius: 0px;
				    box-shadow: none;
				}
			}
			&.boy-banner {
				margin-top: 60px;
    			margin-left: 30px;
				span.title { 
					top: auto;
					right: auto;
					bottom: 0;
					left: 0;
					padding: 30px 30px 30px 30px;
					border-top-right-radius: 8px;
					box-shadow: none;
				}
			}
		}
	}


}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}