//New users

.nu-hero-section {

	height: 95vh;
	background-image: url('../images/nu-landing-bg-2.jpeg');
	background-position-y: 15%;
	background-position-x: 40%;
	background-size: auto;
	background-repeat: no-repeat;
	position: relative;
	margin: 0 -15px 100px -15px; 
	&:before {
		content: '';
		top: 0;
		left: 0; 
		right: 0;
		z-index: 1;
		height: 100px;
		background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,1) 44%, rgba(255,255,255,0) 100%);
		position: absolute;
		
	}
	a.logo {
		position: relative;
		z-index: 10;
		padding: 10px 0;
	}
	h1 {
		padding: 80px 0 15px 30px;
		width: 36%;
		font-weight: 800;
		font-size: 34px;
		color: #000;
	}
	.title p {
		padding: 0 30px;
		font-size: 18px;
		font-weight: 600;
		strong {
			color: #f84752;
		}
	}
	.coupon-section {
		position: absolute;
		bottom: -5%;
		left: 0;
		right: 15%;
		background-color: #e5fafa;
		background-image: url('../images/nu-pattern-1.png');
		background-size: auto;
		background-position: center;
		background-repeat: repeat-x;
		border-radius: 0 30px 30px 0;
		padding: 40px 30px;
		box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.15);
		h2 {
			font-weight: 800;
			font-size: 20px;
			color: #116a68;
			margin-bottom: 20px;
		}
		span.coupon-25 {
			border: 1px dashed #298583;
			padding: 11px 15px;
			font-size: 16px;
			font-weight: 700;
			color: #f84752;
			text-align: center;
			margin-bottom: 20px;
			border-right: 0;
		}
		button.btn {
			font-size: 14px;
			font-weight: 800;
			color: #fff;
			text-transform: uppercase;
			background-color: #f84752;
			padding: 15px 5px;
			cursor: pointer;
		}
		span.coupon-25::selection {
		 	background: #d5e7e9;
		}
		span.coupon-25::-moz-selection {
		 	background: #d5e7e9;
		}
		span.text {
			font-size: 17px;
			font-weight: 600;
			color: #116a68;
		}
	}
}

h2.nu-section-title {
	text-align: center;
	font-weight: 800;
	color: #000;
	font-size: 26px;
	padding-bottom: 20px;
	position: relative;
	margin-bottom: 20px;
	&:after {
		content: '';
		width: 40px;
		height: 2px;
		display: block;
		background-color: red;
		margin: 0 auto;
		bottom: 0;
		position: absolute;
		left: 0;
    	right: 0;
    	text-align: center;
	}
}

hr.nu-divider {
	border-color: transparent;
	margin-top: 25px;
    margin-bottom: 25px;
}

.nu-about-us {
	p {
		font-weight: 600;
	}
	a.btn {
		margin-top: 30px;
		padding: 25px 15px;
		font-size: 20px;
		color: #fff;
		font-weight: 800;
		border: none;
		background-color: #003d3f;
		background-image: url('../images/btn-pattern-blue.png');
		background-size: auto;
		background-position: center;
		background-repeat: repeat-x;
		&:hover {
			background-color: #02484a;
		}
	}
}

.nu-beneficii-kidlet {
	.benefits-wrap {
		margin-top: 40px;
		margin-bottom: 20px;
		box-shadow: 0 8px 27px 0 rgba(89,89,89,0.05);
		border-radius: 3px;
		.benefit-item {
			padding-bottom: 12px;
			padding-top: 12px;
			border-bottom: 1px solid #f2f2f2;
			&:last-child {
				border-bottom: 0;
			}
			&:first-child {
				padding-top: 0;
			}
		}
		img {
			filter: grayscale(100%);
			width: 55%;
		}
		span.icon-image {
			width: 20%;
			text-align: center;
		}
		span.desc {
			width: 80%;
			margin-left: 8px;
			color: #000;
			font-size: 16px;
			font-weight: 600;
			span.small-text {
				text-transform: uppercase;
			    font-size: 14px;
			    font-weight: 600;
			    color: #c1c1c1;
			    margin-top: 5px;
			}
		}
	}
}

.kidlet-nu-footer {
	padding: 30px 0;
	background-color: #000;
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			list-style-type: none;
			a {
				color: #fff;
				font-size: 14px;
				font-weight: 700;
				i {
					margin-right: 6px;
				}
			}
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  
	
	.nu-hero-section-wrap {
		padding-bottom: 100px;
	}
	.nu-hero-section {
		width: auto;
		height: 55vh;
		background-position-x: right;
		margin: 0 0 60px 0;
		.coupon-section {
			right: 50%;
			bottom: -110px;
			border-radius: 30px;
			span.coupon-25 {
				border-right: 0;
			}
		}
		h1 {
			width: 100%;
			padding: 70px 0 15px 30px;
		}
	}

	h2.nu-section-title { 
		text-align: left;
		&:after {
			right: auto;
		}
	}

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}

@media (min-width: 992px) and (max-height: 920px) { 

  .nu-hero-section  { 
	height: 75vh;
  }
}