//Reduceri CSS

section.reduceri {
	padding: 40px 0;
	h1,h2 {
		color: #000;
		font-weight: 800;
		margin-bottom: 25px;
	}
	h1 {
		font-size: 24px;
	}
	h2 { 
		font-size: 20px;
	}
	p {
		font-size: 16px;
		color: #3c454e;
		font-weight: 600;
		line-height: 1.4;
	}
	a {
		color: #65bfc6;
	}
	hr {
		border-color: transparent;
		margin-top: 60px;
		margin-bottom: 60px;
		&.large {
			margin-top: 80px;
			margin-bottom: 70px;
		}
	}
	.red-desc {
		margin-top: 30px;
		margin-bottom: 40px;
	}
	.img-holder {
		position: relative;	
		span.section-link {
			a {
				display: block;
				position: absolute;
				background-color: #fd6e61;
				color: #fff;
				padding: 40px 30px;
				font-size: 14px;
				font-weight: 700;
				bottom: 30px;
    			right: -15px;
				transition: all 0.3s ease;
				&:hover {
			    	transform: scale(0.95); 
			    	transition: all 0.3s ease;
			    	background-color: darken(#fd6e61, 2%);
			    }
			} 
		}
		&.paddmarg {
			margin-top: 30px;
    		padding: 0;
		}
	}
	.inner-cnt {
		padding: 15px 0 0 0;
		position: relative;
		span.bg-img {
			position: relative;
			display: block;
			width: 100%;
			height: 250px;
			background-image: url('../images/copii-vesele-in-haine-cu-reduceri.jpg');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
		span.section-link-2 {
			    position: absolute;
			    left: 0;
			    right: 0;
			    width: 100%;
			    top: 100%;
			    z-index: 1;
			    text-align: center;
			    display: block;
			a {
				display: inline-block;
			    background-color: #fd6e61;
			    color: #fff;
			    padding: 40px 30px;
			    font-size: 14px;
			    font-weight: 700;
			    bottom: -60px;
			    margin: 0 auto;
			    text-align: left;
			    transition: all 0.3s ease;
			    width: 100%;
			    &:hover {
			    	transform: scale(0.95); 
			    	transition: all 0.3s ease;
			    	background-color: darken(#fd6e61, 2%);
			    }
			} 
		}
		.inner-desc {
			background-color: #fff;
			padding: 30px 0 30px 0; 
		}
	}
	.half-inner-section {
		position: relative;
		padding-top: 30px;
		margin-top: 15px;
		span.bg-img {
			position: relative;
			height: 0;
			padding-bottom: 75%;
			display: block;
			background-image: url('../images/fetite-in-haine-de-pe-kidlet.jpg');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
		.inner-desc {
			background-color: #fff;
			padding: 30px 0 30px 0;
		}
	}
	.section-link-3 {
		display: block;
		a {
			display: inline-block;
			font-size: 14px;
		    font-weight: 700;
		    color: #0b4147;
		    background-color: #cbe9eb;
		    padding: 60px 32px 70px 32px;
		    transition: all 0.3s ease;
		    width: 100%;
		    &:hover {
		    	transform: scale(0.95); 
		    	transition: all 0.3s ease;
		    	background-color: darken(#cbe9eb, 2%);
		    }
		}
	}
	section.kdl-categorii-new {
		padding: 80px 0;
	}
}




 


// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.reduceri {
		padding: 80px 0;
		h1 {
			font-size: 26px;
		}
		h2 {
			font-size: 24px;
		}
		.img-holder {
			span.section-link {
				a {
					bottom: -60px;
					right: -60px;
				} 
			}
		} 
		.red-desc {
			margin-top: 0;
			margin-bottom: 45px;
		}
		.img-holder.paddmarg {
		    margin-top: -60px;
			padding: 0 15px 0 30px;
		}
		.inner-cnt {
			padding: 60px 0 30px;
    		position: relative;
    		.inner-desc {
    			padding: 30px 100px 30px 0;
    		}
    		span.bg-img {
    			position: absolute;
				top: 0;
				left: 20%;
				right: 0;
				bottom: 0;
				height: auto;
    		}
    		span.section-link-2 { 
				position: absolute;
			    left: 60px;
			    right: 0;
			    width: auto;
			    bottom: -60px;
			    top: auto;
			    a {
			    	width: auto;
			    }
    		}
		}
		.half-inner-section {
			position: relative;
		    padding-top: 60px;
		    margin-top: 55px;
		    span.bg-img {
		    	position: absolute;
			    top: 0;
			    left: 0;
			    right: 42%;
			    bottom: 0;
			    height: auto;
				padding-bottom: 0;
		    }
		    .inner-desc {
				padding: 50px 15px 35px 50px;
		    }
		}
		.section-link-3 {
			a {
				width: auto;
			}
		}
	}


}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}
