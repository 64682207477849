@charset "UTF-8";

@font-face {
  font-family: "kdl-review";
  src:url("../fonts/kdl-review.eot");
  src:url("../fonts/kdl-review.eot?#iefix") format("embedded-opentype"),
    url("../fonts/kdl-review.woff") format("woff"),
    url("../fonts/kdl-review.ttf") format("truetype"),
    url("../fonts/kdl-review.svg#kdl-review") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-iconkdlrev]:before {
  font-family: "kdl-review" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="iconkdlrev"]:before,
[class*=" iconkdlrev"]:before {
  font-family: "kdl-review" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconkdlrev-certificate:before {
  content: "\61";
}
.iconkdlrev-star:before {
  content: "\62";
}
.iconkdlrev-star-half:before {
  content: "\63";
}
.iconkdlrev-star-half-o:before {
  content: "\64";
}
.iconkdlrev-star-o:before {
  content: "\65";
}
.iconkdlrev-check:before {
  content: "\66";
}
.iconkdlrev-check-1:before {
  content: "\67";
}
