section.haine-fb {
	.category-section-fb {
		margin-bottom: 0;
		.container {
			background-size: initial;
			min-height: 200px;
			background-position-x: center;
			background-position-y: bottom;
			background-repeat: no-repeat;
			padding-top: 60px;
			padding-bottom: 60px;
		}
		h3 {
			font-size: 28px;
			font-weight: 800;
			color: #000;
			margin-bottom: 40px;
			margin-top: 25px;
			padding: 15px;
			display: inline-block;
		}
		.circle {
			display: block;
			width: 100px;
			height: 110px;
			background-color: #fff;
			position: relative;
			border-radius: 50%;
		}
		.left-cnt,
		.right-cnt {
			position: relative;
			padding-bottom: 30px;
		}
		.right-cnt {
			margin-top: 0;
		}
		.inner-cnt {
			position: relative;
			z-index: 10;
			padding: 15px;
			a {
				font-size: 20px;
				font-weight: normal;
				font-style: italic;
				box-shadow: 8px 10px 20px 0 rgba(46,61,73,.15);
				background-color: #fff;
				color: #000;
				padding: 10px 15px;
				margin-bottom: 40px;
				display: inline-block;
				transition: all .3s ease;
				i {
					position: relative;
					margin-left: 5px;
					top: 3px;
	    			font-size: 18px;
	    			transition: all .3s ease;
				}
				&:hover {
					box-shadow: 2px 4px 8px 0 rgba(46,61,73,.2);
				    transition: all .3s ease;
				    -webkit-transform: translateX(5px);
				    transform: translateX(5px);
				    i{
				    	margin-left: 10px;
    					transition: all .3s ease;
				    }
				}
			}
		}
		.bg {
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
		}
		.left-cnt {
			.inner-cnt {
				background-color: rgba(248, 237, 149, .8);
			}
			.bg-multi {
				background-color: #f7ea9a;
				mix-blend-mode: multiply;
			}
			.bg-light {
				background-color: #94917c;
				mix-blend-mode: hard-light;
			}
			&:before {
				content: '';
			    position: absolute;
			    top: 30px;
			    bottom: -30px;
			    right: 0;
			    width: 30px;
			    background-color: #000;
			    opacity: .9;
			    display: none;
			}
			a {
				background-color: #fff;
				color: #000;
				margin-left: 30px;
			}
			.circle {
				&:after {
					content: '';
					position: absolute;
					background-image: url(../images/girl-sign.png);
					width: 65px;
					height: 65px;
					top: 0;
	    			right: 0;
	    			transform: translate(0,0);
	    			-webkit-transition: all 0.3s ease;
					-moz-transition: all 0.3s ease;
					-ms-transition: all 0.3s ease;
					-o-transition: all 0.3s ease;
					transition: all 0.3s ease;
				}
			}
			&:hover {
				.circle {
					&:after {
						transform: translate(10px,-10px);
						-webkit-transition: all 0.3s ease;
						-moz-transition: all 0.3s ease;
						-ms-transition: all 0.3s ease;
						-o-transition: all 0.3s ease;
						transition: all 0.3s ease;
					}
				}
			}

		}
		.right-cnt {
			.inner-cnt {
				float: none;
				background-color: rgba(183, 230, 190, 0.8);
			}
			.bg {
				right: 0;
			}
			.bg-multi {
				background-color: #b8e6be;
				mix-blend-mode: multiply;
			}
			.bg-light {
				background-color: #808681;
				mix-blend-mode: hard-light;
			}
			&:before {
				content: '';
			    position: absolute;
			    top: -30px;
			    bottom: 30px;
			    left: 0;
			    width: 30px;
			    background-color: #000;
			    opacity: .8;
			    display: none;
			}
			a {
				background-color: #000;
				color: #fff;
				font-weight: 300;
			}
			.circle {
				margin-left: 30px;
				&:after {
	    			content: '';
					position: absolute;
					background-image: url(../images/boy-sign.png);
					width: 68px;
					height: 68px;
					bottom: 0;
	    			right: 0;
	    			transform: translate(0,0);
	    			-webkit-transition: all 0.3s ease;
					-moz-transition: all 0.3s ease;
					-ms-transition: all 0.3s ease;
					-o-transition: all 0.3s ease;
					transition: all 0.3s ease;
				}
			}
			&:hover {
				.circle {
					&:after {
						transform: translate(10px,-10px);
						-webkit-transition: all 0.3s ease;
						-moz-transition: all 0.3s ease;
						-ms-transition: all 0.3s ease;
						-o-transition: all 0.3s ease;
						transition: all 0.3s ease;
					}
				}
			}
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  

	


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  

	section.haine-fb {
		.category-section-fb {
			margin-bottom: 120px;
			h3 {
				display: inline-block;
				box-shadow: 0px 8px 18px 2px rgba(0, 0, 0, 0.15);
				background-color: #fff;
			}
			.right-cnt {
				margin-top: 60px;
				.inner-cnt {
					background-color: transparent;
					float: right;
				}

				&:before {
					display: block;
				}
			}
			.left-cnt {
				&:before {
					display: block;
				}
				.inner-cnt {
					background-color: transparent;
				}
			}
		}
	}


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.haine-fb {
		.category-section-fb {
			.container {
				padding-top: 180px;
				padding-bottom: 60px;
			}
			h3 {
				padding: 0;
				box-shadow: none;
				background-color: transparent;
				display: block;
			}
		}
	}



}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  



}