a.fancy-link,
span.fancy-link {
	padding: 14px;
	display: inline-block;
	color: #000;
	font-size: 14px;
	font-weight: 800;
	span.link-wrap {
		display: block;
		position: relative;
		overflow: hidden;
		padding-right: 25px;
		padding-left: 15px;
		cursor: pointer;
	}
	span.anim-bg {
	    display: block;
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    z-index: 1;
	    width: 100%;
	    height: 100%;
	    background-color: rgba(0,0,0,.1);
	    transform: translate(-35%, 0%) matrix(1, 0, 0, 1, 0, 0);
	    transition: all 0.5s ease-in;
	    
	}
	span.text {
		display: block;
	}
	&:hover {
		span.anim-bg { 
			transform: translate(103%, 0%) matrix(1, 0, 0, 1, 0, 0);
			transition: all 0.5s ease-out;
		}
	}
}

section.reviews-new {

	.kdl-reviews-inner {
		background-color: #f1f7fd;
		padding: 40px 30px;
		border-radius: 6px; 
	} 
	h2 {
		color: #99acbf;
		position: relative;
		margin-top: 0;
		margin-bottom: 30px;
    	width: 100%;
		&:after {
			content: "";
		    height: 2px;
		    width: 140px;
		    position: absolute;
		    right: auto;
		    left: 0;
		    top: auto;
			bottom: -15px;
		    margin-top: 0px;
		    background-color: #9caec0;
		}
	}
	.rev-slider-cnt {
	    padding: 20px 0 15px 0;
	}
	.rev-slider-item {
		padding: 30px 0 40px 0;
		p {
			color: #72839c;
			font-size: 16px;
		}
		span.name {
			display: iline-block;
			margin-right: 30px;
			color: #7891a9;
			font-size: 14px;
			font-weight: 700;
		}
		span.occup {
			font-size: 14px;
			font-weight: 600;
			color: #7891a9;
			display: inline-block;
		}
	}
	.navigation-slick-rev {
		position: absolute;
	    top: 0;
	    bottom: 0;
	    left: 50%;
	    margin-left: -20px;
	    .up,
	    .down {
	    	position: absolute;
	    	top: 0;
	    	cursor: pointer;
			i {
				width: 30px;
				height: 30px;
				border-radius: 50%;
				line-height: 30px;
				color: #fff;
				text-align: center;
				background-color: #a6b6c7;
				display: inline-block;
				font-size: 15px;
				transition: box-shadow 0.2s ease-out; 
			}
			&:hover {
				i {
					box-shadow: 0 0 0 4px rgba(169, 181, 200, .5);
					transition: box-shadow 0.2s ease-out;
				}
			}
	    }
	    .down {
	    	bottom: 0;
	    	top: auto;
	    }
	}
}


// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.reviews-new {
		.kdl-reviews-inner {
			padding: 50px 0;
			h2 {
				color: #99acbf;
				transform: rotate(-90deg);
				margin-top: 50%;
				margin-bottom: 0;
    			width: 100%;
				&:after {
					display: block;
					left: auto;
					bottom: auto;
					top: 50%;
					right: -45%;
				}
			}

		}
	}

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}