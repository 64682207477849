//Kidlet Test Ride Campaign Styles

body.test-ride {
	.main-content {
		padding-bottom: 35px;
	}
	.lity-wrap {
		background-image: url('../images/test-ride-bg_03.jpg');
		background-size: cover;
		background-repeat: no-repeat; 
	}
	.popup-cnt {
		color: #fff;
		font-weight: 600;
		padding: 20px;
		padding-top: 50px;
		h3 {
			font-size: 20px;
	    	color: #fff;
	    	font-weight: 800;
		}
		p {
			font-weight: 600;
		}
		ul {
			padding-left: 20px;
			li {
				font-weight: 600;
			}
		}
	}

	button.lity-close {
		color: #db3b33;
	    right: 15px;
	    background-color: #fff;
	    border-radius: 50%;
	    text-shadow: none;
	    top: 10px;
	    right: 10px;
	    width: 40px;
	    height: 40px;
	}

}

header.test-ride {
	margin-bottom: 15px;
	.back-to-site-link {
		padding-top: 15px;
		a {
			color: #000;
		}
	}
	.logo {
		padding-top: 15px;
		padding-bottom: 20px;

	}
}

section.test-ride-heading {
	margin-bottom: 15px;
	margin-left: 8px;
	margin-right: 8px;
	h2 {
		font-weight: 800;
		font-size: 26px;
		color: #130b31;
	}
	h3 {
		font-size: 20px;
    	color: #8d8f9a;
    	font-weight: 300;
	}
	.initial-cnt {
		margin-top: 20px;
	}
	p.find-out-more-toggle {
		position: relative;
		color: #3b3d44;
		&:before {
			content: '';
			width: 100%;
			height: 1px;
			background-color: #e8edf1;
			top: 50%;
			left: 0;
			right: 0;
			display: block; 
			position: absolute;
		}
		a {
			background-color: #e8edf1;
			color: #706f7d;
			border-radius: 3px;
			font-size: 14px;
			border-color: transparent;
			z-index: 5;
			position: relative;
		}
	}
	.card.card-body{
		border-color: transparent;
	}
	.benefit-item-wrap {
		border-radius: 3px;
		box-shadow: 0px 4px 9px 0px rgba(19, 11, 49, 0.15);
		margin-bottom: 30px;
		position: relative;
		&:before {
			content: '';
			width: 24px;
			height: 24px;
			background-image: url('../images/check-tr.png');
			background-size: cover;
			position: absolute;
			right: -12px;
			top: -12px;
			display: block;
		}
	}
	.benefit-item {
		padding: 14px 12px;
		&.bordered {
			border-bottom: 1px solid #8d8f9a;
		}
		span.icon-image {
			margin-right: 14px;
			img {
				max-height: 50px;
				max-width: 50px;
				width: auto;
			}
		}
		span.desc {
			font-weight: 600;
			font-size: 14px;
			color: #140b31;
			width: 80%;
		}
	}
}

section.test-ride-more {
	padding-top: 30px;
	a {
		text-align: center;
    	display: block;
		color: #130b31;
		font-weight: 700;
		font-size: 18px;
		text-decoration: underline;
		transition: all 0.3s linear;
		&:hover {
			color: #db3b33;

		}
	}
}

section.test-ride-form {
	h3 {
		font-weight: 800;
		font-size: 24px;
		color: #130b31;
		margin-left: 8px;
    	margin-right: 8px;
	}
}

form#test-ride-form {
	.wizard {
		.steps {
			ul {
				display: flex;
			    flex-wrap: wrap;
			    justify-content: space-between;
			    position: relative;
			    li {
			    	width: auto;
			    	float: none;
			    	z-index: 5;
			    	a {
			    		margin: 0;
			    		width: 32px;
					    height: 32px;
					    border-radius: 50%;
					    border: 6px solid #fff;
					    background-color: #e8edf1;
					    padding: 0;
					    position: relative;
					    &:after {
					    	font-family: 'simple-line-icons';
					    	font-size: 14px;
						    position: relative;
						    left: 3px;
						    top: -1px;
					    }
			    		span {
			    			display: none;
			    		}
			    	}
			    	&.current {
			    		a {
			    			background-color: #dcfff0;
			    			&:after {
			    				content: "\e07b";
			    				color: #123253;
			    			}
			    		}
			    	}
			    	&.done {
			    		a {
			    			background-color: #dcfff0;
			    			&:after {
			    				content: "\e080";
			    				color: #123253;
			    			}
			    		}
			    	}
			    	&.error {
			    		a {
			    			background-color: #fbe3e4;
			    			&:after {
			    				content: "\e082";
			    				color: #db3b33;
			    			}
			    		}
			    	}
			    }
			    &:before {
					content: "";
					position: absolute;
					height: 1px;
					width: 100%;
					top: 50%;
					left: 0;
					right: 0;
					background-color: #b4f1d7;
					display: block;
			    }
			}
		}
		.actions {
			margin-top: 10px;
			ul {
				li {
					a {
						padding: 18px 20px;
						background-color: #130b31;
						border-radius: 3px;
						color: #fff;
						font-size: 18px;
						font-weight: 600;
					}
					&:first-child {
						a {
							background-color: #e8edf1;
							color: #706f7d;
						}
					}
				}
			}
		}
		>.content {
			background-color: #fff;
			min-height: auto;
			section {
				width: 100%;
				height: auto;
				&.body {
					background-color: #fff;
					padding-left: 0;
					padding-right: 0;
					padding-top: 0;

					width: 100%;
				    height: auto;
				    position: absolute;
				}
				&.current {
			    	position: relative;
			    }
			}
		}

	}
	.form-group:focus-within {
		transform: translateY(-3px);
		transition: all 0.3s ease;
	}
	p.section-message {
		font-size: 14px;
		background-color: #dcfff0;
		color: #0c2d4e;
		padding: 15px;
		margin-bottom: 20px;
		span {
			font-weight: 600;
			margin-right: 15px;
		}
	}
	.form-group {
		margin-bottom: 20px;
		transition: all 0.3s ease;
		transform: translateY(0);
		label.label {
			font-weight: 600;
			color: #0c052a;
			font-size: 14px;
			margin-bottom: 10px;

		}
		label.error {
			color: #c74741;
		    display: inline-block;
		    margin-left: 0;
		    font-size: 12px;
		}
		input.form-control {
			padding: 16px 15px;
			border-color: #130b31;
			border-radius: 3px;
		}
		input:focus {
			border-color: #99d4ba;
		}
		label#acceptTerms-error {
			display: block;
			margin-left: 0;
		}
		::-webkit-input-placeholder {
		  color: #7c7a86;
		  font-size: 12px;
		}
		::-moz-placeholder {
		  color: #7c7a86;
		  font-size: 12px;
		}
		:-ms-input-placeholder {
		  color: #7c7a86;
		  font-size: 12px;
		}
		:-moz-placeholder {
		  color: #7c7a86;
		  font-size: 12px;
		}
	}
	span.form-success {
		font-size: 14px;
		background-color: #dcfff0;
		color: #0c2d4e;
		padding: 15px;
		margin-bottom: 30px;
		margin: 8px;
		span.title {
			font-size: 22px;
			margin-bottom: 12px;
			font-weight: 800;
		}
		span.desc {
			font-size: 14px;
		}
		a.btn {
			background-color: #0c2d4e;
			display: block;
			max-width: 70%;
			padding: 15px;
			margin: 0 auto;
			color: #dcfff0;
		    font-weight: 600;
		    margin-top: 20px;
		    margin-bottom: 15px;
		    border-radius: 3px;
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	form#test-ride-form {
		.wizard {
			.actions {
				margin-top: 30px;
			}
		}
		.form-group {
			margin-bottom: 30px;
			input.form-control {
				padding: 21px 15px;
			}
		}
		p.section-message {
			margin-bottom: 30px;
		}
	}

	body.test-ride {
		.lity-container {
			width: 70vw;
		}
	}



}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}






