//Newsletter Bar

.newsletter-bar { 
	background-color: #f8efb1;
	padding: 15px;
	border-radius: 2px;
	margin: 0 0 30px 0;
	overflow: hidden;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		background-image: url(../images/newsletter-bg-pattern.png);
		background-size: auto;
		background-position: center;
		left: 0;
		right: 0;
		top: auto;
		bottom: -65px;
		width: 100%;
		height: 163px;
		transition: all 0.3s ease-out;
	}
	h2 {
		color: #000;
		font-weight: 800;
		font-size: 22px;
		span {
			position: relative;
			top: 5px;
			font-size: 38px;
			display: inline-block;
			padding: 0 10px;
		}
	}
	a.btn {
		background-color: #f8efb1;
		font-size: 16px;
		font-weight: 800;
		border: 2px solid #000;
		color:  #000;
		padding: 10px 40px;
		position: relative;
   		z-index: 50;
   		margin: 20px 0;
		&:hover {
			background-color: #000;
			color:  #f8efb1;
		}
	}
	&.single {
		padding: 15px;
		margin-top: 30px;
		&:after {
			bottom: -80px;
		}
		h2 {
			font-size: 18px;
		}
		a.btn {
			padding: 10px;
			margin-top: 20px;
			margin-bottom: 10px;
		}
	}
	&.catalog {
		margin-bottom: 30px;
		padding: 20px 20px 60px 20px;
		height: auto;
		box-shadow: 0 0 40px 0 rgba(0,0,0,.1);
		&:after {
			top: auto;
			bottom: -30px;
			left: 0;
			width: auto;
		}
		h2 {
			font-size: 18px;
			margin-bottom: 40px;
			width: 90%;
		}
		a.btn {
			padding: 10px;
			margin-top: 20px;
			margin-bottom: 10px;
			display: block;
			font-size: 14px;
		}
	}
	&.popup {
		padding: 20px 20px 30px;
		margin: 0;
		border-radius: 2px;
		h2 {
			font-size: 18px;
			margin-bottom: 10px;
		}
		&:after {
			display: none;
			top: auto;
			bottom: -5%;
			left: 0;
			width: 100%;
			transition: all 0.5s ease-out;
			transition-delay: 2s;
		}
		a.btn { 
			margin-top: 10px;
			color:  #f8efb1;
			background-color: #000;
			&:hover,
			&:focus,
			&:active {
				color:  #000;
				background-color: #f8efb1;
			}
		}
	}
}


#ns-box {
	display:none;
	width: 100%;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0;
    margin: 0;
    box-shadow: 0 0 40px 0 rgba(0,0,0,.18);
    z-index: 9999;
    background-color: #f8efb1;
    .close {
    	position: absolute;
    	right: 0;
    	top: 0;
    	padding: 15px;
    	font-size: 18px;
    	background-color: transparent;
    	cursor: pointer;
    	z-index: 9999;

    }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  

	.newsletter-bar {
		&.catalog {
			margin-bottom: 0;
			padding: 20px 20px 60px 20px;
			height: 92%;
			&:after {
				top: auto;
				bottom: 0;
				left: 0;
				width: auto;
			}
			h2 {
				font-size: 18px;
				margin-bottom: 80px;
			}
		}

	}

}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {

	#ns-box {
		left: auto;
		display:none;
		width: 400px;
	    position: fixed;
	    bottom: 5%;
	    right: 5%;
	    border-radius: 2px;
	    margin: 0;
	    box-shadow: 0 0 40px 0 rgba(0,0,0,.18);
	    z-index: 9999;
	    background-color: #f8efb1;
	    .close {
	    	position: absolute;
	    	right: 0;
	    	top: 0;
	    	padding: 15px;
	    	font-size: 18px;
	    	background-color: transparent;
	    	cursor: pointer;

	    }
	}  

	.newsletter-bar {
		padding: 8px 30px;
		&:after {
			left: auto;
			right: -30px;
			top: 0;
			width: 300px;
			height: 163px;
		}
		h2 {
			font-size: 22px;
			span {
				top: 5px;
				font-size: 38px;
				padding: 0 10px;
			}
		}
		a.btn {
			margin: 0;
		}
		&.single {
			padding: 15px;
			margin-top: 30px;
			&:after {
				bottom: -80px;
				width: 56%;
			}
			a.btn {
				display: block;
				margin: 10px 0;
			}
		}
		&.popup {
			padding: 20px;
			margin: 0;
			border-radius: 2px;
			box-shadow: none;
			h2 {
				font-size: 18px;
				margin-bottom: 10px;
			}
			&:after {
				display: none;
				top: auto;
				bottom: 0;
				left: 0;
				width: 100%;
				transition: all 0.5s ease-out;
				transform: translateY(65px);
				transition-delay: 0.7s;
			}
			a.btn { 
				margin-top: 20px;
			}
		}
	}



}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

	.newsletter-bar {
		&:after {
			left: auto;
			right: 0;
			top: 0;
			width: 300px;
			height: 163px;
		}
		h2 {
			font-size: 22px;

		}
		&.single {
			&:after {
				width: 52%;
			}
			h2 {
				font-size: 18px;
			}
		}
	}


}
