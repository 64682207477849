section.kidlet-description {
	//padding: 100px 0;
	h2 {
		font-weight: 800;
		color: #000;
		font-size: 28px;
		margin-bottom: 0;
	}
	h3 {
		font-weight: 700;
		color: #000;
		font-size: 22px;
		margin-bottom: 15px;
		span {
			font-size: 12px;
			background-color: transparent;
			padding: 15px;
			margin-right: 15px;
			font-weight: 700;
			position: relative;
			top: -1px;
			display: inline-block;
			border: 1px solid #000;
		}
	}
	.col-12 {
		margin-bottom: 30px;
	}
	p {
		font-size: 14px;
		font-weight: 500;
	}
	.desc-bg-img {
		background-size: cover;
		background-position: right;
		background-repeat: no-repeat;
		padding: 50px 0;
		background-image: url(../images/kidlet-main-page-desc-mob-3.jpg);
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  
	
	section.kidlet-description {
	
		h2 {
			font-weight: 800;
			font-size: 36px;
			margin-bottom: 30px;
		}
		h3 {
			font-weight: 700;
			font-size: 24px;
			margin-bottom: 15px;
			span {
				border: none;
				background-color: #cbedcf;
			}
		}
		p {
			font-size: 16px;
			font-weight: 500;
		}
		.col-12 {
			margin-bottom: 0;
			&:nth-child(odd) {
				margin-top: 70px;
			}
		}
		.desc-bg-img {
			background-size: auto;
			background-position: right;
			background-repeat: no-repeat;
			padding: 100px 0;
			background-image: url(../images/kidlet-main-page-desc.jpg);
		}
	}


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}