section.kdl-desc-new {
	.row {
		margin-bottom: 40px;
	}
	h3 {
		font-size: 20px;
		color: #000;
		font-weight: 800;
		margin-top: 35px;
	}
	h2,h3 {
		margin-bottom: 20px;
	}
	p {
		font-size: 14px;
	}
	ul > li {
		font-size: 14px;
	}
	.img-abs-rel {
		position: absolute;
		left: 0;
		right: 0;
		opacity: 0.5;
	}
	.bg-white {
		background-color: #fff;
	}
	strong {
		font-weight: 800;
	}
}


// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.kdl-desc-new {
		.row {
			margin-bottom: 50px;
		}
		h3 {
			font-size: 24px;
			color: #000;
			font-weight: 800;
			margin-top: 45px;
		}
		h2,h3 {
			margin-bottom: 24px;
		}
		p {
			font-size: 17px;
		}
		ul > li {
			font-size: 17px;
		}

		.img-abs-rel {
			position: relative;
			left: auto;
			right: auto;
		}
		.bg-white {
			background-color: transparent;
		}
	}

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}