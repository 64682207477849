section.benefits-card {
	.item {
		box-shadow: 0px 5px 45px 0px rgba(0, 0, 0, 0.06);
		border-radius: 15px;
		margin-bottom: 30px;
	}
	.item-top {
		background-color: #fff;
		padding: 20px 0 50px 0;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		&.bottom-right {
			background-position-x: right;
			background-position-y: bottom;
		}
		&.bottom-center {
			background-position-x: center;
			background-position-y: bottom;
		}
		.circle-desc {
			box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
			border-radius: 50%;
			background-color: #fff;
			padding: 44px 0;
			display: flex;
			width: 130px;
			height: 130px;
			display: block;
			margin-left: auto;
			margin-right: auto; 
			color: #df7925;
			&.kdl-blue {
				color: #316688;
			}
			&.kdl-turq {
				color: #2b6969;
			}
			&.kdl-green {
				color: #538f53;
			}
		}
		.bold {
			font-size: 26px;
			font-weight: 800;
			text-align: center;
			display: block;
			line-height: 1;
			&.extra {
				font-size: 36px;
			}

		}
		.reg {
			font-size: 14px;
			font-weight: 700;
			text-align: center;
			display: block;
		}
	}
	.item-bottom {
		padding: 40px 15px 15px;
		position: relative;
		.item-list {
			ul {
				margin-bottom: 0;
				li {
					margin-bottom: 15px;
					font-size: 16px;
					font-weight: 700;
					line-height: 20px;
					padding-right: 30px;
					position: relative;
					&:after {	
						content: '';
						position: absolute;
						background-image: url(../images/check-mark-black.png);
						width: 16px;
						height: 13px;
						right: 0;
					    top: 50%;
					    margin-top: -7px;
					}
					&:last-child {
						margin-bottom: 0;
					}	
				}
			}
		}
		&:before {
			content: '';
			background-image: url(../images/curved-element.png);
			width: 165px;
			height: 67px;
			position: absolute;
			top: -39px;
			left: 50%;
    		margin-left: -83px;
		}
		.lnr {
		    padding: 11px;
		    font-size: 30px;
		    border-radius: 50%;
		    height: 52px;
		    width: 52px;
		    display: block;
		    text-align: center;
		    position: absolute;
		    top: -26px;
		    left: 50%;
		    margin-left: -26px;
		    color: #fff;
		    &.kdl-orange {
		    	background-color: #feab66;
		    }
		    &.kdl-blue {
		    	background-color: #8dc4e8;
		    }
		    &.kdl-turq {
		    	background-color: #90cece;
		    }
		    &.kdl-green {
		    	background-color: #9ad39a;
		    }
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.benefits-card {
		.item {
			margin-bottom: 0;
		}
		.item-bottom {
			padding: 55px 30px 30px 30px;
			.item-list {
				ul {
					li {
						margin-bottom: 22px;
						font-size: 17px;
					}
				}
			}
		}
	}



}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}