//Listing Page Promo Campaigns Styles

.catalog-banner-card {
	height: 100%;
	width: 100%;
	position: relative;
	margin-bottom: 20px;
	a.catalog-banner-link {
		background-position: center; 
		background-size: cover;
		background-repeat: no-repeat;
		display: block;
		height: 95.5%;
		position: relative;
		border-radius: 4px;
		margin: 0px -8px;
		&:hover {
			box-shadow: 0 0 40px 0 rgba(0,0,0,.1);
		}
		&#halloween {
			background-image: url('../images/kidlet-haloween-card-2.jpg');
		}
		&#pareri {
			background-image: url('../images/kidlet-pareri-card-2.jpg');
		}
		&#cizme {
			background-image: url('../images/kidlet-cizme-card-2.jpg');
		}
		&#craciun-a {
			background-image: url('../images/kidlet-craciun-2.jpg');
		}
		&#craciun-b {
			background-image: url('../images/kidlet-craciun-3.jpg');
		}
		&#blackfriday {
			background-image: url('../images/kidlet-bf2019-card.jpg');
		}
	}
}



// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  


.catalog-banner-card {
	height: 94%;
	width: 100%;
	margin-bottom: 30px;
	a.catalog-banner-link {
		margin: 0px -6px;
		height: 98%;
	}
}


}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}