section.main-cat {
	.main-cat-inner {
		padding-top: 30px;
		//padding-bottom: 60px;
		position: relative;
		&:before {
			content: '';
			width: 655px;
			height: 655px;
			border-radius: 50%;
			left: auto;
			right: -10%;
			top: 0;
			position: absolute;
			background-color: #8bc5eb;
		}
	}
	.item {
		border-radius: 15px;
		background-color: rgba(255, 255, 255, 0.95);
		padding: 30px;
		box-shadow: 0px 5px 45px 0px rgba(0, 0, 0, 0.06);
		margin-bottom: 30px;
		.item-title {
			margin-bottom: 35px;
			a {
				font-size: 20px;
				color: #000;
				font-weight: 800;
				display: block;
				span.lnr {
					-webkit-text-stroke: 2px #000;
					position: relative;
				    top: 2px;
				    margin-left: 15px;
				    transition: all 0.3s ease;
				    transform: translateX(0);
				    display: inline-block;
				}
				&:hover {
					color: #4792da;
					span.lnr {
						transition: all 0.3s ease;
						transform: translateX(15px);
						color: #4792da;
						-webkit-text-stroke: 2px #4792da;
					}
				} 
			}
		}
		.item-desc {
			p {
				font-size: 16px;
			}
		}
	}
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.main-cat {
		.item {
			margin-bottom: 0;
			.item-title {
				margin-bottom: 55px;
				a {
					font-size: 24px;
				}
			}
			.item-desc {
				p {
					font-size: 17px;
				}
			}
		}
	}



}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}