section.benefits-icons {
	.icon-image {
		position: relative;
		z-index: 10;
		width: 50px;
		height: 46px;
		&:before {
			content: '';
			position: absolute;
			display: block;
			left: -30px;
			top: -40px;
			width: 136px;
			height: 120px;
			background-color: #fff;
			border-radius: 15px;
			box-shadow: 0px 8px 27px 0px rgba(89, 89, 89, 0.1);
			z-index: -1;
			transition: all 0.3s ease-out;
			transform: scale(1);
			display: none;
		}
	}
	.icon-image {
		width: 50px;
	}
	.benefits-wrap {
		border-radius: 15px;
		box-shadow: 0px 8px 27px 0px rgba(89, 89, 89, 0.1);
		background-color: #fff;
	}
	.desc {
		position: relative;
		font-size: 20px;
		font-weight: 700;
		z-index: 10;
		.small-text {
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 600;
			color: #999;
			margin-top: 5px;
		}
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: -8px;
			height: 2px;
			width: 40px;
			background-color: #000;
		}
	}
	.col-md-6 {
		&:last-child {
			.benefit-item {
				border-color: transparent;
				margin-bottom: 0;
			}
		}
	}
	.benefit-item {
		padding-top: 32px;
		padding-bottom: 32px;
		padding-left: 15px;
		margin-bottom: 10px;
		border-bottom: 1px solid #f2f2f2;
		&:hover {
			.icon-image {
				&:before {
					transform: scale(1.15);
					transition: all 0.3s ease-out;
				}
			}
		}
	}
}


// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {  


}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {  


}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {  

	section.benefits-icons {
		.benefits-wrap {
			border-radius: 0;
			box-shadow: none;
			padding: 0;
			background-color: transparent;
		}
		.benefit-item {
			border: none;
			margin-bottom: 15px;
			padding-left: 32px;
			.icon-image {
				&:before {
					display: block;
				}
			}
			&:hover {
				.icon-image {
					&:before {
						transform: scale(1.15);
						transition: all 0.3s ease-out;
					}
				}
			}
		}
	}

}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {  

}

